import { doGetAxios, doPostAxios } from "./axios";

export const apiMain = {
  getCountry: async () => {
    try {
      const url = "country/list";
      const res: any = await doGetAxios(url);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getProvince: async (p: { countryId: string | number }) => {
    try {
      const url = `province/list?countryId=${p.countryId}`;
      const res: any = await doGetAxios(url);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getDistrict: async (p: { provinceId: string | number }) => {
    try {
      const url = `district/list?provinceId=${p.provinceId}`;
      const res: any = await doGetAxios(url);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getWard: async (p: { districtId: string | number }) => {
    try {
      const url = `ward/list?districtId=${p.districtId}`;
      const res: any = await doGetAxios(url);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getQuestion: async () => {
    try {
      const url = `user/survey/questions`;
      const res: any = await doGetAxios(url);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getResult: async () => {
    try {
      const url = `user/survey/results`;
      const res: any = await doGetAxios(url);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getAcknowledgment: async () => {
    try {
      const url = `user/survey/acknowledgment`;
      const res: any = await doGetAxios(url);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  submitQuestion: async (params: {
    appetiteId: null | number;
    isSave: boolean;
    questionResults: Array<any>;
  }) => {
    try {
      const url = `user/survey/submit`;
      const res: any = await doPostAxios(url, params);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getPerformanceInfo: async (params: { productId: null | number }) => {
    try {
      const url = `chart/performance-info`;
      const res: any = await doPostAxios(url, params);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
  getChartInfo: async (params: { productId: null | number }) => {
    try {
      const url = `chart/chart-info`;
      const res: any = await doPostAxios(url, params);
      if (res.status == 200) {
        return res;
      }
      throw res?.response;
    } catch (error: any) {
      throw error;
    }
  },
};
