import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import useCachedResources from "./src/hooks/useCachedResources";
import Navigation from "./src/navigation";
import useColorScheme from "./src/hooks/useColorScheme";
import React from "react";
export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <>
        <SafeAreaProvider>
          <Navigation colorScheme={colorScheme} />
          <StatusBar hidden={true} />
        </SafeAreaProvider>
        {/* <div id="trueidsdk"></div> */}
        {/* <script src="./src/react_trueid/trueid.js"></script> */}
        {/* <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap.native/2.0.27/bootstrap-native-v4.min.js"
        ></script> */}
      </>
    );
  }
}
