import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setStoreToken } from "utils/storage";
import { doLogin, getInfo, getQuestion, getResult } from "./action";
type IParamsStatusScreen = "splash" | "unAuthorized" | "main";

interface IinitialState {
  currentUser: any;
  isLoadingCurrentUser: boolean;
  userName?: string;
  token: any;
  isLoading: boolean;
  error: any;
  statusScreen: IParamsStatusScreen; // 1 splash , 2 unauthorized , 3 login success
  isBio: boolean;
  isNoti: boolean;
  isSupport: boolean;
  biometryType: string;
  isLoadingQuestion: boolean;
  question: {
    appetites?: any;
    questions?: any;
  };
  result: {
    appetites?: any;
    questions?: any;
  };
  draftQuestion: any;
}

const initialState = {
  currentUser: {},
  isLoadingCurrentUser: false,
  //
  question: {
    appetites: [],
    questions: [],
  },
  result: {
    appetites: [],
    questions: [],
  },
  draftQuestion: {},
  isLoadingQuestion: false,
  //
  token: "",
  //
  isLoading: false,
  statusScreen: "splash",
  //
  userName: "",
  error: {},
  //
  isBio: true,
  isNoti: true,
  isSupport: true,
  biometryType: "",
} as IinitialState;

const authen = createSlice({
  name: `authen@/doLogin`,
  initialState: initialState,
  reducers: {
    // change status screen
    changeStatusScreen(state, action: PayloadAction<IParamsStatusScreen>) {
      if (action.payload == "unAuthorized") {
        setStoreToken("");
      }
      state.statusScreen = action.payload;
    },
    saveName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    changeBio(state, action: PayloadAction<boolean>) {
      state.isBio = action.payload;
    },
    changeNoti(state, action: PayloadAction<boolean>) {
      state.isNoti = action.payload;
    },
    clearAuthen(state, action: PayloadAction<any>) {
      state.currentUser = {};
    },
    changeIsSupport(state, action: PayloadAction<boolean>) {
      state.isSupport = action.payload;
    },
    changeBiometryType(
      state,
      action: PayloadAction<string | "TouchID" | "FaceID">
    ) {
      state.biometryType = action.payload;
    },
    changeResult(state, action: PayloadAction<any>) {
      state.result = action.payload;
    },
    draftQuestion(state, action: PayloadAction<any>) {
      state.draftQuestion = { ...action.payload, isSave: true };
    },
  },
  extraReducers: (builder) => {
    // getquestion
    builder.addCase(getQuestion.pending, (state, action) => {
      state.isLoadingQuestion = true;
    });
    builder.addCase(getQuestion.fulfilled, (state, action) => {
      state.isLoadingQuestion = false;
      state.question = action.payload;
    });
    builder.addCase(getQuestion.rejected, (state, action) => {
      state.isLoadingQuestion = false;
    });
    // dologin
    builder.addCase(doLogin.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(doLogin.rejected, (state, action) => {
      state.isLoading = false;
    });
    // do get info
    builder.addCase(getInfo.pending, (state, action) => {
      state.isLoadingCurrentUser = true;
    });
    builder.addCase(getInfo.fulfilled, (state, action) => {
      state.isLoadingCurrentUser = false;
      state.currentUser = action.payload;
    });
    builder.addCase(getInfo.rejected, (state, action) => {
      state.isLoadingCurrentUser = false;
    });
    builder.addCase(getResult.pending, (state, action) => {
      // state.isLoadingCurrentUser = true;
    });
    builder.addCase(getResult.fulfilled, (state, action) => {
      // state.isLoadingCurrentUser = false;
      // state.currentUser = action.payload;
      state.result = action.payload;
    });
    builder.addCase(getResult.rejected, (state, action) => {
      // state.isLoadingCurrentUser = false;
    });
    // get info
  },
});

const { actions, reducer } = authen;
export const {
  changeStatusScreen,
  saveName,
  changeBio,
  clearAuthen,
  changeNoti,
  changeBiometryType,
  changeIsSupport,
  changeResult,
  draftQuestion,
} = actions;
export default reducer;
