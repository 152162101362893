import { Button, Div, HeaderBack, ImageView, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { doGetAxios } from "services/axios";
import { useAppSelector } from "store/hooks";
import { convertPercent, convertStringFeeSell } from "utils/utils";

function FeeTableModal(props: {
  url: string;
  content: string;
  productId: any;
  productProgramId: any;
  hide: () => void;
}) {
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  const insest = useSafeAreaInsets();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<any>>([]);
  const { url, productId, productProgramId, content, hide } = props;

  useEffect(() => {
    getData();
    return () => {};
  }, [productId, productProgramId]);

  const getData = async () => {
    try {
      const stringurl = `${url}/load?productId=${productId}&productProgramId=${productProgramId}`;
      setLoading(true);
      const res = await doGetAxios(stringurl);
      if (res.status == 200) {
        setData(res.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const switchOrder = () => {
    switch (url) {
      case "product-program/sell-fee":
        return (
          <Div paddingBottom={25}>
            {data?.length != 1 && (
              <Div
                flexDirection={"row"}
                paddingTop={19}
                paddingBottom={11}
                borderBottomWidth={0.7}
                borderBottomColor={Ecolors.spaceColor}
                alignItems={"center"}
                justifyContent={"space-between"}
                marginHorizontal={17}
              >
                <Label
                  fontWeight={"500"}
                  size={14}
                >{`createordermodal.thoigiannamgiu`}</Label>
                <Label
                  fontWeight={"500"}
                  size={14}
                >{`createordermodal.phiban`}</Label>
              </Div>
            )}
            {data?.length == 1 && (
              <Div>
                {data?.map((item: any, index: number) => {
                  return (
                    <Div
                      key={index}
                      width={"100%"}
                      height={63}
                      alignItems={"center"}
                      flexDirection={"row"}
                      justifyContent={"center"}
                    >
                      <Label>{`createordermodal.phiban`}</Label>
                      <Label multilanguage={false}>
                        {` ${convertPercent(item.rate)}`}{" "}
                      </Label>
                    </Div>
                  );
                })}
              </Div>
            )}

            {data?.length > 1 &&
              data?.map((item: any, index: number) => {
                return (
                  <Div
                    key={index}
                    width={"100%"}
                    paddingVertical={5}
                    alignItems={"center"}
                    paddingHorizontal={17}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Label size={14} multilanguage={false}>
                      {convertStringFeeSell({ ...item, I18nState: I18nState })}
                    </Label>
                    <Label size={14} multilanguage={false}>
                      {` ${convertPercent(item.rate)}`}{" "}
                    </Label>
                  </Div>
                );
              })}
          </Div>
        );
      case "product-program/buy-fee":
        return (
          <Div>
            {data?.map((item: any, index: number) => {
              return (
                <Div
                  key={index}
                  width={"100%"}
                  height={63}
                  alignItems={"center"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <Label>{`createordermodal.phimua`}</Label>
                  <Label multilanguage={false}>
                    {` ${convertPercent(item.rate)}`}{" "}
                  </Label>
                </Div>
              );
            })}
          </Div>
        );
      case "product-program/switch-fee":
        return (
          <Div paddingBottom={25}>
            {data?.length != 1 && (
              <Div
                flexDirection={"row"}
                paddingTop={19}
                paddingBottom={11}
                borderBottomWidth={0.7}
                borderBottomColor={Ecolors.spaceColor}
                alignItems={"center"}
                justifyContent={"space-between"}
                marginHorizontal={17}
              >
                <Label
                  fontWeight={"500"}
                  size={14}
                >{`createordermodal.thoigiannamgiu`}</Label>
                <Label
                  fontWeight={"500"}
                  size={14}
                >{`createordermodal.phichuyendoi`}</Label>
              </Div>
            )}
            {data?.length == 1 && (
              <Div>
                {data?.map((item: any, index: number) => {
                  return (
                    <Div
                      key={index}
                      width={"100%"}
                      height={63}
                      alignItems={"center"}
                      flexDirection={"row"}
                      justifyContent={"center"}
                    >
                      <Label>{`createordermodal.phichuyendoi`}</Label>
                      <Label multilanguage={false}>
                        {` ${convertPercent(item.rate)}`}{" "}
                      </Label>
                    </Div>
                  );
                })}
              </Div>
            )}
            {data?.length > 1 &&
              data?.map((item: any, index: number) => {
                return (
                  <Div
                    key={index}
                    width={"100%"}
                    paddingVertical={5}
                    alignItems={"center"}
                    paddingHorizontal={17}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Label size={14} multilanguage={false}>
                      {convertStringFeeSell({ ...item, I18nState: I18nState })}
                    </Label>
                    <Label size={14} multilanguage={false}>
                      {` ${convertPercent(item.rate)}`}{" "}
                    </Label>
                  </Div>
                );
              })}
          </Div>
        );
      default:
        return;
    }
  };

  return (
    <Div
      width={337}
      minHeight={100}
      backgroundColor={Ecolors.whiteColor}
      borderRadius={5}
    >
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        height={47}
        borderBottomWidth={0.7}
        borderBottomColor={Ecolors.spaceColor}
        paddingLeft={20}
        justifyContent={"space-between"}
      >
        <Label fontWeight={"700"}>{content}</Label>
        <Button
          onPress={() => {
            hide();
          }}
          widthHeight={47}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ImageView
            widthHeight={18}
            resizeMode={"contain"}
            source={Icons.close}
          />
        </Button>
      </Div>
      {switchOrder()}
    </Div>
  );
}

export default React.memo(FeeTableModal);
