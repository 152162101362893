import {
  BottomSheetDialog,
  Button,
  ButtonBorder,
  Div,
  Dropdown,
  ImageView,
  InputItem,
  Label,
  TimeFromNow,
} from "components";
import React, { useRef, useState } from "react";
import { Modal, Platform, ScrollView } from "react-native";

import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import moment from "moment";
import FeeTableModal from "screens/CreateOrderModal/FeeTableModal";
import { goBack } from "services/navigation";
import { useAppSelector } from "store/hooks";
import {
  checkToSetdata,
  convertAmount,
  convertNav,
  convertNumber,
  convertPercent,
  convertTimestamp,
} from "utils/utils";

interface Props {
  product: any;
  setProduct: (e: any) => void;
  scheme: any;
  setScheme: (e: any) => void;
  currentSession: any;
  excuseTempVolumn: any;
  loading: boolean;
  bankSuperVisory: any;
  setCurrentSession: (e: any) => void;
  setListScheme: (e: any) => void;
  setBankSuperVisory: (e: any) => void;
  setExcuseTempVolumn: (e: any) => void;
  onChangeProduct: (e?: any) => void;
  onExcuseTempVolumn: (e?: any) => void;
  amount: string;
  listScheme: Array<any>;
  listProduct?: Array<any>;
  setAmount: (e: string) => void;
  onNext: () => void;
}

function RowSpaceItem(p: {
  marginTop?: number;
  children: any;
  isBorderBottom?: boolean;
}) {
  return (
    <>
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        marginTop={p.marginTop ?? 0}
      >
        {p.children && p.children}
      </Div>
    </>
  );
}

function OrderSellStep1({
  product,
  listScheme,
  listProduct,
  scheme,
  setScheme,
  currentSession,
  excuseTempVolumn,
  amount,
  setAmount,
  onChangeProduct,
  onExcuseTempVolumn,
  onNext,
  loading,
}: Props) {
  const [loadingLocal, setLoadingLocal] = useState<boolean>(false);
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const [showCalculator, setShowCalculator] = useState<boolean>(false);
  const [amountExpected, setAmountExpected] = useState<string>("");
  const [redemptionUnits, setRedemptionUnits] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<number>(-1);
  const bottomsheet = useRef<any>(null);
  const onAccept = async () => {
    // check data null
    onNext && onNext();
    return;
    // try {
    //   setLoading(true);
    //   const res = await apiInvestment.bankSuperVisory({
    //     productId: product?.id || 0,
    //   });
    //   if (res.status == 200) {
    //     // setBankSuperVisory(res.data);
    //     onNext && onNext();
    //   }
    // } catch (error) {
    // } finally {
    //   setLoading(false);
    // }
  };

  const onCalculator = (e: string) => {
    setAmountExpected(convertAmount(`${e}`, true));
    const strim = e.replace(/,/g, "");
    const redempt = Number(strim) / product?.navCurrently;
    const string = convertAmount(`${redempt}`, true);
    setRedemptionUnits(string);
  };

  const onGetCalculator = () => {
    setAmount(redemptionUnits);
    setShowCalculator(false);
    setCurrentTab(-1);
  };

  return (
    <Div screen={true}>
      <BottomSheetDialog
        style={{
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "65%",
          marginTop: "35%",
        }}
        ref={bottomsheet}
      >
        <FeeTableModal
          hide={() => {
            bottomsheet.current.hide();
          }}
          url={`product-program/sell-fee`}
          content={`createordermodal.bieuphiban`}
          productId={product?.id || 0}
          productProgramId={scheme?.id || 0}
        />
      </BottomSheetDialog>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Div paddingHorizontal={16}>
          <Label marginTop={33}>{`createordermodal.chonsanpham`}</Label>
          <Dropdown
            marginTop={6}
            multiline={true}
            paddingHorizontal={0}
            isShortName={true}
            initData={listProduct}
            value={product}
            content={`createordermodal.chonsanpham`}
            multilanguage={true}
            isActive={true}
            onChange={onChangeProduct}
          />
          <Label marginTop={13}>{`createordermodal.chonchuongtrinh`}</Label>
          <Dropdown
            multiline={true}
            marginTop={6}
            paddingHorizontal={0}
            initData={listScheme}
            value={scheme}
            content={`createordermodal.chonchuongtrinh`}
            multilanguage={true}
            isActive={!!product && !!listScheme.length && !loading}
            onChange={(e) => {
              setScheme(e);
              setAmount("");
            }}
          />
          <Label marginTop={13}>{`createordermodal.nhapsoluongban`}</Label>
          <InputItem
            isInput={!!product && !!scheme}
            value={amount}
            onChangeText={(a) => {
              checkToSetdata(a, (r) => setAmount(convertAmount(a, true)));
              setCurrentTab(-1);
            }}
            keyboardType={
              Platform.OS === "ios" ? "numbers-and-punctuation" : "number-pad"
            }
            marginTop={6}
            marginHorizontal={0}
            onHandleChange={() => onExcuseTempVolumn()}
            renderButtonRight={() => {
              return (
                <Button
                  onPress={() => {
                    setShowCalculator(true);
                  }}
                  width={100}
                  height={29}
                  borderRadius={100}
                  backgroundColor={Ecolors.mainColor}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Label
                    size={14}
                    color={Ecolors.whiteColor}
                  >{`createordermodal.nhapsotien`}</Label>
                </Button>
              );
            }}
          />
          {scheme && (
            <Div
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ButtonBorder
                type={6}
                width={80}
                height={30}
                borderRadius={20}
                size={14}
                isDisable={currentTab == 0 ? false : true}
                title={`createordermodal.haimuoi`}
                marginTop={10}
                onPress={() => {
                  setCurrentTab(0);
                  setAmount(
                    `${convertAmount(scheme?.volumeAvailable * 0.2, true)}`
                  );
                }}
              />
              <ButtonBorder
                type={6}
                width={80}
                height={30}
                borderRadius={20}
                size={14}
                isDisable={currentTab == 1 ? false : true}
                title={`createordermodal.nammuoi`}
                marginTop={10}
                onPress={() => {
                  setCurrentTab(1);
                  setAmount(
                    `${convertAmount(scheme?.volumeAvailable * 0.5, true)}`
                  );
                }}
              />
              <ButtonBorder
                type={6}
                width={80}
                height={30}
                borderRadius={20}
                size={14}
                isDisable={currentTab == 2 ? false : true}
                title={`createordermodal.baylam`}
                marginTop={10}
                onPress={() => {
                  setCurrentTab(2);
                  setAmount(
                    `${convertAmount(scheme?.volumeAvailable * 0.75, true)}`
                  );
                }}
              />
              <ButtonBorder
                type={6}
                width={80}
                height={30}
                borderRadius={20}
                size={14}
                isDisable={currentTab == 3 ? false : true}
                title={`createordermodal.tatca`}
                marginTop={10}
                onPress={() => {
                  setCurrentTab(3);
                  setAmount(`${convertAmount(scheme?.volumeAvailable, true)}`);
                }}
              />
            </Div>
          )}
          {!!scheme && (
            <>
              {!!scheme?.sellMin && (
                <Div
                  marginTop={7}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <ImageView
                    source={Icons.warningamount}
                    widthHeight={16}
                    resizeMode={"contain"}
                  />
                  <Label
                    marginLeft={5}
                    size={12}
                  >{`createordermodal.soluongtoithieukhongduoi`}</Label>
                  <Label marginLeft={5} size={12} multilanguage={false}>
                    {convertNav(scheme?.sellMin, true)}
                  </Label>
                </Div>
              )}
              <Div
                marginTop={4}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <ImageView
                  source={Icons.warningamount}
                  widthHeight={16}
                  resizeMode={"contain"}
                />
                <Label
                  marginLeft={5}
                  size={12}
                >{`createordermodal.soluongkhadung`}</Label>
                <Label marginLeft={5} size={12} multilanguage={false}>
                  {scheme?.volumeAvailable
                    ? convertNav(scheme?.volumeAvailable, true)
                    : "0.00"}
                </Label>
              </Div>
            </>
          )}

          {!!excuseTempVolumn && (
            <>
              <Div marginTop={17}>
                <Label>{`createordermodal.phiban`}</Label>
                <Div
                  marginTop={6}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Div
                    width={207}
                    paddingLeft={21}
                    height={48}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    borderRadius={5}
                    borderWidth={0.8}
                    borderColor={Ecolors.bordercolor}
                    backgroundColor={Ecolors.spaceColor}
                  >
                    <Label multilanguage={false}>
                      {convertNumber(Math.round(excuseTempVolumn?.totalFee))}
                    </Label>
                  </Div>
                  <Button
                    onPress={() => {
                      bottomsheet.current.show();
                    }}
                  >
                    <Label
                      color={Ecolors.linkColor}
                    >{`createordermodal.xembieuphi`}</Label>
                  </Button>
                </Div>
              </Div>
              {excuseTempVolumn?.details?.map((item: any, index: number) => {
                const { createAt, volumSell, feeRate, holdingDay } = item;
                return (
                  <Div
                    marginTop={14}
                    key={index}
                    borderRadius={5}
                    borderWidth={0.8}
                    borderColor={Ecolors.spaceColor}
                    paddingHorizontal={20}
                    paddingTop={12}
                    paddingBottom={15}
                  >
                    <RowSpaceItem>
                      <Label size={14}>{`createordermodal.ngaymua`}</Label>
                      <Label size={14} multilanguage={false}>
                        {convertTimestamp(createAt, "DD/MM/yyyy, HH:mm")}
                        {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
                      </Label>
                    </RowSpaceItem>
                    <RowSpaceItem marginTop={10}>
                      <Label size={14}>{`createordermodal.tgnamgiu`}</Label>
                      <Label multilanguage={false} size={14}>{`${holdingDay} ${
                        I18nState == "vi" ? "ngày" : "days"
                      }`}</Label>
                    </RowSpaceItem>
                    <RowSpaceItem marginTop={10}>
                      <Label size={14}>{`createordermodal.slban`}</Label>
                      <Label multilanguage={false} size={14}>
                        {convertNav(volumSell, true)}
                      </Label>
                    </RowSpaceItem>
                    <RowSpaceItem marginTop={10}>
                      <Label size={14}>{`createordermodal.phi`}</Label>
                      <Label size={14} multilanguage={false}>{`${convertPercent(
                        feeRate
                      )}`}</Label>
                    </RowSpaceItem>
                  </Div>
                );
              })}
            </>
          )}

          {!!currentSession && (
            <Div
              width={"100%"}
              marginTop={17}
              borderRadius={5}
              paddingHorizontal={16}
              paddingVertical={21}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              backgroundColor={Ecolors.spaceColor}
            >
              <Div
                paddingRight={16}
                height={"100%"}
                width={140}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Label
                  size={12}
                  fontWeight={"700"}
                >{`createordermodal.phiengiaodich`}</Label>
                <Label marginTop={6} size={12} multilanguage={false}>
                  {currentSession?.tradingTimeString || ""}
                  {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
                </Label>
                <TimeFromNow toTime={currentSession?.closedOrderBookTime} />
              </Div>
              <Div
                width={3}
                height={"100%"}
                backgroundColor={Ecolors.grayColor}
                borderRadius={10}
              />
              <Div
                height={"100%"}
                paddingLeft={16}
                flex={1}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Label
                  size={12}
                  fontWeight={"700"}
                >{`createordermodal.thoidiemdongsolenh`}</Label>
                <Label marginTop={6} size={12} multilanguage={false}>
                  {currentSession.closedOrderBookTimeString}
                  {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
                </Label>
                <Label
                  marginTop={12}
                  size={12}
                  fontWeight={"700"}
                >{`createordermodal.navccqkitruoc`}</Label>
                <Label marginTop={6} size={12} multilanguage={false}>
                  {convertNav(product?.navCurrently)}
                </Label>
              </Div>
            </Div>
          )}
        </Div>
        <Div height={100} />
      </ScrollView>
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        paddingHorizontal={29}
        marginTop={10}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <ButtonBorder
          onPress={() => {
            goBack();
          }}
          width={148}
          height={48}
          title={`createordermodal.quaylai`}
          type={2}
        />
        <ButtonBorder
          isDisable={
            !product ||
            !scheme ||
            !amount ||
            !currentSession ||
            !excuseTempVolumn
          }
          loading={loading || loadingLocal}
          onPress={() => {
            onAccept();
          }}
          width={148}
          height={48}
          title={`createordermodal.xacnhan`}
          type={1}
        />
      </Div>
      <Div height={200} />
      <Modal animationType="slide" transparent={true} visible={showCalculator}>
        <Div
          flex={1}
          backgroundColor={Ecolors.transparentLoading}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Div
            width={337}
            backgroundColor={Ecolors.whiteColor}
            borderRadius={5}
            minHeight={200}
          >
            <Div
              flexDirection={"row"}
              alignItems={"center"}
              paddingHorizontal={15}
              borderBottomWidth={0.8}
              borderBottomColor={Ecolors.bordercolor}
              paddingVertical={15}
              justifyContent={"space-between"}
            >
              <Label
                fontWeight={"700"}
              >{`createordermodal.bangtinhtamthoi`}</Label>
              <Button
                onPress={() => {
                  setShowCalculator(false);
                }}
              >
                <ImageView
                  widthHeight={16}
                  source={Icons.close}
                  resizeMode={"contain"}
                />
              </Button>
            </Div>
            <Div padding={15}>
              <Label>{`createordermodal.nhapsotiendutinhnhanduoc`}</Label>
              <InputItem
                //isInput={!!product && !!scheme}
                value={amountExpected}
                onChangeText={(t: string) => {
                  onCalculator(t);
                }}
                keyboardType={"decimal-pad"}
                marginTop={6}
                marginHorizontal={0}
                /* onHandleChange={() => {
                onCalculator();
              }} */
              />
              <Div
                marginTop={7}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <ImageView
                  source={Icons.warningamount}
                  widthHeight={16}
                  resizeMode={"contain"}
                />
                {product && scheme && (
                  <Label marginHorizontal={10} multilanguage={false} size={12}>
                    {I18nState == "vi"
                      ? `Số tiền trên chưa bao gồm phí thuế (nếu có),dựa trên giá chứng chỉ quỹ ngày ${moment(
                          new Date()
                        )
                          .utcOffset("+07:00")
                          .format(
                            "DD/MM/YYYY"
                          )} (Giờ VN), số tiền thực tế nhận có thể khác`
                      : `The above amount is not inclusive of taxes or fees (if any). Calculation is based on unit price as of ${moment(
                          new Date()
                        )
                          .utcOffset("+07:00")
                          .format(
                            "DD/MM/YYYY"
                          )} (VNT), actual amount received can be different`}
                  </Label>
                )}
              </Div>
              <Label marginTop={13}>{`createordermodal.soccqcanban`}</Label>
              <InputItem
                isInput={false}
                value={redemptionUnits}
                keyboardType={"decimal-pad"}
                marginTop={6}
                marginHorizontal={0}
              />
              <ButtonBorder
                type={1}
                width={"100%"}
                title={"alert.dongy"}
                marginTop={25}
                onPress={() => {
                  onGetCalculator();
                }}
              />
            </Div>
          </Div>
        </Div>
      </Modal>
    </Div>
  );
}

export default React.memo(OrderSellStep1);
