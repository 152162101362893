import {
  BottomSheetDialog,
  Button,
  ButtonBorder,
  Div,
  ImageView,
  Label,
  QRCode,
} from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React, { useEffect, useRef, useState } from "react";
import { ScrollView } from "react-native";
import { goBack } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { Log } from "utils";
import {
  PostMSG,
  convertNumber,
  convertTimestamp,
  copyToClipboard,
} from "utils/utils";

interface Props {
  product: any;
  amount: any;
  scheme: any;
  orderData: any;
  currentSession: any;
  bankSuperVisory: any;
  excuseTempVolumn: any;
  stepTimeLine?: number;
  beginBuyAutoStartDate?: string;
  onPre?: () => void;
}

function RowSpaceItem(p: {
  marginTop?: number;
  paddingHorizontal?: number;
  children: any;
  isBorderBottom?: boolean;
  justifyContent?: any;
}) {
  return (
    <>
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={p.justifyContent || "space-between"}
        marginTop={p.marginTop || 0}
        paddingHorizontal={p.paddingHorizontal || 0}
      >
        {p.children && p.children}
      </Div>
      {p.isBorderBottom && (
        <Div
          marginTop={15}
          width={"100%"}
          height={1}
          backgroundColor={Ecolors.spaceColor}
        />
      )}
    </>
  );
}

function ContentCoppy(p: {
  title: string;
  content: string;
  isBorderBottom?: boolean;
  marginTop?: number;
  isBtn?: boolean;
}) {
  return (
    <RowSpaceItem marginTop={p.marginTop} isBorderBottom={p.isBorderBottom}>
      <Div flex={1}>
        <Label color={Ecolors.grayColor} size={14}>
          {p.title}
        </Label>
        <Label marginTop={5} multilanguage={false} size={14}>
          {p.content}
        </Label>
      </Div>
      {p.isBtn ? (
        <Button
          onPress={() => {
            copyToClipboard(p.content);
          }}
          width={61}
          height={26}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={5}
          backgroundColor={Ecolors.yellowColor}
        >
          <Label size={14} multilanguage={false}>
            COPY
          </Label>
        </Button>
      ) : (
        <Div width={61} height={26} />
      )}
    </RowSpaceItem>
  );
}

function OrderBuyStep3({
  product,
  stepTimeLine,
  amount,
  scheme,
  currentSession,
  bankSuperVisory,
  beginBuyAutoStartDate,
  excuseTempVolumn,
  orderData,
  onPre,
}: Props) {
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const bottomsheet = useRef<any>(null);
  const data = {
    amount: amount?.replace(/,/g, ""),
    accountName: bankSuperVisory?.name,
    accountNo: bankSuperVisory?.number,
    transferContent: ` ${excuseTempVolumn?.investmentNumber}`,
    bankId: bankSuperVisory?.dataBank?.binCode,
    binCode: bankSuperVisory?.dataBank?.binCode,
    bankName: bankSuperVisory?.dataBank?.name,
  };
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (stepTimeLine == 3) {
      Log("CreateOrderSuccess", { msg: "CreateOrderSuccess", orderData });
      PostMSG("CreateOrderSuccess", orderData);
    }
    return () => {};
  }, [stepTimeLine]);

  if (stepTimeLine != 3) {
    return <Div screen={true} />;
  }
  return (
    <Div screen={true}>
      <BottomSheetDialog
        style={{
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "65%",
          marginTop: "35%",
        }}
        ref={bottomsheet}
      >
        <Div
          width={337}
          height={450}
          borderRadius={8}
          borderWidth={0.8}
          borderColor={Ecolors.bordercolor}
          backgroundColor={Ecolors.whiteColor}
        >
          <ScrollView>
            <Div
              width={"100%"}
              paddingHorizontal={17}
              paddingTop={18}
              alignItems={"center"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Label
                fontWeight={"700"}
              >{`createordermodal.xacnhanthanhtoan`}</Label>
              <Button
                onPress={() => {
                  bottomsheet.current.hide();
                }}
              >
                <ImageView
                  widthHeight={18}
                  tintColor={Ecolors.textColor}
                  source={Icons.close}
                />
              </Button>
            </Div>
            <Div
              marginTop={13}
              height={1}
              width={"100%"}
              backgroundColor={Ecolors.grayColor}
            />
            <Label
              fontWeight={"700"}
              marginHorizontal={17}
              size={14}
              marginTop={18}
            >{`createordermodal.contentxacnhanthanhtoan`}</Label>
            <Label
              marginHorizontal={17}
              marginTop={3}
              size={14}
            >{`createordermodal.luuyttck`}</Label>
            <Div
              borderRadius={8}
              marginHorizontal={18}
              borderWidth={0.8}
              backgroundColor={Ecolors.whiteColor}
              marginTop={20}
              borderColor={Ecolors.bordercolor}
              paddingHorizontal={20}
              paddingTop={20}
              paddingBottom={24}
            >
              <ContentCoppy
                title={`createordermodal.tenthuhuong`}
                isBtn={true}
                content={
                  I18nState == "vi"
                    ? bankSuperVisory?.name
                    : bankSuperVisory?.nameEn || bankSuperVisory?.name
                }
                isBorderBottom={true}
              />
              <ContentCoppy
                marginTop={11}
                isBtn={true}
                title={`createordermodal.sotaikhoan`}
                content={bankSuperVisory?.number || ""}
                isBorderBottom={true}
              />
              <ContentCoppy
                marginTop={11}
                isBtn={false}
                title={`createordermodal.nganhang`}
                content={
                  I18nState == "vi"
                    ? bankSuperVisory?.dataBank?.name
                    : bankSuperVisory?.dataBank?.nameEn || ""
                }
                isBorderBottom={true}
              />
              <ContentCoppy
                marginTop={11}
                isBtn={true}
                title={`createordermodal.noidung`}
                content={excuseTempVolumn?.investmentNumber || ""}
                isBorderBottom={!!scheme?.productSchemeIsAutoBuy}
              />
              {scheme && scheme?.productSchemeIsAutoBuy && (
                <>
                  <RowSpaceItem marginTop={15}>
                    <Label
                      size={14}
                    >{`createordermodal.tudongtieptucdautu`}</Label>
                    <Label multilanguage={false} size={14}>
                      {I18nState == "vi" ? "Có" : "Yes"}
                    </Label>
                  </RowSpaceItem>
                  <RowSpaceItem marginTop={8}>
                    <Label
                      size={14}
                    >{`createordermodal.ngaythanhtoanhangthang`}</Label>
                    <Label multilanguage={false} size={14}>
                      {beginBuyAutoStartDate || ""}
                    </Label>
                  </RowSpaceItem>
                </>
              )}
            </Div>
            <Div marginHorizontal={16}>
              <Div
                flexDirection={"row"}
                marginVertical={15}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Div
                  height={0.8}
                  width={100}
                  backgroundColor={Ecolors.bordercolor}
                />
                <Label>{`createordermodal.hoac`}</Label>
                <Div
                  height={0.8}
                  width={100}
                  backgroundColor={Ecolors.bordercolor}
                />
              </Div>
              <QRCode data={data} />
            </Div>
          </ScrollView>
          <Div
            width={"100%"}
            paddingVertical={20}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <ButtonBorder
              onPress={() => {
                bottomsheet.current.hide();
                goBack();
              }}
              width={303}
              title={`createordermodal.xacnhan`}
            />
          </Div>
        </Div>
      </BottomSheetDialog>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Div
          width={"100%"}
          marginTop={27}
          paddingHorizontal={16}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ImageView
            source={Icons.createordersuccess}
            width={222}
            height={160}
            resizeMode={"contain"}
          />
          <Label
            size={18}
            textAlign={"center"}
            fontWeight={"700"}
            marginTop={11}
          >{`createordermodal.datlenhmuathanhcong`}</Label>
          <Div width={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Label
              size={14}
              marginTop={11}
              textAlign={"center"}
            >{`createordermodal.camonquykhachdadautuvao`}</Label>
            <Label
              multilanguage={false}
              textAlign={"center"}
              marginHorizontal={55}
            >
              {I18nState == "vi" ? product?.name : product?.nameEn || ""}
            </Label>
          </Div>

          <Div
            flexDirection="row"
            justifyContent="space-around"
            width="100%"
            marginVertical={15}
          >
            <ButtonBorder
              size={13.5}
              width={176}
              height={30}
              onPress={() => {
                setCurrentTab(0);
              }}
              type={currentTab == 0 ? 5 : 4}
              title={`createordermodal.thanhtoanbangqrcode`}
            />
            <ButtonBorder
              size={13.5}
              width={170}
              height={30}
              onPress={() => {
                setCurrentTab(1);
              }}
              type={currentTab == 1 ? 5 : 4}
              title={`createordermodal.thongtinchuyenkhoan`}
            />
          </Div>

          {currentTab == 0 && <QRCode data={data} />}
          {currentTab == 1 && (
            <Div>
              <Label marginTop={3}>{`createordermodal.luuyttck`}</Label>
              <Div
                width={"100%"}
                marginTop={9}
                borderRadius={8}
                borderWidth={0.8}
                borderColor={Ecolors.bordercolor}
                backgroundColor={Ecolors.whiteColor}
                paddingHorizontal={20}
                paddingTop={20}
                paddingBottom={24}
              >
                <ContentCoppy
                  title={`createordermodal.tenthuhuong`}
                  isBtn={true}
                  content={
                    I18nState == "vi"
                      ? bankSuperVisory?.name
                      : bankSuperVisory?.nameEn || bankSuperVisory?.name
                  }
                  isBorderBottom={true}
                />
                <ContentCoppy
                  marginTop={11}
                  isBtn={true}
                  title={`createordermodal.sotaikhoan`}
                  content={bankSuperVisory?.number || ""}
                  isBorderBottom={true}
                />
                <ContentCoppy
                  marginTop={11}
                  title={`createordermodal.nganhang`}
                  isBtn={false}
                  content={
                    I18nState == "vi"
                      ? bankSuperVisory?.dataBank?.name
                      : bankSuperVisory?.dataBank?.nameEn || ""
                  }
                  isBorderBottom={true}
                />
                <ContentCoppy
                  marginTop={11}
                  isBtn={true}
                  isBorderBottom={!!scheme?.productSchemeIsAutoBuy}
                  title={`createordermodal.noidung`}
                  // content={`${currentUser?.name || ''}-${
                  //   excuseTempVolumn?.investmentNumber
                  // }-${scheme?.tradeCode || ''}`}
                  content={excuseTempVolumn?.investmentNumber || ""}
                />
                {scheme && scheme?.productSchemeIsAutoBuy && (
                  <>
                    <RowSpaceItem marginTop={11}>
                      <Label
                        size={14}
                      >{`createordermodal.tudongtieptucdautu`}</Label>
                      <Label multilanguage={false} size={14}>
                        {I18nState == "vi" ? "Có" : "Yes"}
                      </Label>
                    </RowSpaceItem>
                    <RowSpaceItem marginTop={8}>
                      <Label
                        size={14}
                      >{`createordermodal.ngaythanhtoanhangthang`}</Label>
                      <Label multilanguage={false} size={14}>
                        {beginBuyAutoStartDate || ""}
                      </Label>
                    </RowSpaceItem>
                  </>
                )}
              </Div>
            </Div>
          )}

          <Div
            marginTop={23}
            paddingHorizontal={20}
            marginHorizontal={0}
            paddingTop={20}
            width={"100%"}
            paddingBottom={23}
            borderRadius={8}
            borderWidth={0.8}
            borderColor={Ecolors.bordercolor}
            backgroundColor={Ecolors.whiteColor}
          >
            <RowSpaceItem isBorderBottom={true}>
              <Label size={14}>{`createordermodal.quydautu`}</Label>
              <Div
                flex={1}
                flexDirection={"row"}
                alignItems={"center"}
                paddingLeft={10}
                justifyContent={"flex-end"}
              >
                <Label size={14} multilanguage={false}>
                  {I18nState == "vi" ? product?.name : product?.nameEn}
                </Label>
              </Div>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.chuongtrinh`}</Label>
              <Div
                flex={1}
                flexDirection={"row"}
                alignItems={"center"}
                paddingLeft={10}
                justifyContent={"flex-end"}
              >
                <Label size={14} multilanguage={false}>
                  {I18nState == "vi" ? scheme?.name : scheme?.nameEn}
                </Label>
              </Div>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.loailenh`}</Label>
              <Label size={14}>{`createordermodal.mua`}</Label>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.ngaydatlenh`}</Label>
              <Label size={14} multilanguage={false}>
                {convertTimestamp(new Date().getTime(), "DD/MM/yyyy, HH:mm")}
                {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
              </Label>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.phiengiaodich`}</Label>
              <Label multilanguage={false} size={14}>
                {currentSession?.tradingTimeString}
                {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
              </Label>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15}>
              <Label size={14}>{`createordermodal.sotienmua`}</Label>
              <Label size={14} multilanguage={false}>
                {convertNumber(amount)}
              </Label>
            </RowSpaceItem>
            {scheme && scheme?.productSchemeIsAutoBuy && (
              <>
                <RowSpaceItem marginTop={15}>
                  <Label
                    size={14}
                  >{`createordermodal.tudongtieptucdautu`}</Label>
                  <Label multilanguage={false} size={14}>
                    {I18nState == "vi" ? "Có" : "Yes"}
                  </Label>
                </RowSpaceItem>
                <RowSpaceItem marginTop={8}>
                  <Label
                    size={14}
                  >{`createordermodal.ngaythanhtoanhangthang`}</Label>
                  <Label multilanguage={false} size={14}>
                    {beginBuyAutoStartDate || ""}
                  </Label>
                </RowSpaceItem>
              </>
            )}
          </Div>
        </Div>
        <Div height={100} />
      </ScrollView>
      {/* <RowSpaceItem
        justifyContent={"center"}
        paddingHorizontal={29}
        marginTop={10}
      > */}
      {/* <ButtonBorder
          width={148}
          height={48}
          onPress={() => {
            // goBack();
            onPre && onPre();
            // bottomsheet.current.show();
          }}
          title={`createordermodal.muathem`}
          type={2}
        /> */}
      <Div
        marginTop={10}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width={"100%"}
      >
        <ButtonBorder
          onPress={() => {
            goBack();
            PostMSG("CloseFlowBuyOrder", orderData);
            // bottomsheet.current.hide();
          }}
          height={48}
          title={`createordermodal.hoantatvachuyentien`}
        />
      </Div>
      {/* </RowSpaceItem> */}
      <Div height={200} />
    </Div>
  );
}

export default React.memo(OrderBuyStep3);
