import { Alert, Button, Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useRef, useState } from "react";
import { apiTransaction } from "services/apiTransaction";
import ListTransaction from "./ListTransaction";

const Row = (p: {
  title: string;
  pressKey: string;
  onPress: (t: string) => void;
  isFocus: boolean;
}) => {
  return (
    <Button
      onPress={() => p.onPress(p.pressKey)}
      width={103}
      borderRadius={5}
      borderWidth={0.8}
      borderColor={p.isFocus ? Ecolors.mainColor : Ecolors.textColor}
      backgroundColor={p.isFocus ? Ecolors.mainColor : Ecolors.whiteColor}
      height={36}
      alignItems="center"
      justifyContent="center"
    >
      <Label
        fontWeight="bold"
        color={p.isFocus ? Ecolors.whiteColor : Ecolors.textColor}
        size={12}
      >
        {p.title}
      </Label>
    </Button>
  );
};

const OrderTransaction = () => {
  const [tabFocus, setTabfocus] = useState<"BUY" | "SELL" | "TRANSFER_ALL">(
    "BUY"
  );
  const [data, setData] = useState<Array<any>>([]);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const pageLoadData = useRef<any>(1);

  useEffect(() => {
    loadData({ tabFocus });
    return () => {};
  }, [tabFocus]);

  const loadData = async (p: { tabFocus; page?: 1 }) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      pageLoadData.current = p.page;
      const res = await apiTransaction.loadByStatus({
        pagination: {
          currentPage: p.page,
          itemsPerPage: 10,
        },
        queries: {
          orderType: p.tabFocus,
        },
      });
      if (res.status == 200 && res.data.queries.orderType == p.tabFocus) {
        setData(res.data.items);
        setTotalItemCount(res.data.pagination.totalItemCount);
        return;
      }
      Alert.showError({
        content: res.message || "",
        multilanguage: false,
      });
    } catch (error: any) {
      // Alert.showError({
      //   content: error.message || "",
      //   multilanguage: false,
      // });
    } finally {
      setLoading(false);
    }
  };

  const onChangeTab = (t) => {
    setTotalItemCount(0);
    setData([]);
    setTabfocus(t);
  };

  return (
    <Div flex={1}>
      <Div
        flexDirection="row"
        alignItems="center"
        padding={15}
        paddingBottom={5}
        justifyContent={"space-between"}
      >
        <Row
          title="transactionscreen.lenhchomua"
          pressKey="BUY"
          onPress={(t: any) => onChangeTab(t)}
          isFocus={tabFocus == "BUY"}
        />
        <Row
          title="transactionscreen.lenhchoban"
          pressKey="SELL"
          onPress={(t: any) => onChangeTab(t)}
          isFocus={tabFocus == "SELL"}
        />
        <Row
          title="transactionscreen.lenhhoandoi"
          pressKey="TRANSFER_ALL"
          onPress={(t: any) => onChangeTab(t)}
          isFocus={tabFocus == "TRANSFER_ALL"}
        />
      </Div>

      <ListTransaction
        totalItemCount={totalItemCount}
        itemRender={tabFocus}
        onRefresh={() => {
          loadData({ tabFocus, page: 1 });
        }}
        onLoadMore={() => {
          //   loadData({ tabFocus, page: pageLoadData.current + 1 });
        }}
        data={data}
        refreshing={loading}
      />
    </Div>
  );
};

export default React.memo(OrderTransaction);
