import axios, { AxiosError, AxiosResponse } from "axios";
import { Alert } from "components";
import { urlApp } from "constants/AppUrl";
import { getStoreData } from "utils/storage";
import { PostMSG } from "utils/utils";
const baseURL = urlApp.APIURL;
const axiosApp = axios.create({
  baseURL: baseURL + "api/",
  timeout: 10000,
});
const axiosAppV3 = axios.create({
  baseURL: urlApp.APIURLV3 + "api/",
  timeout: 10000,
});
const axiosAppV3TypeText = axios.create({
  baseURL: urlApp.APIURLV3 + "api/",
  timeout: 10000,
});

const aa = axios.create({
  baseURL: baseURL + "api/",
  timeout: 10000,
});

const functionAuthen = (err) => {
  if (err.status == 401) {
    Alert.showError({
      content: err.message,
      multilanguage: false,
      type: 2,
      onClose: () => {
        PostMSG("RequestAuthenFailure", {});
      },
      onConfirm: () => {
        PostMSG("RequestAuthenFailure", {});
      },
    });
    return;
  }
};

axiosApp.interceptors.request.use(
  async (config: any) => {
    const refCode = await getStoreData("refCode");
    const mToken = await getStoreData("mToken");
    const idCode = await getStoreData("idCode");
    const idNo = await getStoreData("idNo");
    const phone = await getStoreData("phone");
    config.headers["Authorization"] = `Bearer ${mToken || "token"}`;
    config.headers["phone"] = phone;
    config.headers["idNo"] = idNo;
    config.headers["refCode"] = refCode;
    config.headers["idCode"] = idCode;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosAppV3.interceptors.request.use(
  async (config: any) => {
    const refCode = await getStoreData("refCode");
    const mToken = await getStoreData("mToken");
    const idCode = await getStoreData("idCode");
    const phone = await getStoreData("phone");
    const idNo = await getStoreData("idNo");
    config.headers["Authorization"] = `Bearer ${mToken || "token"}`;
    config.headers["phone"] = phone;
    config.headers["idNo"] = idNo;
    config.headers["refCode"] = refCode;
    config.headers["idCode"] = idCode;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error: any) => Promise.reject(error)
);

axiosAppV3TypeText.interceptors.request.use(
  async (config: any) => {
    const refCode = await getStoreData("refCode");
    const mToken = await getStoreData("mToken");
    const idCode = await getStoreData("idCode");
    const phone = await getStoreData("phone");
    const idNo = await getStoreData("idNo");
    config.headers["Authorization"] = `Bearer ${mToken || "token"}`;
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    config.headers["phone"] = phone;
    config.headers["idNo"] = idNo;
    config.headers["refCode"] = refCode;
    config.headers["idCode"] = idCode;
    return config;
  },
  (error: any) => Promise.reject(error)
);

aa.interceptors.request.use(
  async (config: any) => {
    const refCode = await getStoreData("refCode");
    const mToken = await getStoreData("mToken");
    const idCode = await getStoreData("idCode");
    const phone = await getStoreData("phone");
    const idNo = await getStoreData("idNo");
    config.headers["Authorization"] = `Bearer ${mToken || "token"}`;
    config.headers["Content-Type"] = "multipart/form-data";
    config.headers["Accept"] = "application/json";
    config.headers["idCode"] = idCode;
    config.headers["phone"] = phone;
    config.headers["idNo"] = idNo;
    config.headers["refCode"] = refCode;
    return config;
  },
  (error: any) => Promise.reject(error)
);

aa.interceptors.response.use(
  (res: AxiosResponse<{ content: any; message: string; result: number }>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        messageEn?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>
  ) => {
    throw (
      err.response?.data || {
        message: "Lỗi mạng",
        messageEn: "Lỗi mạng",
        content: null,
        result: -1,
        data: null,
      }
    );
  }
);

axiosApp.interceptors.response.use(
  (res: AxiosResponse<{ content: any; message: string; result: number }>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>
  ) => {
    throw (
      err.response?.data || {
        message: "",
        content: null,
        result: -1,
        data: null,
      }
    );
  }
);

axiosAppV3.interceptors.response.use(
  (res: AxiosResponse<{ content: any; message: string; result: number }>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>
  ) => {
    throw (
      err.response?.data || {
        message: "",
        content: null,
        result: -1,
        data: null,
      }
    );
  }
);

axiosAppV3TypeText.interceptors.response.use(
  (res: AxiosResponse<{ content: any; message: string; result: number }>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>
  ) => {
    throw (
      err.response?.data || {
        message: "",
        content: null,
        result: -1,
        data: null,
      }
    );
  }
);

export async function doGetAxios(url: string): Promise<any> {
  try {
    const res: AxiosResponse = await axiosApp.get(url);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}

export async function doPostAxios(url: string, params: object): Promise<any> {
  try {
    const res: AxiosResponse = await axiosApp.post(url, JSON.stringify(params));
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}
export async function doPostAxiosV3(url: string, params: object): Promise<any> {
  try {
    const res: AxiosResponse = await axiosAppV3.post(
      url,
      JSON.stringify(params)
    );
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}
export async function doPostAxiosV3TypeText(
  url: string,
  params: object | any
): Promise<any> {
  try {
    const res: AxiosResponse = await axiosAppV3TypeText.post(url, params);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}

export async function axiosMultipart(
  url: string,
  params: any
): Promise<AxiosResponse> {
  try {
    const res: AxiosResponse = await aa.post(url, params);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}
export async function axiosGetData(url: string): Promise<AxiosResponse> {
  try {
    const res: AxiosResponse = await aa.get(url);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error: any) {
    if (error.status != 401) {
      throw error;
    }
    functionAuthen(error);
    return;
  }
}
