import { getStoreData, Log } from "utils";
import { getUuid } from "./../../utils/utils";
import { Alert } from "components";
import { apiAuth } from "services/api";
import { navigate } from "services/navigation";

export const doUploadFileSignature = async (p: {
  link: string;
  setLoading: (t: boolean) => void;
  I18nState: "en" | "vi";
}) => {
  try {
    const bb = await fetch(p.link);
    const blob = await bb.blob();
    const id = getUuid();
    p.setLoading(true);
    const formdata = new FormData();
    const file = new File([blob], `${id}.png`);
    formdata.append("file", file);
    const res = await apiAuth.createEsignature(formdata);
    const phone = await getStoreData("phone");
    if (res.status == 200 && !!res.data.otpInfo) {
      navigate("OtpRequestModal", {
        data: {
          requestOnSendOtp: res.data.otpInfo,
          flowApp: "CreateEsignature",
          phone,
        },
      });
      return;
    }
    Alert.showError({
      content: p.I18nState == "vi" ? res.message : res.messageEn,
      multilanguage: false,
    });
    return;
  } catch (error: any) {
    Alert.showError({
      content: p.I18nState == "vi" ? error.message : error.messageEn,
      multilanguage: false,
    });
    p.setLoading(false);
    return;
  } finally {
    p.setLoading(false);
  }
};
