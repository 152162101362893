import { convertStringTime } from "utils/utils";
import { apiMain } from "./apiMain";

export const convertDaTaNewCC = async (cardInfo) => {
  const {
    person,
    backCardImage,
    frontCardImage,
    result: {
      kyc_result: { front, decision, back },
    },
  } = cardInfo;
  const listCountry = await apiMain.getCountry();
  const country = listCountry.data.find((a: any) => a?.id == "234");
  const listProvince = await apiMain.getProvince({
    countryId: 234,
  });
  const province = listProvince.data.find((a: any) =>
    a.administrativeCode == back?.id_address_province?.code
      ? back?.id_address_province?.code
      : 0
  );
  const listDistrict = await apiMain.getDistrict({
    provinceId: province?.id || 0,
  });
  const district = listDistrict.data.find((a: any) =>
    a.administrativeCode == back?.id_address_district?.code
      ? back?.id_address_district?.code
      : 0
  );
  const listWard = await apiMain.getWard({
    districtId: district?.id || 0,
  });
  const ward = listWard.data.find((a: any) =>
    a.administrativeCode == back?.id_address_ward?.code
      ? back?.id_address_ward?.code
      : 0
  );

  const userProfile = {
    gender: person.gender == "MALE" ? 1 : 0,
    dob: convertStringTime(person.dob || ""),
    nationalityId: 234,
    idTypeId: 1,
    idNo: person?.idNumber,
    dateOfIssue: convertStringTime(person.doi),
    placeOfIssue: person.givenPlace,
  };

  const userAddress = {
    permanentAddress: back?.id_address?.value || "",
    countryId: 234,
    provinceId: province?.id || 0,
    districtId: district?.id || 0,
    wardId: ward?.id || 0,
    //
    mailingAddress: back?.id_address?.value || "",
    mailingCountryId: 234,
    mailingProvinceId: province?.id || 0,
    mailingDistrictId: district?.id || 0,
    mailingWardId: ward?.id || 0,
    country,
    province,
    district,
    ward,
  };

  return {
    userProfile,
    userAddress,
    isKYC: decision?.code == 1,
    name: person.fullname,
    person,
    backCardImage,
    frontCardImage,
    cardInfo,
  };
};

export const convertDaTaCCCD = async (cardInfo) => {
  const {
    person,
    backCardImage,
    frontCardImage,
    result: {
      kyc_result: { front, decision },
    },
  } = cardInfo;
  const listCountry = await apiMain.getCountry();
  const country = listCountry.data.find((a: any) => a?.id == "234");
  const listProvince = await apiMain.getProvince({
    countryId: 234,
  });
  const province = listProvince.data.find((a: any) =>
    a.administrativeCode == front?.id_address_province?.code
      ? front?.id_address_province?.code
      : 0
  );
  const listDistrict = await apiMain.getDistrict({
    provinceId: province?.id || 0,
  });
  const district = listDistrict.data.find((a: any) =>
    a.administrativeCode == front?.id_address_district?.code
      ? front?.id_address_district?.code
      : 0
  );
  const listWard = await apiMain.getWard({
    districtId: district?.id || 0,
  });
  const ward = listWard.data.find((a: any) =>
    a.administrativeCode == front?.id_address_ward?.code
      ? front?.id_address_ward?.code
      : 0
  );
  const userProfile = {
    gender: person.gender == "MALE" ? 1 : 0,
    dob: convertStringTime(person.dob || ""),
    nationalityId: 234,
    idTypeId: 1,
    idNo: person?.idNumber,
    dateOfIssue: convertStringTime(person.doi),
    placeOfIssue: person.givenPlace,
  };
  const userAddress = {
    permanentAddress: front?.id_address.value || "",
    countryId: 234,
    provinceId: province?.id || 0,
    districtId: district?.id || 0,
    wardId: ward?.id || 0,
    //
    mailingAddress: front.id_address.value,
    mailingCountryId: 234,
    mailingProvinceId: province?.id || 0,
    mailingDistrictId: district?.id || 0,
    mailingWardId: ward?.id || 0,
    country,
    province,
    district,
    ward,
  };
  return {
    userProfile,
    userAddress,
    isKYC: decision?.code == 1,
    name: person.fullname,
    person,
    backCardImage,
    frontCardImage,
    cardInfo,
  };
};
