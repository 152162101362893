import { useRoute } from "@react-navigation/core";
import { Button, Div, ImageView, Input, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React, { useEffect, useRef, useState } from "react";
import { FlatList, RefreshControl } from "react-native";
import { doGetAxios } from "services/axios";
import { goBack } from "services/navigation";
import { heightScale } from "utils";
import { convertShortName, removeAllSpace, removeUtf8 } from "utils/utils";
interface p {
  propsData?: any;
}
const ITEM_HEIGHT = heightScale(40);
function DropdownModal({ propsData }: p) {
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const flatlistRef = useRef<any>(null);
  const [textsearch, setTextsearch] = useState("");

  const route = useRoute<any>();
  const { value, url, initData, onChange, content, multilanguage, isSearch } =
    route?.params?.data || propsData;

  useEffect(() => {
    if (initData?.length > 0) {
      setData(initData);
      setDataSearch(initData);
    } else {
      getData();
    }
    return () => {};
  }, [initData]);

  useEffect(() => {
    setTimeout(() => {
      if (flatlistRef.current && data.length > 0) {
        flatlistRef.current.scrollToIndex({
          index: value?.index || 0,
          animated: true,
        });
      }
    }, 100);

    return () => {};
  }, []);

  const onRefresh = () => {
    if (initData?.length > 0) {
      return;
    }
    getData();
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await doGetAxios(propsData.url || "");
      if (res.status == 200) {
        const d = res.data.map((item: any, index: number) => {
          return {
            ...item,
            textsearch: `${removeAllSpace(removeUtf8(item.name))}${
              item.shortName
            }${item.stockName}${item.swiftCode}`.toLocaleLowerCase(),
          };
        });
        setData(d);
        setDataSearch(d);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onPressItem = (item: any) => {
    propsData.onChange && propsData.onChange(item);
    closeModal();
  };

  const closeModal = () => {
    if (propsData?.onClose) {
      propsData?.onClose();
    } else {
      goBack();
    }
  };

  useEffect(() => {
    if (textsearch?.length == 0) {
      return;
    }
    filterData(textsearch);
    return () => {};
  }, [textsearch]);

  const filterData = (e: string) => {
    setData((a) =>
      dataSearch?.filter((x: { textsearch: string }) =>
        x.textsearch?.includes(
          removeAllSpace(removeUtf8(e).toLocaleLowerCase())
        )
      )
    );
  };

  const keyExtractor = (item: any, index: number) => ` key${item.id}`;

  const renderItem = (params: { item: any; index: number }) => {
    const isFocus = params.item.id == value?.id;
    const c_code = propsData.isShortName
      ? convertShortName(params.item?.shortName)
      : params.item?.code || "";
    const title = `${c_code ? `${c_code} - ` : ""}${params.item?.name}`;

    return (
      <Button
        onPress={() => onPressItem({ ...params.item, index: params.index })}
        flexDirection={"row"}
        alignItems={"center"}
        marginRight={20}
        borderBottomWidth={1}
        paddingVertical={5}
        borderBottomColor={Ecolors.spaceColor}
        paddingLeft={22}
      >
        <Label
          color={isFocus ? Ecolors.blue : Ecolors.textColor}
          multilanguage={false}
        >
          {title}
        </Label>
      </Button>
    );
  };

  const ListFooterComponent = () => {
    return <Div height={330} />;
  };

  const title = value ? value.name : content;

  return (
    <Button isScale={false} onPress={() => closeModal()}>
      <Div
        screen={true}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Div
          backgroundColor={Ecolors.whiteColor}
          height={"80%"}
          flexDirection={"column"}
          borderTopLeftRadius={5}
          width={"100%"}
          borderTopRightRadius={5}
          borderWidth={1}
          borderColor={Ecolors.spaceColor}
        >
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            paddingLeft={14}
            justifyContent={"space-between"}
          >
            <Label
              fontWeight={"700"}
              multilanguage={(multilanguage && !value) || false}
            >
              {title}
            </Label>
            <Button
              onPress={() => {
                closeModal();
              }}
              paddingVertical={15}
              paddingHorizontal={18}
            >
              <ImageView
                widthHeight={15}
                source={Icons.close}
                resizeMode={"contain"}
              />
            </Button>
          </Div>
          <Div
            marginHorizontal={10}
            height={36}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            borderRadius={5}
            borderWidth={1}
            marginBottom={10}
            borderColor={Ecolors.spaceColor}
          >
            <Input
              value={textsearch}
              onChangeText={(e: string) => setTextsearch(e)}
              paddingHorizontal={10}
              paddingVertical={0}
              autoFocus={true}
            />
          </Div>
          <FlatList
            keyboardShouldPersistTaps={"handled"}
            ref={flatlistRef}
            data={data}
            extraData={data}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            getItemLayout={(data, index) => ({
              length: ITEM_HEIGHT,
              offset: ITEM_HEIGHT * index,
              index,
            })}
            ListFooterComponent={ListFooterComponent}
            refreshControl={
              <RefreshControl refreshing={loading} onRefresh={onRefresh} />
            }
          />
        </Div>
      </Div>
    </Button>
  );
}

export default React.memo(DropdownModal);
