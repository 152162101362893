import { Alert, ButtonBorder, Div, HeaderBack } from "components";
import { Ecolors } from "constants/themes";
import React, { useRef, useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import { draftQuestion } from "reducer/authen";
import { apiMain } from "services/apiMain";
import { goBack, navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { widthScreen } from "utils";
import ProcessHeader from "./ProcessHeader";
import StepComQuestion from "./StepComQuestion";

function EditRiskQuestionModal() {
  const questions = useAppSelector((state) => state.authen.question.questions);
  const [listAnswer, setListAnswer] = useState<Array<any>>([]);
  const [indexFocus, setIndexFocus] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const flatlistRef = useRef();
  const insets = useSafeAreaInsets();
  const dispatch = useDispatch();

  const onScroll = (t: any) => {
    const offsetX = t.nativeEvent.contentOffset.x;
    const Page = Math.round(offsetX / widthScreen);
    if (Page != indexFocus) {
      setIndexFocus(Page);
    }
  };
  const getItemLayout = (data, index) => ({
    length: widthScreen,
    offset: widthScreen * index,
    index,
  });

  const onAnswer = (t: any) => {
    setListAnswer([...listAnswer.filter((item: any) => item.id != t.id), t]);
  };

  const renderItem = (p: { item: any; index: number }) => {
    return (
      <StepComQuestion
        data={{ ...p.item, index: p.index }}
        onAnswer={(t: any) => {
          onAnswer(t);
        }}
      />
    );
  };
  const keyExtractor = (item, index: number) => `key${index}`;

  const onPrevious = () => {
    if (indexFocus == 0) {
      goBack();
      return;
    }
    setIndexFocus((t) => t - 1);
    scrollTo(indexFocus - 1);
  };
  const onNext = () => {
    if (listAnswer?.length <= indexFocus) {
      return;
    }
    if (indexFocus == questions?.length - 1) {
      submitForm();
      return;
    }
    setIndexFocus((t) => t + 1);
    scrollTo(indexFocus + 1);
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      const obj = {
        appetiteId: null,
        questionResults: listAnswer,
        isSave: false,
      };
      const t: any = await apiMain.submitQuestion(obj);
      if (t.status == 200) {
        dispatch(draftQuestion(obj));
        navigate("ResultModal", {
          flow: "Submit",
          data: t.data,
        });
        return;
      }
      Alert.showError({
        content: t?.message,
        type: 2,
        multilanguage: false,
        title: `alert.thatbai`,
        onConfirm: () => {
          navigate("ResultModal");
        },
      });
      return;
    } catch (error: any) {
      Alert.showError({
        content: error.message,
        type: 2,
        multilanguage: false,
        title: `alert.thatbai`,
        onConfirm: () => {
          navigate("ResultModal");
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const scrollTo = async (index: number) => {
    if (!flatlistRef.current) {
      return;
    }
    flatlistRef.current?.scrollToIndex({
      animated: true,
      index: index,
    });
  };

  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack
        type={2}
        title={`riskquestionscreen.bangkhaosatkhauviruiro`}
      />
      <ProcessHeader index={indexFocus} max={questions?.length} />
      {/* <FlatList
        ref={flatlistRef}
        onScroll={onScroll}
        data={questions}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        extraData={questions}
        scrollEnabled={false}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        snapToInterval={widthScreen}
        snapToAlignment="start"
        getItemLayout={getItemLayout}
      /> */}
      <StepComQuestion
        data={{ ...questions[indexFocus], index: indexFocus }}
        onAnswer={(t: any) => {
          onAnswer(t);
        }}
      />
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        marginTop={10}
        paddingHorizontal={29}
        justifyContent={"space-between"}
        width={"100%"}
        marginBottom={insets.bottom + 20}
      >
        <ButtonBorder
          onPress={() => {
            onPrevious();
          }}
          width={148}
          height={48}
          title={`riskquestionscreen.trove`}
          type={2}
        />
        <ButtonBorder
          onPress={() => {
            onNext();
          }}
          loading={loading}
          width={148}
          height={48}
          title={`riskquestionscreen.ketiep`}
          type={1}
        />
      </Div>
    </Div>
  );
}

export default React.memo(EditRiskQuestionModal);
