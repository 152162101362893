import { Alert, Button, Div, ImageView, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React from "react";
import { apiTransaction } from "services/apiTransaction";
import { useAppSelector } from "store/hooks";

import {
  convertNav,
  convertNumber,
  convertReceiveAmount,
  convertTimestamp,
} from "utils/utils";

const onConfirmDeleteOrder = async (
  id: number,
  I18nState: any,
  onRefresh: any
) => {
  Alert.show({
    content: `alert.xacnhanxoalenhgiaodich`,
    onConfirm: async () => {
      try {
        const res = await apiTransaction.deleteOrder({
          orderId: id,
        });
        if (res.status == 200) {
          onRefresh && onRefresh();
          // dispatch(deleteOrder(id));
          Alert.show({
            content: `alert.xoalenh`,
            type: 2,
            titleClose: "alert.dong",
            onConfirm: () => {
              // dispatch(deleteOrder(id));
            },
          });
          // dispatch(deleteOrder(id));
          return;
        }
        Alert.show({
          content: I18nState == "vi" ? res.message : res.messageEn,
          multilanguage: false,
          onConfirm: () => {},
        });
      } catch (error: any) {
        Alert.show({
          content: I18nState == "vi" ? error.message : error.messageEn,
          multilanguage: false,
          onConfirm: () => {},
        });
      } finally {
        // setLoading(false);
      }
    },
  });
};

function DeleteCom(p: { id: number; onRefresh: any }) {
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  return (
    <Button
      onPress={() => {
        onConfirmDeleteOrder(p.id, I18nState, () => {
          p.onRefresh && p.onRefresh();
        });
      }}
      flexDirection="row"
      alignItems="center"
      borderRadius={5}
      borderWidth={0.8}
      paddingHorizontal={5}
      borderColor={Ecolors.bordercolor}
      justifyContent="center"
    >
      <ImageView
        source={Icons.delete}
        widthHeight={12}
        resizeMode="contain"
        tintColor={Ecolors.textColor}
      />
      <Label
        size={14}
        marginBottom={2}
        marginHorizontal={5}
      >{`transactionscreen.huylenh`}</Label>
    </Button>
  );
}

function RowSpaceItem(p: { paddingTop?: number; children?: any }) {
  return (
    <Div
      paddingTop={p.paddingTop ?? 0}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      {p.children && p.children}
    </Div>
  );
}

function ISwap(p: { title: string }) {
  return (
    <Div
      width={149}
      height={68}
      borderWidth={0.7}
      borderColor={Ecolors.bordercolor}
      alignItems={"center"}
      justifyContent={"center"}
      borderRadius={8}
      backgroundColor={Ecolors.whiteColor}
    >
      <Label textAlign={"center"} multilanguage={false}>
        {p.title || ""}
      </Label>
    </Div>
  );
}

export function ItemOrderBuy(p: {
  data: any;
  hideDelete?: boolean;
  hideStatusReceiveAmount?: boolean;
  onRefresh: any;
}) {
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  const {
    productProgramName,
    productProgramNameEn,
    lockAmount,
    sessionTime,
    createAt,
    statusName,
    id,
    receivedAmount,
    receivedMoney,
    statusCode,
  } = p.data;

  return (
    <Button
      isScale={false}
      onPress={() => {
        // navigate('OrderBuyDetailsModal', {
        //   data: p.data,
        //   hideDelete: p.hideDelete,
        //   hideStatusReceiveAmount: p.hideStatusReceiveAmount,
        // });
      }}
      marginHorizontal={16}
      borderRadius={8}
      borderWidth={0.8}
      borderColor={Ecolors.bordercolor}
      backgroundColor={Ecolors.whiteColor}
      paddingHorizontal={15}
      paddingTop={13}
      paddingBottom={18}
      minHeight={100}
    >
      <RowSpaceItem>
        <Label size={14}>{`transactionscreen.quychuongtrinh`}</Label>
        <Label size={14}>{`transactionscreen.sotienmua`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label fontWeight="bold" multilanguage={false} size={14}>
          {I18nState == "vi" ? productProgramName : productProgramNameEn}
        </Label>
        <Label fontWeight="bold" multilanguage={false} size={14}>
          {convertNumber(lockAmount, false)}
        </Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={14}>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.phiengiaodich`}</Label>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.trangthai`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label multilanguage={false} size={14}>
          {convertTimestamp(sessionTime)}
        </Label>
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Div
            widthHeight={10}
            marginRight={8}
            borderRadius={10}
            backgroundColor={
              statusCode == "ORDER_REJECT"
                ? Ecolors.redColor
                : Ecolors.yellowColor
            }
          />
          <Label multilanguage={false} size={14}>
            {I18nState == "vi"
              ? statusName
              : statusCode == "ORDER_REJECT"
              ? "Not matched"
              : statusCode == "ORDER_RECONCILED"
              ? `Matched`
              : "Pending"}
          </Label>
        </Div>
      </RowSpaceItem>
      {!p.hideStatusReceiveAmount &&
        statusCode != "ORDER_REJECT" &&
        receivedMoney != -1 && (
          <RowSpaceItem paddingTop={5}>
            <DeleteCom id={id} onRefresh={p.onRefresh} />
            <Label
              multilanguage={false}
              size={11}
              // fontFamily={Platform.OS == 'android' ? Efonts.SVNItalicAndroid : Efonts.SVNItalicIos}
              color={receivedMoney == 1 ? Ecolors.growColor : Ecolors.redColor}
            >
              {convertReceiveAmount(receivedMoney, I18nState)}
            </Label>
          </RowSpaceItem>
        )}
    </Button>
  );
}

export function ItemOrderSell(p: { data: any; onRefresh: any }) {
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  const {
    productProgramName,
    productProgramNameEn,
    volume,
    createAt,
    statusName,
    statusCode,
    sessionTime,
    id,
  } = p.data;

  return (
    <Button
      isScale={false}
      onPress={() => {
        // navigate('OrderSellDetailsModal', {
        //   data: p.data,
        // });
      }}
      marginHorizontal={16}
      borderRadius={8}
      backgroundColor={Ecolors.whiteColor}
      borderWidth={0.8}
      borderColor={Ecolors.bordercolor}
      paddingHorizontal={15}
      paddingTop={13}
      paddingBottom={18}
      minHeight={100}
    >
      <RowSpaceItem>
        <Label size={14}>{`transactionscreen.quychuongtrinh`}</Label>
        <Label size={14}>{`transactionscreen.soluong`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label fontWeight="bold" multilanguage={false} size={14}>
          {I18nState == "vi" ? productProgramName : productProgramNameEn}
        </Label>
        <Label multilanguage={false} size={14}>
          {convertNav(volume, true)}
        </Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={14}>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.phiengiaodich`}</Label>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.trangthai`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label multilanguage={false} size={14}>
          {convertTimestamp(sessionTime)}
        </Label>
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Div
            widthHeight={10}
            marginRight={8}
            borderRadius={10}
            backgroundColor={
              statusCode == "ORDER_REJECT"
                ? Ecolors.redColor
                : Ecolors.yellowColor
            }
          />
          <Label multilanguage={false} size={14}>
            {I18nState == "vi"
              ? statusName
              : statusCode == "ORDER_REJECT"
              ? "Not matched"
              : statusCode == "ORDER_RECONCILED"
              ? `Matched`
              : "Pending"}
          </Label>
        </Div>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <DeleteCom id={id} onRefresh={p.onRefresh} />
        <Div />
      </RowSpaceItem>
    </Button>
  );
}

export function ItemOrderTransfer(p: { data: any; onRefresh: any }) {
  const {
    productProgramName,
    volume,
    createAt,
    closedBookTime,
    statusName,
    productCode,
    destOrderInfo,
    statusCode,
    sessionTime,
    code,
    beginVolume,
    lockAmount,
    productProgramNameEn,
    id,
    price,
    orderType,
  } = p.data;
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  return (
    <Button
      isScale={false}
      onPress={() => {
        // navigate('OrderTransferDetailsModal', {
        //   data: p.data,
        // });
      }}
      marginHorizontal={16}
      backgroundColor={Ecolors.whiteColor}
      borderRadius={8}
      borderWidth={0.8}
      borderColor={Ecolors.bordercolor}
      paddingHorizontal={15}
      paddingTop={13}
      paddingBottom={18}
      minHeight={100}
    >
      <RowSpaceItem>
        <Label size={14}>{`transactionscreen.quychuongtrinh`}</Label>
        <Label size={14}>{`transactionscreen.phiengiaodich`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label fontWeight="bold" multilanguage={false} size={14}>
          {productProgramName}
        </Label>
        <Label fontWeight="bold" multilanguage={false} size={14}>
          {convertTimestamp(sessionTime)}
        </Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={14}>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.loailenh`}</Label>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.trangthai`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label size={14}>
          {orderType?.code == "TRANSFER_SELL"
            ? `transactionscreen.lenhbanhoandoi`
            : `transactionscreen.lenhmuahoandoi`}
        </Label>
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Div
            widthHeight={10}
            marginRight={8}
            borderRadius={10}
            backgroundColor={
              statusCode == "ORDER_REJECT"
                ? Ecolors.redColor
                : Ecolors.yellowColor
            }
          />
          <Label multilanguage={false} size={14}>
            {I18nState == "vi"
              ? statusName
              : statusCode == "ORDER_REJECT"
              ? "Not matched"
              : statusCode == "ORDER_RECONCILED"
              ? `Matched`
              : "Pending"}
          </Label>
        </Div>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <DeleteCom id={id} onRefresh={p.onRefresh} />
        <Div />
      </RowSpaceItem>
    </Button>
  );

  return (
    <Button
      isScale={false}
      onPress={() => {
        navigate("OrderTransferDetailsModal", {
          data: p.data,
        });
      }}
      marginHorizontal={16}
      backgroundColor={Ecolors.whiteColor}
      style={EStyle.shadowItem}
      borderRadius={8}
      borderWidth={0.8}
      borderColor={Ecolors.bordercolor}
      paddingHorizontal={15}
      paddingTop={13}
      paddingBottom={18}
      minHeight={100}
    >
      <RowSpaceItem>
        <Label size={14}>{`transactionscreen.quychuongtrinh`}</Label>
        <Label size={14}>{`transactionscreen.ngaydatlenh`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <LabelBold multilanguage={false} size={14}>
          {productProgramName}
        </LabelBold>
        <LabelBold multilanguage={false} size={14}>
          {convertTimestamp(createAt)}
        </LabelBold>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={14}>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.quymuctieu`}</Label>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.chuongtrinh`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label multilanguage={false} size={14}>
          {productCode}
        </Label>
        <Label multilanguage={false} size={14}>
          {productProgramName}
        </Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={14}>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.giatriuoctinh`}</Label>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.trangthai`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label multilanguage={false} size={14}>
          {convertNumber(lockAmount)}
        </Label>
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Div
            widthHeight={10}
            marginRight={8}
            borderRadius={10}
            backgroundColor={Ecolors.yellowColor}
          />
          <Label multilanguage={false} size={14}>
            {statusName}
          </Label>
        </Div>
      </RowSpaceItem>
    </Button>
  );
}

export function ItemOrderTransferBuy(p: { data: any }) {
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const {
    productProgramName,
    volume,
    createAt,
    statusName,
    productCode,
    lockAmount,
    statusCode,
    sessionTime,
  } = p.data;

  return (
    <Button
      isScale={false}
      onPress={() => {
        // navigate('OrderTransferDetailsModal', {
        //   data: p.data,
        // });
      }}
      marginHorizontal={16}
      backgroundColor={Ecolors.whiteColor}
      borderRadius={8}
      borderWidth={0.8}
      borderColor={Ecolors.bordercolor}
      paddingHorizontal={15}
      paddingTop={13}
      paddingBottom={18}
      minHeight={100}
    >
      <RowSpaceItem>
        <Label size={14}>{`transactionscreen.quychuongtrinh`}</Label>
        <Label size={14}>{`transactionscreen.phiengiaodich`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label fontWeight="bold" multilanguage={false} size={14}>
          {productProgramName}
        </Label>
        <Label fontWeight="bold" multilanguage={false} size={14}>
          {convertTimestamp(sessionTime)}
        </Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={14}>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.quymuctieu`}</Label>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.chuongtrinh`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label multilanguage={false} size={14}>
          {productCode}
        </Label>
        <Label multilanguage={false} size={14}>
          {productProgramName}
        </Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={14}>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.giatriuoctinh`}</Label>
        <Label
          color={Ecolors.grayColor}
          size={14}
        >{`transactionscreen.trangthai`}</Label>
      </RowSpaceItem>
      <RowSpaceItem paddingTop={6}>
        <Label multilanguage={false} size={14}>
          {convertNumber(lockAmount)}
        </Label>
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Div
            widthHeight={10}
            marginRight={8}
            borderRadius={10}
            backgroundColor={
              statusCode == "ORDER_REJECT"
                ? Ecolors.redColor
                : Ecolors.yellowColor
            }
          />
          <Label multilanguage={false} size={14}>
            {I18nState == "vi"
              ? statusName
              : statusCode == "ORDER_REJECT"
              ? "Not matched"
              : statusCode == "ORDER_RECONCILED"
              ? `Matched`
              : "Pending"}
          </Label>
        </Div>
      </RowSpaceItem>
    </Button>
  );
}
