import { Button, ButtonBorder, Div, ImageView, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors, EStyle } from "constants/themes";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { goBack } from "services/navigation";
import BottomSheetDialog from "./BottomSheetDialog";
import { PostMSG } from "utils/utils";
import { stat } from "fs";

interface p {
  title: string;
  content: string;
  titleClose: string;
  type?: string | number;
  titleCancel: string;
  multilanguage?: boolean;
  is401?: boolean;
  titleConfirm: string;
  error: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
}

function AlertCp({}, ref: React.Ref<unknown> | undefined) {
  const [state, setState] = useState<p>({
    title: "",
    type: 1,
    content: "",
    error: false,
    titleClose: "",
    titleConfirm: "",
    titleCancel: "",
    multilanguage: false,
    is401: false,
    onCancel: () => {},
    onClose: () => {},
    onConfirm: () => {},
  });
  const sheetRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    show: (e: p) => {
      sheetRef.current.show();
      setState((a) => {
        return {
          ...a,
          ...e,
        };
      });
    },
    hide: () => {
      sheetRef.current.hide();
    },
  }));

  return (
    <BottomSheetDialog
      style={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      ref={sheetRef}
    >
      <Div
        width={337}
        borderRadius={5}
        style={EStyle.shadow}
        backgroundColor={Ecolors.whiteColor}
        minHeight={193}
      >
        {/* header */}
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          borderBottomWidth={1}
          borderBottomColor={Ecolors.spaceColor}
        >
          <Div heightWidth={46} />
          <Div
            flexDirection={"row"}
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Label fontWeight={"700"}>{state?.title || ""}</Label>
          </Div>
          <Button
            onPress={() => {
              state?.onClose && state?.onClose();
              if (state.is401) {
                PostMSG("RequestAuthenFailure", {});
              }
              sheetRef.current.hide();
            }}
            widthHeight={46}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <ImageView
              source={Icons.close}
              widthHeight={20}
              resizeMode={"contain"}
            />
          </Button>
        </Div>
        {/* content */}
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={28}
          marginBottom={39}
          paddingHorizontal={35}
        >
          <Label
            multilanguage={state?.multilanguage ?? true}
            textAlign={"center"}
          >
            {state?.content || ""}
          </Label>
        </Div>
        {state?.error ? (
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            paddingHorizontal={14}
            paddingBottom={13}
          >
            <ButtonBorder
              type={1}
              width={148}
              title={state?.titleClose}
              onPress={() => {
                state.onClose && state.onClose();
                if (state.is401) {
                  PostMSG("RequestAuthenFailure", {});
                }
                sheetRef.current.hide();
                // route?.params?.onCancel && route?.params?.onCancel();
              }}
            />
          </Div>
        ) : state?.type == 1 ? (
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            paddingHorizontal={14}
            paddingBottom={13}
          >
            <ButtonBorder
              type={2}
              width={148}
              title={state?.titleCancel}
              onPress={() => {
                sheetRef.current.hide();
                if (state.is401) {
                  PostMSG("RequestAuthenFailure", {});
                }
                state?.onCancel && state?.onCancel();
              }}
            />
            <ButtonBorder
              type={1}
              width={148}
              title={state?.titleConfirm}
              onPress={() => {
                sheetRef.current.hide();
                if (state.is401) {
                  PostMSG("RequestAuthenFailure", {});
                }
                state?.onConfirm && state?.onConfirm();
              }}
            />
          </Div>
        ) : (
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            paddingHorizontal={14}
            paddingBottom={13}
          >
            <ButtonBorder
              type={1}
              width={148}
              title={state?.titleClose}
              onPress={() => {
                sheetRef.current.hide();
                if (state.is401) {
                  PostMSG("RequestAuthenFailure", {});
                }
                state?.onConfirm && state?.onConfirm();
              }}
            />
          </Div>
        )}
      </Div>
    </BottomSheetDialog>
  );
}

export default React.forwardRef(AlertCp);
