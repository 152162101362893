import { combineReducers } from "@reduxjs/toolkit";
import authen from "./authen/reducer";
import asset from "./asset/reducer";
import languages from "./language/reducer";

export const rootReducer = combineReducers({
  authen,
  languages,
  asset,
});
export type RootState = ReturnType<typeof rootReducer>;

// export *
