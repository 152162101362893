import { Button, Div, Label } from "components";
import React, { useEffect, ReactDOM } from "react";
import { heightScreen, Log, widthScreen } from "utils";
import { Ecolors } from "constants/themes";
export default function RenderHtmlEKYC() {
  useEffect(() => {
    var btn: any = document.getElementById("startBtn");
    btn.onclick = function () {
      var event = new CustomEvent("start", {
        detail: {
          config: {
            domain: "https://api.fma.dev.techland.link/web",
            domainPath: "/ekyc/v1.2",
            authDomain: "https://sdk-stag.trueid.ai/auth",
            authDomainPath: "/v1/oauth",
            appId: "7c55d934f47f19371220aaa38b5d2a4c",
            appSecret: "dIuYznA6cfoDixqHolokUOq5f32gW0XXSsHTVWKBMvk=",
            lang: "vi",
            accessToken:
              "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJpc3NhY2VAZmluY29ycC5jb20iLCJhdWRpZW5jZSI6IndlYiIsImNyZWF0ZWQiOjE2MzgzNDc0MjU3MzQsInVzZXJ0eXBlIjoiSU5WRVNUT1IiLCJleHAiOjE2MzgzNzYyMjV9.BqM34QpiWUIkVheBD0RMYCioDWPOZzcDCbM_l83fgzC8zZshq61Ey2eWCUWgs8D9licxk_Tn4dmWS9zEhVLyvA",
          },
        },
      });
      document.dispatchEvent(event);
      Log("CustomEvent", event);
    };
    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("trueid_result", function (e) {
      Log("trueid_result", e);
    });
  }, []);

  return (
    <Div
      backgroundColor={Ecolors.whiteColor}
      style={{
        width: widthScreen,
        height: heightScreen,
      }}
    >
      <button type="button" id="startBtn" className="row">
        Start Demo
      </button>

      {/* {Platform.OS === "web" ? (
        <button type="button" id="startBtn">
          Start Demo
        </button>
      ) : (
        <Button
          onPress={() => {
            var event = new CustomEvent("start", {
              detail: {
                config: {
                  domain: "https://api.fma.dev.techland.link/web",
                  domainPath: "/ekyc/v1.2",
                  authDomain: "https://sdk-stag.trueid.ai/auth",
                  authDomainPath: "/v1/oauth",
                  appId: "7c55d934f47f19371220aaa38b5d2a4c",
                  appSecret: "dIuYznA6cfoDixqHolokUOq5f32gW0XXSsHTVWKBMvk=",
                  lang: "vi",
                  accessToken:
                    "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJpc3NhY2VAZmluY29ycC5jb20iLCJhdWRpZW5jZSI6IndlYiIsImNyZWF0ZWQiOjE2MzgzNDc0MjU3MzQsInVzZXJ0eXBlIjoiSU5WRVNUT1IiLCJleHAiOjE2MzgzNzYyMjV9.BqM34QpiWUIkVheBD0RMYCioDWPOZzcDCbM_l83fgzC8zZshq61Ey2eWCUWgs8D9licxk_Tn4dmWS9zEhVLyvA",
                },
              },
            });
            document.dispatchEvent(event);
          }}
          widthHeight={200}
          backgroundColor={Ecolors.redColor}
        />
      )} */}
      {/* <div id="trueid"></div> */}
      {/* <script id="trueid" src="../../react_trueid/index.html"></script> */}
      {/* <div dangerouslySetInnerHTML={template} /> */}
      {/* <script src="../../react_trueid/trueid.js"></script> */}
    </Div>
  );
}
