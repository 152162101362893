import { useRoute } from "@react-navigation/native";
import { ButtonBorder, Div, HeaderBack, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { apiInvestment } from "services/apiInvestment";
import { apiMain } from "services/apiMain";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { setStoreData } from "utils";
import { detectLinkData } from "utils/utils";

const Item = (props: { data: any; index: number }) => {
  const { data, index } = props;
  const isFirst = index == 0;
  const BgCol = isFirst ? "#30353d" : Ecolors.whiteColor;
  const TexCol = isFirst ? Ecolors.whiteColor : Ecolors.textColor;
  const fontWeight = isFirst ? "bold" : "500";
  if (data.id == 7.0) {
    return (
      <Div
        paddingVertical={10}
        paddingHorizontal={20}
        borderTopWidth={isFirst ? 0 : 0.8}
        borderTopColor={Ecolors.bordercolor}
      >
        <Label
          size={14}
          fontWeight={fontWeight}
          multilanguage={false}
          color={TexCol}
        >
          {data.col1}
        </Label>
      </Div>
    );
  }
  return (
    <Div
      flexDirection="row"
      borderTopWidth={isFirst ? 0 : 0.8}
      borderTopColor={Ecolors.bordercolor}
      backgroundColor={BgCol}
      alignItems="center"
      paddingVertical={10}
      paddingHorizontal={20}
      justifyContent="flex-start"
    >
      <Div flex={1}>
        <Label
          size={14}
          fontWeight={fontWeight}
          multilanguage={false}
          color={TexCol}
        >
          {data.col1}
        </Label>
      </Div>
      <Div width={100} alignItems="center" justifyContent="center">
        <Label
          textAlign="center"
          size={14}
          fontWeight={fontWeight}
          multilanguage={false}
          color={TexCol}
        >
          {data.col3}
        </Label>
      </Div>
      <Div width={100} alignItems="center" justifyContent="center">
        <Label
          size={14}
          textAlign="center"
          fontWeight={fontWeight}
          multilanguage={false}
          color={TexCol}
        >
          {data.col4}
        </Label>
      </Div>
    </Div>
  );
};

function PerformanceScreen() {
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState(0);
  const route = useRoute<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [productData, setProductData] = useState<any>({});
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  useEffect(() => {
    if (!!route.params.productId) {
      setProductId(route.params.productId);
    }
    bindDataUrl();
    return () => {};
  }, []);

  useEffect(() => {
    getData();
    return () => {};
  }, [productId]);

  const bindDataUrl = async () => {
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        setStoreData("productId", obj?.productId || "");
        setProductId(obj?.productId);
      })
      .catch((err: any) => {});
  };

  const getData = async () => {
    try {
      setLoading(true);
      const performanceInfo = await apiMain.getPerformanceInfo({ productId });
      const product = await apiInvestment.loadProductDetails({
        id: route.params.productId,
      });
      if (product.status == 200) {
        setProductData(product.data);
      }
      if (performanceInfo.status == 200) {
        setData(performanceInfo.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onInvestNow = async () => {
    const productData = await apiInvestment.loadProductDetails({
      id: route.params.productId,
    });
    navigate("CreateOrderModal", {
      initData: {
        product: productData.data,
      },
    });
  };

  return (
    <Div screen={true}>
      <HeaderBack
        type={route.params.flowApp == "AssetsScreen" ? 2 : 1}
        isShowStep={true}
        title={`performance.hieusuatquy`}
        // titleRight="investmentscreen.xemchitiet"
        // titleRightSize={14}
        // onRightPress={() => {
        //   linkToWeb(
        //     I18nState == "vi" ? productData.website : productData.websiteEn
        //   );
        // }}
      />
      <ScrollView showsVerticalScrollIndicator={false}>
        {data.map((item: any, index: number) => {
          return <Item index={index} key={index} data={item} />;
        })}
        {!loading && route.params.flowApp && (
          <Div
            width={"100%"}
            marginTop={12}
            alignItems="center"
            justifyContent="center"
          >
            <ButtonBorder
              title="performance.dautungay"
              onPress={() => {
                onInvestNow();
              }}
            />
          </Div>
        )}
      </ScrollView>
    </Div>
  );
}

export default React.memo(PerformanceScreen);
