import { Button, Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React from "react";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { convertShortName } from "utils/utils";

function ItemProduct(props: { data: any }) {
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const {
    name,
    id,
    code,
    color,
    tradingDayOfNavCurrently,
    valuationdate,
    navPercent,
    navCurrently,
    nameEn,
    hubMioPerformance,
    shortName,
  } = props.data;
  return (
    <Button
      isScale={false}
      onPress={() => {
        navigate("PerformanceScreen", {
          productId: id,
          flowApp: "AssetsScreen",
        });
        // dispatch(
        //   focusProduct({
        //     data,
        //   }),
        // );
        // navigate('InvestmentDetailsScreen');
      }}
      paddingVertical={16}
      paddingHorizontal={12}
      minHeight={100}
      marginHorizontal={12}
      borderRadius={8}
      borderColor={Ecolors.bordercolor}
      backgroundColor={Ecolors.whiteColor}
      borderWidth={0.8}
    >
      <Label fontWeight="bold" size={15} multilanguage={false}>
        {shortName}
      </Label>
      <Label size={14} multilanguage={false} color={Ecolors.grayText}>
        {I18nState == "vi" ? name : nameEn}
      </Label>
      <Div
        paddingTop={10}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Div>
          <Label size={12} multilanguage={false}>
            {I18nState == "vi"
              ? hubMioPerformance?.col5
              : hubMioPerformance?.col6}
          </Label>
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            {/*  <Label size={13}>{`investmentscreen.denngay`}</Label> */}
            <Label
              size={13}
              multilanguage={false}
              style={{ textTransform: "lowercase" }}
            >
              {I18nState == "vi"
                ? hubMioPerformance?.col1
                : hubMioPerformance?.col2}
            </Label>
          </Div>
        </Div>
        <Div flexDirection="row" alignItems="center">
          <Div marginRight={8}>
            <Label multilanguage={false} size={14} textAlign="right">
              {`${convertShortName(shortName) || code}`}
            </Label>

            <Label
              fontWeight="bold"
              multilanguage={false}
              textAlign="right"
              color={
                hubMioPerformance?.col3 && hubMioPerformance?.col3 < 0
                  ? Ecolors.redColor
                  : Ecolors.greenColor
              }
              size={14}
            >
              {Number(hubMioPerformance?.col3) >= 0 && "+"}
              {`${hubMioPerformance?.col3}`}{" "}
              {(Number(hubMioPerformance?.col3) >= 0 ||
                Number(hubMioPerformance?.col3) < 0) &&
                "%"}
            </Label>
          </Div>
          <Div height={33} width={1} backgroundColor={Ecolors.grayColor}></Div>
          <Div marginLeft={8}>
            <Label size={14} multilanguage={false}>
              {I18nState == "vi"
                ? hubMioPerformance?.col7
                : hubMioPerformance?.col8}
            </Label>
            <Label
              fontWeight="bold"
              multilanguage={false}
              textAlign="right"
              color={
                hubMioPerformance?.col4 && hubMioPerformance?.col4 > 0
                  ? Ecolors.greenColor
                  : Ecolors.redColor
              }
              size={14}
            >
              {Number(hubMioPerformance?.col4) >= 0 && "+"}
              {`${hubMioPerformance?.col4}`}{" "}
              {(Number(hubMioPerformance?.col4) >= 0 ||
                Number(hubMioPerformance?.col4) < 0) &&
                "%"}
            </Label>
          </Div>
        </Div>
      </Div>
    </Button>
  );
}

export default React.memo(ItemProduct);
