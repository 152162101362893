import { Alert, Toast } from "components";
import * as Clipboard from "expo-clipboard";
import * as ImagePicker from "expo-image-picker";
import I18n from "languages/i18n";
import moment from "moment";
import { Linking, Platform } from "react-native";
import { navigate } from "services/navigation";
const regEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9|#?!@$%^&*-]).{6,}$/;
const regPhone = /([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;

export const converRistInfoInDto = (t: any) => {
  const obj = {};
  Object.keys(t).map((item: any, index: number) => {
    obj[item] = t[item].id;
  });
  return obj;
};

export const PostMSG = (msg: string, data: Object) => {
  const D = JSON.stringify({
    msg,
    data,
  });

  if (window.AndroidInterface) {
    window.AndroidInterface.sendMessageToNative(D);
    return;
  }
  // if (window.Android) {
  //   window.Android.shareData(D);
  //   return;
  // }
  if (window.webkit) {
    window.webkit.messageHandlers.doStuffMessageHandler.postMessage(D);
    return;
  }
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(D);
    return;
  }
  if (window.top) {
    window.top.postMessage(D, "*");
    return;
  }
  if (window.parent) {
    window.parent.postMessage(D, "*");
    return;
  }
};

export function detectLinkData(t: any) {
  const stringuri = decodeURI(t);
  const num = stringuri?.indexOf("?");
  const ss = stringuri?.slice(num + 1, t.length);
  let obj = {};
  ss.split("&")?.map((item: any, index: number) => {
    const gg = item?.split("=");
    obj[gg[0]] = gg[1];
  });
  return obj;
}

export function convertReceiveAmount(
  status: boolean,
  I18nState: "en" | "vi" | string
) {
  if (status) {
    if (I18nState == "vi") {
      return `Đã nhận tiền`;
    }
    return `Received money confirm`;
  }
  if (I18nState == "vi") {
    return `Chờ xác nhận tiền`;
  }
  return `Waiting to confirm receiving money`;
}
export const convertNumber = (num: number | string, hideD?: boolean) => {
  if (!num) return `${num}${hideD ? "" : ` đ`}`;
  const strhead = parseInt(`${num}`.replace(/[,]/g, "")) >= 0 ? "" : "-";
  const ar = Math.abs(
    typeof num == "string" ? parseInt(num.replace(/[,]/g, "")) : num
  )
    .toString()
    .split(".");
  const str = [...ar[0]]
    .reverse()
    .map((item, index) => {
      if (index % 3 == 2 && index < ar[0].length - 1) {
        return `,${item}`;
      }
      return item;
    })
    .reverse()
    .join("");
  return `${strhead}${str}${ar[1] ? `.${ar[1]}` : ""}${!!hideD ? "" : " đ"}`;
};

export const convertAmount = (num: number | string, hideD?: boolean) => {
  if (!num) return `${num}${hideD ? "" : ` đ`}`;
  if (!parseInt(`${num}`)) {
    return "";
  }

  const strhead = parseInt(`${num}`.replace(/[,]/g, "")) >= 0 ? "" : "-";
  const ar = Math.abs(
    typeof num == "string" ? parseInt(num.replace(/[,]/g, "")) : num
  )
    .toString()
    .split(".");
  const isDot = `${num}`.indexOf(".");
  let last = "";
  if (isDot != -1) {
    last = `${num}`.slice(isDot + 1, `${num}`.length)?.slice(0, 2);
  } else {
    last = "";
  }
  const str = [...ar[0]]
    .reverse()
    .map((item, index) => {
      if (index % 3 == 2 && index < ar[0].length - 1) {
        return `,${item}`;
      }
      return item;
    })
    .reverse()
    .join("");
  return `${strhead}${str}${isDot != -1 ? `.${last ?? ""}` : ""}${
    !!hideD ? "" : " đ"
  }`;
};

export const convertNav = (num: number | string, hideD?: boolean) => {
  if (!num) return `${num}${hideD ? "" : " đ"}`;
  const strhead = parseInt(`${num}`.replace(/[,]/g, "")) >= 0 ? "" : "-";
  const ar = Math.abs(
    typeof num == "string" ? parseInt(num.replace(/[,]/g, "")) : num
  )
    .toString()
    .split(".");
  const last =
    ar[1]?.length == 2
      ? `.${ar[1]}`
      : ar[1]?.length == 1
      ? `.${ar[1]}0`
      : `.00`;
  const str = [...ar[0]]
    .reverse()
    .map((item, index) => {
      if (index % 3 == 2 && index < ar[0].length - 1) {
        return `,${item}`;
      }
      return item;
    })
    .reverse()
    .join("");
  return `${strhead}${str}${last}${!!hideD ? "" : " đ"}`;
};

export const convertPercent = (num: string | number | any) => {
  if (!`${num}`.length) {
    return "";
  }
  const per = Math.round(num * 100) / 100;
  const la = `${per}`?.split(".")?.[1] || "";
  const st = la?.length == 2 ? "" : la?.length == 1 ? "0" : ".00";
  return `${per}${st}%`;
};

export const isvalidEmail = (email: string) => {
  if (!email?.length) {
    return false;
  }
  return regEmail.test(email.toLocaleLowerCase());
};

export const isValidPassword = (password: string) => {
  return regPassword.test(password);
};

export const isvalidPhone = (phone: string) => {
  if (!phone?.length) {
    return false;
  }
  return regPhone.test(phone.toLocaleLowerCase());
};

export const parseMultilanguage = (text: string) => {
  return I18n.t(`${text}`);
};

export const convertShortName = (shortName: string) => {
  if (!shortName) {
    return "";
  }
  return shortName.replace("VINACAPITAL-", "");
};

export const checkToEsign = (
  currentUser: any,
  orderType: "BUY" | "SELL" | "TRANSFER" | "TRANSFER_ALL"
) => {
  const isFatca =
    !currentUser?.fatca?.fatca1 &&
    !currentUser?.fatca?.fatca2 &&
    !currentUser?.fatca?.fatca3;
  if (
    (orderType == "SELL" ||
      orderType == "TRANSFER_ALL" ||
      orderType == "TRANSFER") &&
    !currentUser?.investmentProfile?.isReceivedHardProfile
  ) {
    const content = `Tài khoản của quý khách hiện tại chưa được duyệt hoặc chưa nhận được hồ sơ gốc/chưa ký hợp đồng điện tử. Nên không thể thực hiện lệnh bán/chuyển đổi.`;
    if (
      currentUser?.investmentProfile?.status?.code !=
        `INVESTMENT_PROFILE_APPROVE` ||
      (currentUser?.investmentProfile?.status?.code ==
        "INVESTMENT_PROFILE_APPROVE" &&
        !currentUser?.investmentProfile?.isReceivedHardProfile)
    ) {
      if (
        currentUser?.investmentProfile?.status?.code ==
        "INVESTMENT_PROFILE_PENDING"
      ) {
        Alert.show({
          content: content,
          multilanguage: false,
          type: 2,
          titleClose: "alert.dong",
          onCancel: () => {},
          onConfirm: () => {},
        });
        return;
      }
      if (isFatca == true) {
        Alert.show({
          content: content,
          multilanguage: false,
          type: 2,
          titleClose: "alert.dongy",
          onCancel: () => {},
          onConfirm: () => {
            navigate("DigitalSignatureScreen");
          },
        });
      } else {
        Alert.showError({
          content: content,
          multilanguage: false,
        });
      }
      return;
    }
  }
};

export const checkRegisterValue = (p: {
  name: string;
  email: string;
  phone: string;
  province?: any;
}) => {
  if (
    !p.name.length ||
    !isvalidEmail(p.email) ||
    !isvalidPhone(p.phone) ||
    !p.province
  ) {
    return false;
  }
  return true;
};

export const checkPassword = (p: {
  oldPassword: string;
  newPassword: string;
  reNewPassword: string;
}) => {
  if (!p.oldPassword.length) {
    return false;
  }
  if (p.oldPassword == p.newPassword) {
    Alert.showError({
      content: `alert.matkhaucuphaikhacmatkhaumoi`,
    });
    return false;
  }
  if (p.newPassword !== p.reNewPassword) {
    return false;
  }

  if (!regPassword.test(p.newPassword)) {
    return false;
  }
  // regex check password;
  return true;
};
export const checkCreatePassword = (p: {
  password: string;
  confirmPassword: string;
}) => {
  if (!p.password || !p.confirmPassword) {
    return false;
  }
  if (p.password != p.confirmPassword) {
    return false;
  }
  if (!regPassword.test(p.password)) {
    Alert.showError({
      content: `setpasswordscreen.rule`,
    });
    return false;
  }
  return true;
};

export const checkEmail = (p: {
  oldEmail: string;
  newEmail: string;
  reNewEmail: string;
}) => {
  if (!p.oldEmail.length) {
    return false;
  }
  if (p.oldEmail == p.newEmail) {
    Alert.showError({
      content: `alert.emailcuphaikhacemailmoi`,
    });
    return false;
  }
  if (!isvalidEmail(p.newEmail)) {
    return false;
  }

  if (p.newEmail !== p.reNewEmail) {
    return false;
  }
  // regex check password;
  return true;
};

export const checkAddress = (p: {
  valueNation: any;
  valueProvince: any;
  valueDistrict: any;
  valueCommune: any;
  address: string;
}) => {
  if (p.address?.length == 0) {
    return false;
  }
  if (!p.valueNation?.id) {
    return false;
  }
  if (!p.valueProvince?.id) {
    return false;
  }
  if (!p.valueDistrict?.id) {
    return false;
  }
  if (!p.valueCommune?.id) {
    return false;
  }
  return true;
};

export const getNumdateCalenda = (p: {
  date?: number | string;
  month: number | string;
  year: number | string;
}) => {
  const gmonth =
    typeof p.month == "string" ? parseInt(p.month || "0") : p.month;
  const gyear = typeof p.year == "string" ? parseInt(p.year || "0") : p.year;
  if (gmonth == 2) {
    if ((gyear % 4 == 0 && gyear % 100 != 0) || gyear % 400 == 0) {
      return 29;
    }
    return 28;
  }
  if (gmonth == 4 || gmonth == 6 || gmonth == 9 || gmonth == 11) {
    return 30;
  }
  return 31;
};

export const parseArrayNum = (num: number, isYear?: boolean) => {
  const curryear = new Date().getFullYear();
  return Array(num)
    .fill(0)
    .map((item, index) => {
      const i = isYear ? curryear - num + index : index;
      return {
        id: `${i < 10 ? "0" : ""}${i + 1}`,
        name: `${i + 1}`,
        nameen: `${i + 1}`,
      };
    });
};

export const getUuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const parseToFormData = (p: any) => {
  if (typeof p == "object") {
    return Object.keys(p)?.map((item) => {
      if (item == "fileUpload") {
        return p[`${item}`];
      }
      return {
        name: item,
        data: typeof p[item] == "string" ? p[item] : `${p[item]}`,
      };
    });
  }
  return [];
};

export const getImageCamera = async () => {
  return new Promise((resolve, reject) => {
    return ImagePicker.launchCameraAsync({
      quality: 1,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
    })
      .then(async (res: any) => {
        if (res) {
          resolve([
            {
              ...res,
              base64: res.uri
                .replace("data:image/jpeg;base64,", "")
                .replace("data:image/png;base64,", ""),
            },
          ]);
          return;
        }
        reject();
      })
      .catch((err: any) => {
        reject();
        throw err;
      });
  });
};

export const getImageLibrary = async () => {
  return new Promise((resolve, reject) => {
    return ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
    })
      .then((res: any) => {
        if (res) {
          resolve([
            {
              ...res,
              base64: res.uri
                .replace("data:image/jpeg;base64,", "")
                .replace("data:image/png;base64,", ""),
            },
          ]);
          return;
        }
        reject();
      })
      .catch((err: any) => {
        reject();
        throw err;
      });
  });
};

export const requestPermisson = (permissions: any, callback: () => void) => {
  return;
};

export const joinObjectCalendar = (a: {
  date: number | string;
  month: string;
  year?: number;
  isPicker?: boolean;
}) => {
  if (a.isPicker) {
    const MM = `${parseInt(a.month) + 1}`;
    const DD = `${parseInt(a.date)}`;
    return `${a.year}${MM}${`${DD}`?.length >= 2 ? DD : `0${DD}`}`;
  }
  const MM = `${parseInt(a.month)}`;
  const DD = `${parseInt(a.date)}`;
  return `${a.year}${MM?.length >= 2 ? MM : `0${parseInt(MM)}`}${
    `${DD}`?.length >= 2 ? DD : `0${DD}`
  }`;
};
export const reJoinObjectCalendar = (a: string) => {
  const arr = a?.split("/");
  return {
    date: arr?.[0],
    month: arr?.[1],
    year: arr?.[2],
  };
};

export const convertTimestamp = (
  a: number | string,
  format?: string,
  localZone?: boolean
) => {
  if (!a) {
    return "";
  }
  if (localZone) {
    return `${moment(a).format(format || "DD/MM/yyyy")}`;
  }
  return `${moment(a)
    .utcOffset("+07:00")
    .format(format || "DD/MM/yyyy")}`;
};

export const removeUtf8 = (s: string) => {
  let str = `${s}`;
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
};

export const removeAllSpace = (s: string) => {
  return s.replace(/\s/g, "");
};

export const objectToArray = (a: Object) => {
  if (!a) {
    return [];
  }
  return Object.keys(a)?.map((item: any, index: number) => {
    return a[item] || null;
  });
};

export const timeoutFromNow = (
  timeOut: number,
  I18nState: "en" | "vi" | string
) => {
  let abc = timeOut;
  const h = Math.floor(abc / 3600);
  abc = abc % 3600;
  const m = Math.floor(abc / 60);
  abc = abc % 60;
  const s = abc;
  if (I18nState == "vi") {
    return `${h} giờ ${m} phút ${s} giây`;
  }
  return `${h} h ${m} m ${s} s`;
};

export const convertStringTime = (time: string) => {
  return time
    .split("/")
    .reverse()
    .map((item: any) => {
      return parseInt(item) < 10 && item.length < 2
        ? `0${parseInt(item)}`
        : item;
    })
    .join("");
};

export const getAddressRejectWard = (address: string) => {
  const a = removeUtf8(address?.toLocaleLowerCase());
  const index = a.indexOf("xa");
  const indexgg = a.indexOf("phuong");
  if (index != -1) {
    return address.slice(0, index).replace(/,/g, "");
  } else if (indexgg != -1) {
    return address.slice(0, indexgg).replace(/,/g, "");
  }
  return "";
};

export const callPhone = (phone: string) => {
  const phoneNumber =
    Platform.OS === "android" ? `tel:${phone}` : `telprompt:${phone}`;
  return Linking.openURL(phoneNumber);
};

export const linkToWeb = (t: string) => {
  Linking.canOpenURL(t)
    .then((e: any) => {
      Linking.openURL(t);
    })
    .catch((e: any) => {
      Toast.show({
        content: `alert.daxayraloi`,
        multilanguage: true,
      });
    });
};

export const copyToClipboard = (text: string) => {
  Clipboard.setString(text);
  Toast.show({
    content: `Đã sao chép thông tin nội dung vào bộ nhớ đệm`,
    multilanguage: false,
  });
};

export const checkLogin = (p: { name: string; pass: string }) => {
  if (p.name.length == 0) {
    Alert.showError({
      content: `alert.vuilongnhaptendangnhap`,
    });
    return false;
  }
  if (p.pass.length == 0) {
    Alert.showError({
      content: `alert.vuilongnhapmatkhau`,
    });
    return false;
  }
  return true;
};

// export function getMaxMinNAV(t: Array<any>) {
//   let max = null;
//   let min = null;
//   t.map((item: any, index: number) => {
//     if (!max) {
//       max = item.y;
//     } else if (item.y >= max) {
//       max = item.y;
//     }
//     if (!min) {
//       min = item.y;
//     } else if (item.y <= min) {
//       min = item.y;
//     }
//   });
//   return {
//     max: max || 10000,
//     min: min || 0,
//     space: (max || 10000) / 3,
//   };
// }

export function Log(t?: string | object, r?: any) {
  if (__DEV__) {
    if (typeof t == "string") {
      return console.log(t, r || "");
    } else if (typeof t == "object") {
      return console.log(t);
    } else {
      return console.log(t || "", r || "");
    }
  }
  return;
}

export function convertStringFeeSell(p: {
  beginValue?: number;
  endValue?: number;
  endOperatorCode?: string;
  I18nState?: "vi" | "en";
}) {
  let content = "";
  if (p.beginValue == 0 && p.endOperatorCode == `&`) {
    content = `${p.I18nState == "vi" ? "Phí bán" : "Fee sell"}`;
  } else if (p.beginValue == 0) {
    content = `${p.I18nState == "vi" ? "Dưới" : "Under"} ${p.endValue} ${
      p.I18nState == "vi" ? "ngày" : "days"
    }`;
  } else if (p.beginValue == 730) {
    content = `${p.I18nState == "vi" ? "Trên" : "Above"} ${p.beginValue} ${
      p.I18nState == "vi" ? "ngày" : "days"
    }`;
  } else {
    content = `${p.beginValue} - ${p.endValue} ${
      p.I18nState == "vi" ? "ngày" : "days"
    }`;
  }
  return content;
}

export function hidePhoneNumberOTP(t?: string) {
  if (!t?.length) {
    return "";
  }
  return [...t]
    .map((item: any, index: number) => {
      if (index <= 3 || index >= t.length - 2) {
        return item;
      }
      return "*";
    })
    .join("");
}

export function convertProductCode(p: {
  code?: string;
  I18nState?: "vi" | "en";
}) {
  let content = "";
  if (p.code === "VFF") {
    content = `${
      p.I18nState == "vi" ? "Quỹ Trái phiếu" : "Fixed Income Fund"
    } `;
  } else if (p.code === "VEOF") {
    content = `${p.I18nState == "vi" ? "Quỹ Cổ phiếu" : "Equity Fund"} `;
  } else if (p.code === "VESAF") {
    content = `${p.I18nState == "vi" ? "Quỹ Cổ phiếu" : "Equity Fund"} `;
  } else if (p.code === "VIBF") {
    content = `${p.I18nState == "vi" ? "Quỹ Cân bằng" : "Balanced Fund"} `;
  } else if (p.code === "VLBF") {
    content = `${
      p.I18nState == "vi" ? "Quỹ Thị trường Tiền tệ" : "Money Market Fund"
    } `;
  } else {
    content = `${p.I18nState == "vi" ? "dfdf" : "fdfd"} `;
  }
  return content;
}
export function checkToSetdata(e: string, func: (r: string) => void) {
  if (e.length == 0) {
    func(e);
    return;
  }
  const t: string = e[e.length ? e.length - 1 : 0] || "";
  const reg = /^[0-9.]*$/;
  if (reg.test(t)) {
    func(e);
  }
}

export function doc1so(so) {
  var arr_chuhangdonvi = [
    "không",
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];
  var resualt = "";
  resualt = arr_chuhangdonvi[so];
  return resualt;
}
export function doc2so(so) {
  so = so.replace(" ", "");
  var arr_chubinhthuong = [
    "không",
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];
  var arr_chuhangdonvi = [
    "mươi",
    "mốt",
    "hai",
    "ba",
    "bốn",
    "lăm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];
  var arr_chuhangchuc = [
    "",
    "mười",
    "hai mươi",
    "ba mươi",
    "bốn mươi",
    "năm mươi",
    "sáu mươi",
    "bảy mươi",
    "tám mươi",
    "chín mươi",
  ];
  var resualt = "";
  var sohangchuc = so.substr(0, 1);
  var sohangdonvi = so.substr(1, 1);
  resualt += arr_chuhangchuc[sohangchuc];
  if (sohangchuc == 1 && sohangdonvi == 1)
    resualt += " " + arr_chubinhthuong[sohangdonvi];
  else if (sohangchuc == 1 && sohangdonvi > 1)
    resualt += " " + arr_chuhangdonvi[sohangdonvi];
  else if (sohangchuc > 1 && sohangdonvi > 0)
    resualt += " " + arr_chuhangdonvi[sohangdonvi];

  return resualt;
}
export function doc3so(so) {
  var resualt = "";
  var arr_chubinhthuong = [
    "không",
    "một",
    "hai",
    "ba",
    "bốn",
    "năm",
    "sáu",
    "bảy",
    "tám",
    "chín",
  ];
  var sohangtram = so.substr(0, 1);
  var sohangchuc = so.substr(1, 1);
  var sohangdonvi = so.substr(2, 1);
  resualt = arr_chubinhthuong[sohangtram] + " trăm";
  if (sohangchuc == 0 && sohangdonvi != 0)
    resualt += " linh " + arr_chubinhthuong[sohangdonvi];
  else if (sohangchuc != 0)
    resualt += " " + doc2so(sohangchuc + " " + sohangdonvi);
  return resualt;
}

export function docsonguyen(so) {
  var result = "";
  if (so != undefined) {
    //alert(so);
    var arr_So = [{ ty: "" }, { trieu: "" }, { nghin: "" }, { tram: "" }];
    var sochuso = so.length;
    for (var i = sochuso - 1; i >= 0; i--) {
      if (sochuso - i <= 3) {
        if (arr_So["tram"] != undefined)
          arr_So["tram"] = so.substr(i, 1) + arr_So["tram"];
        else arr_So["tram"] = so.substr(i, 1);
      } else if (sochuso - i > 3 && sochuso - i <= 6) {
        if (arr_So["nghin"] != undefined)
          arr_So["nghin"] = so.substr(i, 1) + arr_So["nghin"];
        else arr_So["nghin"] = so.substr(i, 1);
      } else if (sochuso - i > 6 && sochuso - i <= 9) {
        if (arr_So["trieu"] != undefined)
          arr_So["trieu"] = so.substr(i, 1) + arr_So["trieu"];
        else arr_So["trieu"] = so.substr(i, 1);
      } else {
        if (arr_So.ty != undefined) arr_So.ty = so.substr(i, 1) + arr_So.ty;
        else arr_So.ty = so.substr(i, 1);
      }
      //console.log(arr_So);
    }

    if (arr_So["ty"] > 0) result += doc(arr_So["ty"]) + " tỷ";
    if (arr_So["trieu"] > 0) {
      if (arr_So["trieu"].length >= 3 || arr_So["ty"] > 0)
        result += " " + doc3so(arr_So["trieu"]) + " triệu";
      else if (arr_So["trieu"].length >= 2)
        result += " " + doc2so(arr_So["trieu"]) + " triệu";
      else result += " " + doc1so(arr_So["trieu"]) + " triệu";
    }
    if (arr_So["nghin"] > 0) {
      if (arr_So["nghin"].length >= 3 || arr_So["trieu"] > 0)
        result += " " + doc3so(arr_So["nghin"]) + " nghìn";
      else if (arr_So["nghin"].length >= 2)
        result += " " + doc2so(arr_So["nghin"]) + " nghìn";
      else result += " " + doc1so(arr_So["nghin"]) + " nghìn";
    }
    if (arr_So["tram"] > 0) {
      if (arr_So["tram"].length >= 3 || arr_So["nghin"] > 0)
        result += " " + doc3so(arr_So["tram"]);
      else if (arr_So["tram"].length >= 2)
        result += " " + doc2so(arr_So["tram"]);
      else result += " " + doc1so(arr_So["tram"]);
    }
  }
  return result;
}

export function doc(so) {
  var kytuthapphan = ",";
  var result = "";
  if (so != undefined) {
    so = " " + so + " ";
    so = so.trim();
    var cautrucso = so.split(kytuthapphan);
    if (cautrucso[0] != undefined) {
      result += docsonguyen(cautrucso[0]);
    }
    if (cautrucso[1] != undefined) {
      //alert(this.docsonguyen(cautrucso[1]));
      result += " phẩy " + docsonguyen(cautrucso[1]);
    }
  }

  return result;
}
