import { Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React from "react";
import { convertPercent } from "utils/utils";

const size = 340;
function ProcessHeader(p: { index: number; max: number }) {
  const { index, max } = p;
  const w = Math.round(((index + 1) / max) * size);
  const isFull = index + 1 === max;
  const percent = Math.round(((index + 1) * 100) / max);
  return (
    <Div padding={19} paddingBottom={0}>
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Label multilanguage={false} color={Ecolors.redColor}>{`Câu hỏi ${
          index + 1
        }/${max}`}</Label>
        <Label
          multilanguage={false}
          color={isFull ? Ecolors.greenColor : Ecolors.textColor}
        >
          {convertPercent(percent)}
        </Label>
      </Div>

      <Div
        width={size}
        marginTop={10}
        height={5}
        backgroundColor={Ecolors.bordercolor}
        borderRadius={5}
        flexDirection={"row"}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Div
          height={5}
          width={w}
          backgroundColor={isFull ? Ecolors.greenColor : Ecolors.textColor}
          borderRadius={5}
        />
      </Div>
    </Div>
  );
}

export default React.memo(ProcessHeader);
