import { Div, HeaderBack, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { FlatList } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { apiMain } from "services/apiMain";

const Item = (p: { data: any; index }) => {
  const { data, index } = p;
  return (
    <Div paddingHorizontal={20}>
      <Label fontWeight="400" size={14} multilanguage={false}>{`${index + 1}. ${
        data.content
      }`}</Label>
    </Div>
  );
};

function RiskContentModal() {
  const [data, setData] = useState<any>({});
  const insets = useSafeAreaInsets();
  useEffect(() => {
    getData();
    return () => {};
  }, []);

  const getData = async () => {
    try {
      const res = await apiMain.getAcknowledgment();
      if (res.status == 200) {
        setData(res.data);
      }
    } catch (error) {
    } finally {
    }
  };

  const renderItem = (p: { item: any; index: number }) => {
    return <Item data={p.item} index={p.index} />;
  };
  const keyExtractor = (item: any, index: number) => `key${index}`;

  const ItemSeparatorComponent = () => {
    return (
      <Div
        height={1}
        backgroundColor={Ecolors.grayText}
        marginHorizontal={20}
        marginVertical={10}
      />
    );
  };
  const ListFooterComponent = () => {
    return <Div height={insets.bottom + 200} />;
  };
  const ListHeaderComponent = () => {
    return (
      <>
        <Div paddingHorizontal={20} paddingVertical={10}>
          <Label fontWeight="600" size={14} multilanguage={false}>{`${
            data?.titleContent || ""
          }`}</Label>
        </Div>
        <Div
          height={1}
          backgroundColor={Ecolors.grayText}
          marginVertical={10}
        />
      </>
    );
  };

  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack
        multilanguage={false}
        isShowStep={false}
        type={4}
        title={data?.title || ""}
      />
      <FlatList
        data={data?.items}
        extraData={data?.items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={ListHeaderComponent}
        ItemSeparatorComponent={ItemSeparatorComponent}
        ListFooterComponent={ListFooterComponent}
        showsVerticalScrollIndicator={false}
      />
    </Div>
  );
}

export default React.memo(RiskContentModal);
