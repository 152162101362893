import { Alert, Div } from "components";
import React, { useEffect, useRef, useState } from "react";
import ListTransaction from "./ListTransaction";
import { apiTransaction } from "services/apiTransaction";

const AutoInvestTransaction = () => {
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);

  const pageLoadData = useRef<any>(1);

  useEffect(() => {
    loadData({});
    return () => {};
  }, []);

  const loadData = async (p: { page?: 1 }) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      pageLoadData.current = p.page;
      const res = await apiTransaction.getAutoInvest();
      if (res.status == 200) {
        setData(res.data);
        setTotalItemCount(res.data.length);
        return;
      }
      Alert.showError({
        content: res.message || "",
        multilanguage: false,
      });
    } catch (error: any) {
      Alert.showError({
        content: error.message || "",
        multilanguage: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div flex={1}>
      <ListTransaction
        itemRender={"AutoInvestTransaction"}
        totalItemCount={totalItemCount}
        onRefresh={() => {
          loadData({ page: 1 });
        }}
        onLoadMore={() => {
          loadData({ page: pageLoadData.current + 1 });
        }}
        data={data}
        refreshing={loading}
      />
    </Div>
  );
};

export default React.memo(AutoInvestTransaction);
