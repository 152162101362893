import { createAsyncThunk } from "@reduxjs/toolkit";
import { changeStatusScreen } from "reducer/authen";
import { apiAuth } from "services/api";
import { apiMain } from "services/apiMain";
import { setAccount } from "utils/storage";
interface IParamsLogin {
  username: string;
  password: string;
}

export const doLogin = createAsyncThunk(
  `authen@/doLogin`,
  async (params: IParamsLogin, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiAuth.login(params);
      if (res) {
        dispatch(changeStatusScreen("main"));
        dispatch(getInfo({}));
        setAccount({
          username: params.username,
          password: params.password,
        });
        return res;
      }
      return rejectWithValue(res);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
export const getInfo = createAsyncThunk(
  `authen@/getInfo`,
  async (params: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiAuth.getInfo();
      if (res.status == 200) {
        return res.data;
      }
      return rejectWithValue(res);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
export const getQuestion = createAsyncThunk(
  `authen@/getQuestion`,
  async (params: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiMain.getQuestion();
      if (res.status == 200) {
        return res.data;
      }
      return rejectWithValue(res);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
export const submitQuestion = createAsyncThunk(
  `authen@/submitQuestion`,
  async (
    params: {
      appetiteId: null | number;
      questionResults: Array<any>;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await apiMain.submitQuestion(params);
      if (res.status == 200) {
        return res.data;
      }
      return rejectWithValue(res);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
export const getResult = createAsyncThunk(
  `authen@/getResult`,
  async (params: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiMain.getResult();
      if (res.status == 200) {
        return res.data;
      }
      return rejectWithValue(res);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
