import { Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { heightScale, widthScale, widthScreen } from "utils";
import { VictoryPie } from "victory";
const size = 122;

const I = (p: { data: any }) => {
  const { data } = p;
  const w = Math.round((data.percent / 100) * size);
  return (
    <Div
      borderBottomWidth={0.5}
      borderColor={Ecolors.grayColor}
      paddingVertical={7}
      flexDirection="row"
      alignItems="center"
    >
      <Div widthHeight={13} backgroundColor={data.color} borderRadius={13} />
      <Div
        width={150}
        justifyContent="center"
        paddingLeft={10}
        alignItems="flex-start"
      >
        <Label size={14} multilanguage={false}>
          {data?.fundName || ""}
        </Label>
        <Label
          size={12}
          fontWeight="400"
          color={Ecolors.grayColor}
          multilanguage={false}
        >
          {data?.fundCategory || ""}
        </Label>
      </Div>
      <Div
        width={size}
        height={5}
        backgroundColor={Ecolors.grayText}
        borderRadius={5}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Div
          width={w}
          height={5}
          borderRadius={5}
          backgroundColor={data.color}
        />
      </Div>
      <Label marginLeft={5} multilanguage={false} size={14}>
        {`${data.percent}%`}
      </Label>
    </Div>
  );
};

function PieChartComm() {
  const suggestionFunds = useAppSelector(
    (state) => state.authen.result?.appetites?.suggestionFunds
  );
  const appetites = useAppSelector((state) => state.authen.result?.appetites);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    bindData(suggestionFunds);
    return () => {};
  }, [suggestionFunds]);

  const bindData = (t: any) => {
    setData(
      t?.map((item: any, index: number) => {
        return {
          ...item,
          y: item.percent,
          x: item.fundName,
        };
      }) || []
    );
  };

  return (
    <Div
      alignItems="center"
      justifyContent="center"
      style={{
        width: widthScreen,
      }}
    >
      <Div
        alignItems="center"
        justifyContent="center"
        style={{
          width: widthScreen,
        }}
      >
        <Div alignItems="center" justifyContent="center">
          <VictoryPie
            labels={({ datum }) => ``}
            // labels={({ datum }) => `${datum.fundName}`}
            colorScale={data?.map((item, index) => item.color)}
            height={heightScale(200)}
            width={heightScale(200)}
            innerRadius={heightScale(65)}
            padding={{
              top: widthScale(20),
              bottom: widthScale(10),
            }}
            data={data}
          />
          {!!appetites?.totalPoints && (
            <Div
              position="absolute"
              zIndex={999}
              elevation={999}
              alignItems="center"
              justifyContent="center"
            >
              <Label size={15}>{`riskquestionscreen.tongsodiem`}</Label>
              <Label
                size={20}
                multilanguage={false}
                color={Ecolors.redColor}
                marginTop={5}
              >
                {appetites?.totalPoints || 0}
              </Label>
            </Div>
          )}
        </Div>
      </Div>
      {suggestionFunds?.map((item: any, index: number) => {
        return <I data={item} key={index} />;
      })}
      <Div height={200} />
    </Div>
  );
}

export default React.memo(PieChartComm);
