import { Button, Div, ImageView, Label, LoadingIndicator } from "components";
import { urlApp } from "constants/AppUrl";
import { Icons } from "constants/Icons";
import { stringApp } from "constants/stringApp";
import { EStyle, Ecolors } from "constants/themes";
import React, { useState } from "react";
import { useAppSelector } from "store/hooks";
import { widthScale, widthScreen } from "utils";
import { getStoreData } from "utils/storage";
import { PostMSG } from "utils/utils";

function CardSignature(p: {
  isAccept: boolean;
  setIsAccept: (t: boolean) => void;
}) {
  const currentUser = useAppSelector<any>((state) => state.authen.currentUser);
  const hardProfile = !!currentUser.investmentProfile?.isReceivedHardProfile;
  const { name, investmentProfile } = currentUser;
  const [loading, setLoading] = useState<boolean>(false);
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const { isAccept, setIsAccept } = p;

  const switchStatusEsign = (p: {
    hardProfile: boolean;
    I18nState: "vi" | "en";
  }) => {
    if (p.hardProfile) {
      if (p.I18nState == "vi") {
        return "Đã ký";
      }
      return "Full submission";
    }
    if (p.I18nState == "vi") {
      return "Chưa ký";
    }
    return "No submission";
  };
  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      "data:application/pdf;base64,".length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const downloadConfirm = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const url = `${urlApp.APIURL}api/esignature/download-contract`;
      var headers = {};

      const refCode = await getStoreData("refCode");
      const mToken = await getStoreData("mToken");
      const idCode = await getStoreData("idCode");
      // const idNo = await getStoreData("idNo");
      // const phone = await getStoreData("phone");
      headers = {
        // idNo: idNo,
        // phone: phone,
        refCode: refCode,
        idCode: idCode,
        Authorization: `Bearer ${mToken || "token"}`,
      };
      const data = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      data.blob().then((t) => {
        const reader = new FileReader();
        let dataurl = window.URL.createObjectURL(t);
        let a = document.createElement("a");
        a.href = dataurl;
        a.download = "Form-mo-tai-khoan";
        reader.readAsDataURL(t);
        reader.onloadend = () => {
          const base64data: any = reader.result;
          PostMSG(
            "DownloadFileContract",
            `${base64data.replace("data:application/pdf;base64,", "") || ""}`
          );
        };
        a.click();
      });
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* {!loading && (
        <Div
          flex={1}
          width={'100%'}
          height={'100%'}
          screen={true}
          backgroundColor={Ecolors.transparentLoading}
          alignItems={'center'}
          justifyContent={'center'}
          position={'absolute'}
          zIndex={9999999}
          elevation={999999}>
          <LoadingIndicator color={Ecolors.mainColor} />
        </Div>
      )} */}
      <Div
        width={343}
        marginVertical={15}
        paddingVertical={16}
        borderRadius={8}
        borderWidth={0.8}
        borderColor={Ecolors.bordercolor}
        flexDirection={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        paddingLeft={20}
        paddingRight={17}
        backgroundColor={Ecolors.whiteColor}
        style={EStyle.shadowItem}
      >
        <Div
          widthHeight={40}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={5}
          overflow={"hidden"}
          borderWidth={0.8}
          borderColor={Ecolors.bordercolor}
        >
          <ImageView
            source={Icons.iconapp}
            // widthHeight={30}
            style={{
              width: widthScale(40),
              height: widthScale(40),
            }}
            resizeMode={"cover"}
          />
        </Div>
        <Div
          flex={1}
          paddingHorizontal={10}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Label
            size={16}
            color={Ecolors.mainColor}
            fontWeight={"700"}
            multilanguage={false}
          >
            {stringApp.companyName}
          </Label>
          <Label
            marginTop={2}
            size={14}
            color={Ecolors.grayColor}
            multilanguage={false}
          >
            {investmentProfile?.number || ""}
          </Label>
        </Div>

        <Div
          flexDirection={"row"}
          paddingTop={12}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Label
            size={14}
            color={hardProfile ? Ecolors.growColor : Ecolors.redColor}
            multilanguage={false}
          >
            {switchStatusEsign({ hardProfile, I18nState: "vi" })}
          </Label>
          <ImageView
            marginLeft={10}
            widthHeight={17}
            resizeMode={"contain"}
            source={hardProfile ? Icons.check : Icons.uncheck}
          />
        </Div>
      </Div>
      <Div
        alignItems={"center"}
        paddingHorizontal={20}
        justifyContent={"center"}
      >
        <Label textAlign={"center"} size={14}>
          {hardProfile
            ? `digitalsignature.contentdownload2`
            : `digitalsignature.contentdownload`}
        </Label>
      </Div>
      <Div
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingBottom={20}
      >
        <Button
          width={340}
          height={48}
          flexDirection={"row"}
          marginTop={18}
          onPress={() => {
            downloadConfirm();
          }}
          borderRadius={5}
          borderWidth={0.8}
          borderColor={Ecolors.mainColor}
          backgroundColor={Ecolors.spaceColor}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {loading ? (
            <LoadingIndicator color={Ecolors.mainColor} />
          ) : (
            <>
              <ImageView
                source={Icons.download}
                widthHeight={18}
                resizeMode={"contain"}
                marginRight={10}
              />
              <Label fontWeight={"700"} size={15}>
                {hardProfile
                  ? `digitalsignature.taihopdongdaky`
                  : `digitalsignature.taihopdongmotaikhoan`}
              </Label>
            </>
          )}
        </Button>
      </Div>
      <Div
        paddingHorizontal={20}
        style={{
          width: widthScreen,
        }}
      >
        <Button
          onPress={() => {
            setIsAccept(!isAccept);
          }}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <ImageView
            source={isAccept ? Icons.checkPro : Icons.unCheckPro}
            widthHeight={20}
            resizeMode={"contain"}
          />
          {/* {isAccept && (
              <Div
                widthHeight={22}
                borderRadius={5}
                backgroundColor={Ecolors.mainColor}
              />
            )} */}
          <Label multilanguage={false} marginLeft={10}>{`Tôi đồng ý`}</Label>
        </Button>
        <Label marginTop={10} size={14} multilanguage={false}>
          {`Bằng việc nhấp vào ô Tôi đồng ý này, nhà đầu tư theo đây xác nhận như sau:`}
        </Label>
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          marginTop={5}
        >
          <ImageView
            widthHeight={15}
            source={Icons.warningamount}
            resizeMode={"contain"}
          />
          <Label marginLeft={10} size={14} multilanguage={false}>
            {`Đồng ý với tài khoản được mở theo hợp đồng mở tài khoản.`}
          </Label>
        </Div>
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          marginTop={5}
        >
          <ImageView
            widthHeight={15}
            source={Icons.warningamount}
            resizeMode={"contain"}
          />
          <Label marginLeft={10} size={14} multilanguage={false}>
            {`Chấp thuận và đồng ý tuân thủ toàn bộ điều khoản và điều kiện của hợp đồng mở tài khoản (đã được ký bằng e-sign).`}
          </Label>
        </Div>
      </Div>

      <Div
        alignItems={"center"}
        paddingHorizontal={20}
        justifyContent={"center"}
      >
        {!investmentProfile?.isReceivedHardProfile && I18nState == "en" && (
          <Label
            size={14}
            fontWeight={"700"}
            textAlign={"center"}
            multilanguage={false}
          >
            {I18nState == "en"
              ? `If you're US-CITIZEN, please send FATCA document to our company`
              : ""}
          </Label>
        )}
      </Div>
    </>
  );
}

export default React.memo(CardSignature);
