import { useRoute } from "@react-navigation/core";
import { Div, HeaderBack, TimeLine } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { Linking } from "react-native";
import { useAppSelector } from "store/hooks";
import { Log, setStoreData } from "utils";
import { checkToEsign, detectLinkData } from "utils/utils";
import OrderBuy from "./OrderBuy";
import OrderSell from "./OrderSell";
import OrderTransfer from "./OrderTransfer";

const switchTitleOrder = (t: "BUY" | "SELL" | "TRANSFER" | "TRANSFER_BUY") => {
  switch (t) {
    case "BUY":
      return `createordermodal.datlenhmua`;
    case "SELL":
      return `createordermodal.datlenhban`;
    case "TRANSFER":
      return `createordermodal.datlenhchuyendoi`;
    case "TRANSFER_BUY":
      return `createordermodal.datlenhchuyendoi`;
    default:
      return `createordermodal.datlenhmua`;
  }
};

function CreateOrderModal() {
  const route = useRoute<any>();
  const initData: any = route.params?.initData;
  const [stepTimeLine, setStepTimeLine] = useState<number>(1);
  const [orderType, setOrderType] = useState<"BUY" | "SELL" | "TRANSFER">(
    "BUY"
  );
  const currentUser = useAppSelector((state) => state.authen.currentUser);
  useEffect(() => {
    bindDataUrl();
    return () => {};
  }, []);

  useEffect(() => {
    if (!!currentUser?.fatca && orderType != "BUY") {
      checkToEsign(currentUser, orderType);
    }
  }, [orderType]);

  const bindDataUrl = async () => {
    if (!!route.params.orderType) {
      setOrderType(route.params.orderType.toLocaleUpperCase());
      return;
    }
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        Log("detectLinkData", obj);
        setOrderType(obj?.orderType?.toLocaleUpperCase() || "BUY");
        if (obj?.orderType?.toLocaleUpperCase()?.length) {
          setStoreData("orderType", obj?.orderType?.toLocaleUpperCase());
        }
        // setStoreData("idNo", obj?.idNo || "");
        // setStoreData("phone", obj?.phone || "");
        // setStoreData("refCode", obj?.refCode || "");
        return;
      })
      .catch((err: any) => {});
  };

  const swithTypeOrder = (
    type: "BUY" | "SELL" | "TRANSFER" | "TRANSFER_BUY" | "TRANSFER_ALL"
  ) => {
    switch (type) {
      case "BUY":
        return (
          <OrderBuy
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      case "SELL":
        return (
          <OrderSell
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      case "TRANSFER":
        return (
          <OrderTransfer
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      case "TRANSFER_BUY":
        return (
          <OrderTransfer
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      default:
        return (
          <OrderBuy
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
    }
  };

  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={2} title={switchTitleOrder(orderType)} />
      <TimeLine index={stepTimeLine} />
      <Div flex={1}>{swithTypeOrder(orderType)}</Div>
    </Div>
  );
}

export default React.memo(CreateOrderModal);
