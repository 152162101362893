// import RNDateTimePicker from '@react-native-community/datetimepicker';
import { Button, Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { Linking } from "react-native";
import {
  convertTimestamp,
  detectLinkData,
  joinObjectCalendar,
} from "utils/utils";

const currentDate = convertTimestamp(new Date().toString()).split("/");
const D = parseInt(currentDate[0]);
const M = parseInt(currentDate[1]);
const Y = parseInt(currentDate[2]);
const ArrayTime = [
  {
    title: "investmentscreen.nam",
    cur: 1,
    date: joinObjectCalendar({
      date: D,
      month: M,
      year: Y - 1,
    }),
    vi: `YTD`,
    en: `YTD`,
  },
  {
    title: "investmentscreen.nam",
    cur: 3,
    date: joinObjectCalendar({
      date: D,
      month: M,
      year: Y - 3,
    }),
    vi: `1 năm`,
    en: `1 year`,
  },
  {
    title: "investmentscreen.nam",
    cur: 5,
    date: joinObjectCalendar({
      date: D,
      month: M,
      year: Y - 5,
    }),
    vi: `3 năm`,
    en: `3 years`,
  },
  {
    title: "investmentscreen.tatca",
    cur: 0,
    date: joinObjectCalendar({
      date: D,
      month: M,
      year: Y - 10,
    }),
    isAllData: true,
    vi: `5 năm`,
    en: `5 years`,
  },
];

function RowTime(props: { onChange: (t: any) => void }) {
  const [curForcus, setCurForcus] = useState(ArrayTime[3]);
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    bindDataUrl();
    return () => {};
  }, []);

  const bindDataUrl = async () => {
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        setProductId(obj?.productId);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    if (!!productId) {
      const toDate = joinObjectCalendar({
        date: D,
        month: M,
        year: Y,
      });
      props.onChange(
        curForcus.isAllData
          ? { productId }
          : {
              fromDate: curForcus.date || toDate,
              toDate: toDate,
              productId,
            }
      );
    }
    return () => {};
  }, [curForcus, productId]);

  const changeFocusTime = (i: any) => {
    setCurForcus(i);
  };

  return (
    <>
      <Div width={"100%"} justifyContent={"center"} alignItems={"center"}>
        <Div
          width={351}
          height={40}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          borderRadius={40}
          backgroundColor={Ecolors.disableColor}
        >
          {ArrayTime.map((item: any, index: number) => {
            const isFocus = curForcus?.cur == item?.cur;
            return (
              <Button
                key={index}
                onPress={() => {
                  changeFocusTime(item);
                }}
                width={84}
                flexDirection={"row"}
                height={34}
                borderRadius={40}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={
                  isFocus ? Ecolors.mainColor : Ecolors.transparent
                }
              >
                <Label
                  multilanguage={false}
                  size={14}
                  color={isFocus ? Ecolors.whiteColor : Ecolors.textColor}
                >
                  {`${item.cur || ""} `}
                </Label>
                <Label
                  size={14}
                  color={isFocus ? Ecolors.whiteColor : Ecolors.textColor}
                >
                  {item.title}
                </Label>
              </Button>
            );
          })}
        </Div>
      </Div>
    </>
  );
}

export default React.memo(RowTime);
