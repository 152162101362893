/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { RootStackParamList } from "utils";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Register: "/Register",
      AssetScreen: "/Asset",
      ProductScreen: "/Product",
      CreateOrderModal: "/CreateOrder",
      ResultModal: "/R",
      EditRiskQuestionModal: "/ED",
      RiskQuestionScreen: "/RiskQuestion",
      PerformanceScreen: "/Performance",
      Chart: "/Chart",
      SetPasswordScreen: "/A",
      ReviewInfoModal: "/X",
      ControlEKYCScreen: "/EKYC",
      EditBankInfoModal: "/C",
      OtpRequestModal: "/D",
      LoginScreen: "/Login",
      DigitalSignatureScreen: "/Esign",
      PendingOrderScreen: "/Order",
    },
  },
};

export default linking;
