import { useRoute } from "@react-navigation/native";
import { ButtonBorder, Div, HeaderBack, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import { changeResult, getResult } from "reducer/authen";
import { goBack, navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import ComReSubmit from "./ComReSubmit";
import PieChartComm from "./PieChartComm";

function ResultModal() {
  const dispatch = useDispatch();
  const appetites = useAppSelector((state) => state.authen.result?.appetites);
  const insets = useSafeAreaInsets();
  const route = useRoute<any>();
  const flow = route.params?.flow;

  useEffect(() => {
    if (flow == "Submit") {
      dispatch(changeResult(route.params?.data || {}));
    } else {
      dispatch(getResult({}));
    }
    return () => {};
  }, []);

  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack
        onGoBack={() => {
          if (flow != "Submit") {
            return;
          }
          goBack();
          return;
        }}
        type={flow == "Submit" ? 2 : 1}
        title={`riskquestionscreen.bangkhaosatkhauviruiro`}
      />
      <ScrollView showsVerticalScrollIndicator={false}>
        <Div padding={21}>
          <Div width={"100%"} alignItems="center" justifyContent="center">
            {appetites?.totalPoints < 12 ? (
              <>
                <Div
                  width={181}
                  height={41}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={40}
                  borderWidth={1}
                  borderColor={Ecolors.grayText}
                >
                  <Label
                    size={14}
                    marginLeft={15}
                  >{`riskquestionscreen.tongsodiem`}</Label>
                  <Div
                    width={52}
                    borderTopRightRadius={40}
                    borderBottomRightRadius={40}
                    height={"100%"}
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor={Ecolors.redColor}
                  >
                    <Label
                      multilanguage={false}
                      color={Ecolors.whiteColor}
                      fontWeight="500"
                    >
                      {appetites?.totalPoints || 0}
                    </Label>
                  </Div>
                </Div>
                <Label
                  marginTop={10}
                  size={14}
                >{`riskquestionscreen.luuy`}</Label>
              </>
            ) : (
              <>
                <Label>{`riskquestionscreen.nhomkhauviruiro`}</Label>
                <Label size={20} fontWeight="600" multilanguage={false}>
                  {appetites?.appetiteName || ""}
                </Label>
                <Div
                  width={"100%"}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Label
                    marginTop={25}
                    color={Ecolors.redColor}
                  >{`riskquestionscreen.tuvanvaoquymovinacapital`}</Label>
                </Div>

                {/* <div
                  style={{
                    fontFamily: Efonts.medium,
                    fontSize: fontScale(16),
                  }}
                  dangerouslySetInnerHTML={{ __html: appetites?.contentHtml }}
                /> */}
                <Label size={15} marginTop={3} multilanguage={false}>
                  {appetites?.content || ""}
                </Label>
                <PieChartComm />
              </>
            )}
          </Div>
        </Div>
      </ScrollView>
      {flow == "Submit" ? (
        <ComReSubmit />
      ) : (
        <>
          {!!appetites?.totalPoints && (
            <Div
              flexDirection={"row"}
              alignItems={"center"}
              marginTop={10}
              paddingHorizontal={29}
              justifyContent={"space-between"}
              width={"100%"}
              marginBottom={insets.bottom + 20}
            >
              <ButtonBorder
                onPress={() => {
                  navigate("HistoryRiskQuestionModal");
                }}
                height={48}
                title={`riskquestionscreen.xembangkhaosatruiro`}
                type={2}
              />
            </Div>
          )}
        </>
      )}
    </Div>
  );
}

export default React.memo(ResultModal);
