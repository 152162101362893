import { Button, Div, Label } from "components";
import { Ecolors, Efonts } from "constants/themes";
import React from "react";
import { fontScale } from "utils";

function Item(p: {
  title?: string;
  content: string;
  isSelect: boolean;
  onPress: (t: any) => void;
  type?: 1 | 2;
}) {
  const { title, content, isSelect, onPress, type } = p;
  if (type == 1) {
    return (
      <Div
        width={"100%"}
        borderRadius={8}
        borderWidth={1}
        borderColor={isSelect ? Ecolors.greenColor : Ecolors.grayText}
        padding={20}
      >
        <Button
          isScale={false}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          onPress={() => {
            onPress && onPress(isSelect);
          }}
        >
          <Div
            widthHeight={20}
            borderRadius={40}
            borderWidth={1}
            borderColor={Ecolors.grayText}
            alignItems="center"
            justifyContent="center"
          >
            {isSelect && (
              <Div
                marginRight={1}
                marginTop={1}
                widthHeight={12}
                borderRadius={20}
                backgroundColor={Ecolors.textColor}
              />
            )}
          </Div>
          <Div paddingLeft={13} flex={1}>
            <Label size={16} multilanguage={false}>
              {title}
            </Label>
          </Div>
        </Button>

        <Div>
          <div
            style={{
              fontFamily: Efonts.medium,
              fontSize: fontScale(16),
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Div>
      </Div>
    );
  }
  return (
    <Div
      width={"100%"}
      borderRadius={8}
      borderWidth={1}
      borderColor={isSelect ? Ecolors.greenColor : Ecolors.grayText}
      padding={20}
    >
      <Button
        isScale={false}
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        onPress={() => {
          onPress && onPress(isSelect);
        }}
      >
        <Div
          widthHeight={20}
          borderRadius={40}
          marginTop={2}
          borderWidth={1}
          borderColor={Ecolors.grayText}
          alignItems="center"
          justifyContent="center"
        >
          {isSelect && (
            <Div
              marginRight={1}
              marginTop={1}
              widthHeight={12}
              borderRadius={20}
              backgroundColor={Ecolors.textColor}
            />
          )}
        </Div>
        <Div paddingLeft={13} flex={1}>
          <Label size={14} multilanguage={false}>
            {content}
          </Label>
        </Div>
      </Button>
    </Div>
  );
}

function ComQuestion(p: {
  data: any;
  type?: 1 | 2;
  isSelect: boolean;
  onPress: (t: any) => void;
}) {
  const { data, isSelect, type, onPress } = p;
  return (
    <Item
      title={data.appetiteName || data.title || ""}
      content={data.contentHtml || data.content || ""}
      isSelect={isSelect}
      onPress={(t: any) => {
        onPress && onPress(t);
      }}
      type={type}
    />
  );
}

export default React.memo(ComQuestion);
