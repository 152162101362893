import { Button, Div, HeaderBack, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useState } from "react";
import { widthScreen } from "utils";
import AutoInvestTransaction from "./AutoInvestTransaction";
import HistoryTransaction from "./HistoryTransaction";
import OrderTransaction from "./OrderTransaction";

const Row = (p: {
  title: string;
  pressKey: string;
  onPress: (t: string) => void;
  isFocus: boolean;
}) => {
  return (
    <Button
      onPress={() => p.onPress(p.pressKey)}
      style={{
        width: widthScreen / 3,
      }}
      borderBottomWidth={2}
      borderBottomColor={p.isFocus ? Ecolors.mainColor : Ecolors.grayColor}
      height={48}
      alignItems="center"
      justifyContent="center"
    >
      <Label
        textAlign="center"
        fontWeight="bold"
        color={p.isFocus ? Ecolors.mainColor : Ecolors.grayColor}
        size={14}
      >
        {p.title}
      </Label>
    </Button>
  );
};

const renderTabFocus = (p: { tabFocus: string }) => {
  switch (p.tabFocus) {
    case "OrderTransaction":
      return <OrderTransaction />;
    case "HistoryTransaction":
      return <HistoryTransaction />;
    case "AutoInvestTransaction":
      return <AutoInvestTransaction />;
    default:
      return <Div />;
  }
};

const PendingOrderScreen = () => {
  const [tabFocus, setTabFocus] = useState<string>("OrderTransaction");
  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={2} title={"transactionscreen.lenhchoxuly"} />
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Row
          title="transactionscreen.lenhchoxuly"
          pressKey="OrderTransaction"
          isFocus={tabFocus == "OrderTransaction"}
          onPress={(t: string) => setTabFocus(t)}
        />
        <Row
          isFocus={tabFocus == "HistoryTransaction"}
          title="transactionscreen.lichsugiaodich"
          pressKey="HistoryTransaction"
          onPress={(t: string) => setTabFocus(t)}
        />
        <Row
          isFocus={tabFocus == "AutoInvestTransaction"}
          title="transactionscreen.dinhky"
          pressKey="AutoInvestTransaction"
          onPress={(t: string) => setTabFocus(t)}
        />
      </Div>

      {renderTabFocus({ tabFocus })}
    </Div>
  );
};

export default React.memo(PendingOrderScreen);
