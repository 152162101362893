/*! For license information please see jsencrypt.min.js.LICENSE.txt */
!(function (t, e) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = e())
    : "function" == typeof define && define.amd
    ? define([], e)
    : "object" == typeof exports
    ? (exports.JSEncrypt = e())
    : (t.JSEncrypt = e());
})(window, () =>
  (() => {
    var t = {
        155: (t) => {
          var e,
            i,
            r = (t.exports = {});
          function n() {
            throw new Error("setTimeout has not been defined");
          }
          function s() {
            throw new Error("clearTimeout has not been defined");
          }
          function o(t) {
            if (e === setTimeout) return setTimeout(t, 0);
            if ((e === n || !e) && setTimeout)
              return (e = setTimeout), setTimeout(t, 0);
            try {
              return e(t, 0);
            } catch (i) {
              try {
                return e.call(null, t, 0);
              } catch (i) {
                return e.call(this, t, 0);
              }
            }
          }
          !(function () {
            try {
              e = "function" == typeof setTimeout ? setTimeout : n;
            } catch (t) {
              e = n;
            }
            try {
              i = "function" == typeof clearTimeout ? clearTimeout : s;
            } catch (t) {
              i = s;
            }
          })();
          var h,
            a = [],
            u = !1,
            c = -1;
          function f() {
            u &&
              h &&
              ((u = !1),
              h.length ? (a = h.concat(a)) : (c = -1),
              a.length && l());
          }
          function l() {
            if (!u) {
              var t = o(f);
              u = !0;
              for (var e = a.length; e; ) {
                for (h = a, a = []; ++c < e; ) h && h[c].run();
                (c = -1), (e = a.length);
              }
              (h = null),
                (u = !1),
                (function (t) {
                  if (i === clearTimeout) return clearTimeout(t);
                  if ((i === s || !i) && clearTimeout)
                    return (i = clearTimeout), clearTimeout(t);
                  try {
                    i(t);
                  } catch (e) {
                    try {
                      return i.call(null, t);
                    } catch (e) {
                      return i.call(this, t);
                    }
                  }
                })(t);
            }
          }
          function p(t, e) {
            (this.fun = t), (this.array = e);
          }
          function g() {}
          (r.nextTick = function (t) {
            var e = new Array(arguments.length - 1);
            if (arguments.length > 1)
              for (var i = 1; i < arguments.length; i++)
                e[i - 1] = arguments[i];
            a.push(new p(t, e)), 1 !== a.length || u || o(l);
          }),
            (p.prototype.run = function () {
              this.fun.apply(null, this.array);
            }),
            (r.title = "browser"),
            (r.browser = !0),
            (r.env = {}),
            (r.argv = []),
            (r.version = ""),
            (r.versions = {}),
            (r.on = g),
            (r.addListener = g),
            (r.once = g),
            (r.off = g),
            (r.removeListener = g),
            (r.removeAllListeners = g),
            (r.emit = g),
            (r.prependListener = g),
            (r.prependOnceListener = g),
            (r.listeners = function (t) {
              return [];
            }),
            (r.binding = function (t) {
              throw new Error("process.binding is not supported");
            }),
            (r.cwd = function () {
              return "/";
            }),
            (r.chdir = function (t) {
              throw new Error("process.chdir is not supported");
            }),
            (r.umask = function () {
              return 0;
            });
        },
      },
      e = {};
    function i(r) {
      var n = e[r];
      if (void 0 !== n) return n.exports;
      var s = (e[r] = { exports: {} });
      return t[r](s, s.exports, i), s.exports;
    }
    (i.d = (t, e) => {
      for (var r in e)
        i.o(e, r) &&
          !i.o(t, r) &&
          Object.defineProperty(t, r, { enumerable: !0, get: e[r] });
    }),
      (i.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e));
    var r = {};
    return (
      (() => {
        "use strict";
        function t(t) {
          return "0123456789abcdefghijklmnopqrstuvwxyz".charAt(t);
        }
        function e(t, e) {
          return t & e;
        }
        function n(t, e) {
          return t | e;
        }
        function s(t, e) {
          return t ^ e;
        }
        function o(t, e) {
          return t & ~e;
        }
        function h(t) {
          if (0 == t) return -1;
          var e = 0;
          return (
            0 == (65535 & t) && ((t >>= 16), (e += 16)),
            0 == (255 & t) && ((t >>= 8), (e += 8)),
            0 == (15 & t) && ((t >>= 4), (e += 4)),
            0 == (3 & t) && ((t >>= 2), (e += 2)),
            0 == (1 & t) && ++e,
            e
          );
        }
        function a(t) {
          for (var e = 0; 0 != t; ) (t &= t - 1), ++e;
          return e;
        }
        i.d(r, { default: () => ot });
        var u,
          c =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        function f(t) {
          var e,
            i,
            r = "";
          for (e = 0; e + 3 <= t.length; e += 3)
            (i = parseInt(t.substring(e, e + 3), 16)),
              (r += c.charAt(i >> 6) + c.charAt(63 & i));
          for (
            e + 1 == t.length
              ? ((i = parseInt(t.substring(e, e + 1), 16)),
                (r += c.charAt(i << 2)))
              : e + 2 == t.length &&
                ((i = parseInt(t.substring(e, e + 2), 16)),
                (r += c.charAt(i >> 2) + c.charAt((3 & i) << 4)));
            (3 & r.length) > 0;

          )
            r += "=";
          return r;
        }
        function l(e) {
          var i,
            r = "",
            n = 0,
            s = 0;
          for (i = 0; i < e.length && "=" != e.charAt(i); ++i) {
            var o = c.indexOf(e.charAt(i));
            o < 0 ||
              (0 == n
                ? ((r += t(o >> 2)), (s = 3 & o), (n = 1))
                : 1 == n
                ? ((r += t((s << 2) | (o >> 4))), (s = 15 & o), (n = 2))
                : 2 == n
                ? ((r += t(s)), (r += t(o >> 2)), (s = 3 & o), (n = 3))
                : ((r += t((s << 2) | (o >> 4))), (r += t(15 & o)), (n = 0)));
          }
          return 1 == n && (r += t(s << 2)), r;
        }
        var p,
          g = {
            decode: function (t) {
              var e;
              if (void 0 === p) {
                var i = "= \f\n\r\t \u2028\u2029";
                for (p = Object.create(null), e = 0; e < 64; ++e)
                  p[
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(
                      e
                    )
                  ] = e;
                for (p["-"] = 62, p._ = 63, e = 0; e < i.length; ++e)
                  p[i.charAt(e)] = -1;
              }
              var r = [],
                n = 0,
                s = 0;
              for (e = 0; e < t.length; ++e) {
                var o = t.charAt(e);
                if ("=" == o) break;
                if (-1 != (o = p[o])) {
                  if (void 0 === o)
                    throw new Error("Illegal character at offset " + e);
                  (n |= o),
                    ++s >= 4
                      ? ((r[r.length] = n >> 16),
                        (r[r.length] = (n >> 8) & 255),
                        (r[r.length] = 255 & n),
                        (n = 0),
                        (s = 0))
                      : (n <<= 6);
                }
              }
              switch (s) {
                case 1:
                  throw new Error(
                    "Base64 encoding incomplete: at least 2 bits missing"
                  );
                case 2:
                  r[r.length] = n >> 10;
                  break;
                case 3:
                  (r[r.length] = n >> 16), (r[r.length] = (n >> 8) & 255);
              }
              return r;
            },
            re: /-----BEGIN [^-]+-----([A-Za-z0-9+\/=\s]+)-----END [^-]+-----|begin-base64[^\n]+\n([A-Za-z0-9+\/=\s]+)====/,
            unarmor: function (t) {
              var e = g.re.exec(t);
              if (e)
                if (e[1]) t = e[1];
                else {
                  if (!e[2]) throw new Error("RegExp out of sync");
                  t = e[2];
                }
              return g.decode(t);
            },
          },
          d = 1e13,
          v = (function () {
            function t(t) {
              this.buf = [+t || 0];
            }
            return (
              (t.prototype.mulAdd = function (t, e) {
                var i,
                  r,
                  n = this.buf,
                  s = n.length;
                for (i = 0; i < s; ++i)
                  (r = n[i] * t + e) < d
                    ? (e = 0)
                    : (r -= (e = 0 | (r / d)) * d),
                    (n[i] = r);
                e > 0 && (n[i] = e);
              }),
              (t.prototype.sub = function (t) {
                var e,
                  i,
                  r = this.buf,
                  n = r.length;
                for (e = 0; e < n; ++e)
                  (i = r[e] - t) < 0 ? ((i += d), (t = 1)) : (t = 0),
                    (r[e] = i);
                for (; 0 === r[r.length - 1]; ) r.pop();
              }),
              (t.prototype.toString = function (t) {
                if (10 != (t || 10))
                  throw new Error("only base 10 is supported");
                for (
                  var e = this.buf,
                    i = e[e.length - 1].toString(),
                    r = e.length - 2;
                  r >= 0;
                  --r
                )
                  i += (d + e[r]).toString().substring(1);
                return i;
              }),
              (t.prototype.valueOf = function () {
                for (var t = this.buf, e = 0, i = t.length - 1; i >= 0; --i)
                  e = e * d + t[i];
                return e;
              }),
              (t.prototype.simplify = function () {
                var t = this.buf;
                return 1 == t.length ? t[0] : this;
              }),
              t
            );
          })(),
          m =
            /^(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([01]\d|2[0-3])(?:([0-5]\d)(?:([0-5]\d)(?:[.,](\d{1,3}))?)?)?(Z|[-+](?:[0]\d|1[0-2])([0-5]\d)?)?$/,
          y =
            /^(\d\d\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([01]\d|2[0-3])(?:([0-5]\d)(?:([0-5]\d)(?:[.,](\d{1,3}))?)?)?(Z|[-+](?:[0]\d|1[0-2])([0-5]\d)?)?$/;
        function b(t, e) {
          return t.length > e && (t = t.substring(0, e) + "…"), t;
        }
        var T,
          S = (function () {
            function t(e, i) {
              (this.hexDigits = "0123456789ABCDEF"),
                e instanceof t
                  ? ((this.enc = e.enc), (this.pos = e.pos))
                  : ((this.enc = e), (this.pos = i));
            }
            return (
              (t.prototype.get = function (t) {
                if ((void 0 === t && (t = this.pos++), t >= this.enc.length))
                  throw new Error(
                    "Requesting byte offset "
                      .concat(t, " on a stream of length ")
                      .concat(this.enc.length)
                  );
                return "string" == typeof this.enc
                  ? this.enc.charCodeAt(t)
                  : this.enc[t];
              }),
              (t.prototype.hexByte = function (t) {
                return (
                  this.hexDigits.charAt((t >> 4) & 15) +
                  this.hexDigits.charAt(15 & t)
                );
              }),
              (t.prototype.hexDump = function (t, e, i) {
                for (var r = "", n = t; n < e; ++n)
                  if (((r += this.hexByte(this.get(n))), !0 !== i))
                    switch (15 & n) {
                      case 7:
                        r += "  ";
                        break;
                      case 15:
                        r += "\n";
                        break;
                      default:
                        r += " ";
                    }
                return r;
              }),
              (t.prototype.isASCII = function (t, e) {
                for (var i = t; i < e; ++i) {
                  var r = this.get(i);
                  if (r < 32 || r > 176) return !1;
                }
                return !0;
              }),
              (t.prototype.parseStringISO = function (t, e) {
                for (var i = "", r = t; r < e; ++r)
                  i += String.fromCharCode(this.get(r));
                return i;
              }),
              (t.prototype.parseStringUTF = function (t, e) {
                for (var i = "", r = t; r < e; ) {
                  var n = this.get(r++);
                  i +=
                    n < 128
                      ? String.fromCharCode(n)
                      : n > 191 && n < 224
                      ? String.fromCharCode(
                          ((31 & n) << 6) | (63 & this.get(r++))
                        )
                      : String.fromCharCode(
                          ((15 & n) << 12) |
                            ((63 & this.get(r++)) << 6) |
                            (63 & this.get(r++))
                        );
                }
                return i;
              }),
              (t.prototype.parseStringBMP = function (t, e) {
                for (var i, r, n = "", s = t; s < e; )
                  (i = this.get(s++)),
                    (r = this.get(s++)),
                    (n += String.fromCharCode((i << 8) | r));
                return n;
              }),
              (t.prototype.parseTime = function (t, e, i) {
                var r = this.parseStringISO(t, e),
                  n = (i ? m : y).exec(r);
                return n
                  ? (i && ((n[1] = +n[1]), (n[1] += +n[1] < 70 ? 2e3 : 1900)),
                    (r = n[1] + "-" + n[2] + "-" + n[3] + " " + n[4]),
                    n[5] &&
                      ((r += ":" + n[5]),
                      n[6] && ((r += ":" + n[6]), n[7] && (r += "." + n[7]))),
                    n[8] &&
                      ((r += " UTC"),
                      "Z" != n[8] && ((r += n[8]), n[9] && (r += ":" + n[9]))),
                    r)
                  : "Unrecognized time: " + r;
              }),
              (t.prototype.parseInteger = function (t, e) {
                for (
                  var i, r = this.get(t), n = r > 127, s = n ? 255 : 0, o = "";
                  r == s && ++t < e;

                )
                  r = this.get(t);
                if (0 == (i = e - t)) return n ? -1 : 0;
                if (i > 4) {
                  for (o = r, i <<= 3; 0 == (128 & (+o ^ s)); )
                    (o = +o << 1), --i;
                  o = "(" + i + " bit)\n";
                }
                n && (r -= 256);
                for (var h = new v(r), a = t + 1; a < e; ++a)
                  h.mulAdd(256, this.get(a));
                return o + h.toString();
              }),
              (t.prototype.parseBitString = function (t, e, i) {
                for (
                  var r = this.get(t),
                    n = "(" + (((e - t - 1) << 3) - r) + " bit)\n",
                    s = "",
                    o = t + 1;
                  o < e;
                  ++o
                ) {
                  for (
                    var h = this.get(o), a = o == e - 1 ? r : 0, u = 7;
                    u >= a;
                    --u
                  )
                    s += (h >> u) & 1 ? "1" : "0";
                  if (s.length > i) return n + b(s, i);
                }
                return n + s;
              }),
              (t.prototype.parseOctetString = function (t, e, i) {
                if (this.isASCII(t, e)) return b(this.parseStringISO(t, e), i);
                var r = e - t,
                  n = "(" + r + " byte)\n";
                r > (i /= 2) && (e = t + i);
                for (var s = t; s < e; ++s) n += this.hexByte(this.get(s));
                return r > i && (n += "…"), n;
              }),
              (t.prototype.parseOID = function (t, e, i) {
                for (var r = "", n = new v(), s = 0, o = t; o < e; ++o) {
                  var h = this.get(o);
                  if ((n.mulAdd(128, 127 & h), (s += 7), !(128 & h))) {
                    if ("" === r)
                      if ((n = n.simplify()) instanceof v)
                        n.sub(80), (r = "2." + n.toString());
                      else {
                        var a = n < 80 ? (n < 40 ? 0 : 1) : 2;
                        r = a + "." + (n - 40 * a);
                      }
                    else r += "." + n.toString();
                    if (r.length > i) return b(r, i);
                    (n = new v()), (s = 0);
                  }
                }
                return s > 0 && (r += ".incomplete"), r;
              }),
              t
            );
          })(),
          E = (function () {
            function t(t, e, i, r, n) {
              if (!(r instanceof w)) throw new Error("Invalid tag value.");
              (this.stream = t),
                (this.header = e),
                (this.length = i),
                (this.tag = r),
                (this.sub = n);
            }
            return (
              (t.prototype.typeName = function () {
                switch (this.tag.tagClass) {
                  case 0:
                    switch (this.tag.tagNumber) {
                      case 0:
                        return "EOC";
                      case 1:
                        return "BOOLEAN";
                      case 2:
                        return "INTEGER";
                      case 3:
                        return "BIT_STRING";
                      case 4:
                        return "OCTET_STRING";
                      case 5:
                        return "NULL";
                      case 6:
                        return "OBJECT_IDENTIFIER";
                      case 7:
                        return "ObjectDescriptor";
                      case 8:
                        return "EXTERNAL";
                      case 9:
                        return "REAL";
                      case 10:
                        return "ENUMERATED";
                      case 11:
                        return "EMBEDDED_PDV";
                      case 12:
                        return "UTF8String";
                      case 16:
                        return "SEQUENCE";
                      case 17:
                        return "SET";
                      case 18:
                        return "NumericString";
                      case 19:
                        return "PrintableString";
                      case 20:
                        return "TeletexString";
                      case 21:
                        return "VideotexString";
                      case 22:
                        return "IA5String";
                      case 23:
                        return "UTCTime";
                      case 24:
                        return "GeneralizedTime";
                      case 25:
                        return "GraphicString";
                      case 26:
                        return "VisibleString";
                      case 27:
                        return "GeneralString";
                      case 28:
                        return "UniversalString";
                      case 30:
                        return "BMPString";
                    }
                    return "Universal_" + this.tag.tagNumber.toString();
                  case 1:
                    return "Application_" + this.tag.tagNumber.toString();
                  case 2:
                    return "[" + this.tag.tagNumber.toString() + "]";
                  case 3:
                    return "Private_" + this.tag.tagNumber.toString();
                }
              }),
              (t.prototype.content = function (t) {
                if (void 0 === this.tag) return null;
                void 0 === t && (t = 1 / 0);
                var e = this.posContent(),
                  i = Math.abs(this.length);
                if (!this.tag.isUniversal())
                  return null !== this.sub
                    ? "(" + this.sub.length + " elem)"
                    : this.stream.parseOctetString(e, e + i, t);
                switch (this.tag.tagNumber) {
                  case 1:
                    return 0 === this.stream.get(e) ? "false" : "true";
                  case 2:
                    return this.stream.parseInteger(e, e + i);
                  case 3:
                    return this.sub
                      ? "(" + this.sub.length + " elem)"
                      : this.stream.parseBitString(e, e + i, t);
                  case 4:
                    return this.sub
                      ? "(" + this.sub.length + " elem)"
                      : this.stream.parseOctetString(e, e + i, t);
                  case 6:
                    return this.stream.parseOID(e, e + i, t);
                  case 16:
                  case 17:
                    return null !== this.sub
                      ? "(" + this.sub.length + " elem)"
                      : "(no elem)";
                  case 12:
                    return b(this.stream.parseStringUTF(e, e + i), t);
                  case 18:
                  case 19:
                  case 20:
                  case 21:
                  case 22:
                  case 26:
                    return b(this.stream.parseStringISO(e, e + i), t);
                  case 30:
                    return b(this.stream.parseStringBMP(e, e + i), t);
                  case 23:
                  case 24:
                    return this.stream.parseTime(
                      e,
                      e + i,
                      23 == this.tag.tagNumber
                    );
                }
                return null;
              }),
              (t.prototype.toString = function () {
                return (
                  this.typeName() +
                  "@" +
                  this.stream.pos +
                  "[header:" +
                  this.header +
                  ",length:" +
                  this.length +
                  ",sub:" +
                  (null === this.sub ? "null" : this.sub.length) +
                  "]"
                );
              }),
              (t.prototype.toPrettyString = function (t) {
                void 0 === t && (t = "");
                var e = t + this.typeName() + " @" + this.stream.pos;
                if (
                  (this.length >= 0 && (e += "+"),
                  (e += this.length),
                  this.tag.tagConstructed
                    ? (e += " (constructed)")
                    : !this.tag.isUniversal() ||
                      (3 != this.tag.tagNumber && 4 != this.tag.tagNumber) ||
                      null === this.sub ||
                      (e += " (encapsulates)"),
                  (e += "\n"),
                  null !== this.sub)
                ) {
                  t += "  ";
                  for (var i = 0, r = this.sub.length; i < r; ++i)
                    e += this.sub[i].toPrettyString(t);
                }
                return e;
              }),
              (t.prototype.posStart = function () {
                return this.stream.pos;
              }),
              (t.prototype.posContent = function () {
                return this.stream.pos + this.header;
              }),
              (t.prototype.posEnd = function () {
                return this.stream.pos + this.header + Math.abs(this.length);
              }),
              (t.prototype.toHexString = function () {
                return this.stream.hexDump(this.posStart(), this.posEnd(), !0);
              }),
              (t.decodeLength = function (t) {
                var e = t.get(),
                  i = 127 & e;
                if (i == e) return i;
                if (i > 6)
                  throw new Error(
                    "Length over 48 bits not supported at position " +
                      (t.pos - 1)
                  );
                if (0 === i) return null;
                e = 0;
                for (var r = 0; r < i; ++r) e = 256 * e + t.get();
                return e;
              }),
              (t.prototype.getHexStringValue = function () {
                var t = this.toHexString(),
                  e = 2 * this.header,
                  i = 2 * this.length;
                return t.substr(e, i);
              }),
              (t.decode = function (e) {
                var i;
                i = e instanceof S ? e : new S(e, 0);
                var r = new S(i),
                  n = new w(i),
                  s = t.decodeLength(i),
                  o = i.pos,
                  h = o - r.pos,
                  a = null,
                  u = function () {
                    var e = [];
                    if (null !== s) {
                      for (var r = o + s; i.pos < r; )
                        e[e.length] = t.decode(i);
                      if (i.pos != r)
                        throw new Error(
                          "Content size is not correct for container starting at offset " +
                            o
                        );
                    } else
                      try {
                        for (;;) {
                          var n = t.decode(i);
                          if (n.tag.isEOC()) break;
                          e[e.length] = n;
                        }
                        s = o - i.pos;
                      } catch (t) {
                        throw new Error(
                          "Exception while decoding undefined length content: " +
                            t
                        );
                      }
                    return e;
                  };
                if (n.tagConstructed) a = u();
                else if (
                  n.isUniversal() &&
                  (3 == n.tagNumber || 4 == n.tagNumber)
                )
                  try {
                    if (3 == n.tagNumber && 0 != i.get())
                      throw new Error(
                        "BIT STRINGs with unused bits cannot encapsulate."
                      );
                    a = u();
                    for (var c = 0; c < a.length; ++c)
                      if (a[c].tag.isEOC())
                        throw new Error(
                          "EOC is not supposed to be actual content."
                        );
                  } catch (t) {
                    a = null;
                  }
                if (null === a) {
                  if (null === s)
                    throw new Error(
                      "We can't skip over an invalid tag with undefined length at offset " +
                        o
                    );
                  i.pos = o + Math.abs(s);
                }
                return new t(r, h, s, n, a);
              }),
              t
            );
          })(),
          w = (function () {
            function t(t) {
              var e = t.get();
              if (
                ((this.tagClass = e >> 6),
                (this.tagConstructed = 0 != (32 & e)),
                (this.tagNumber = 31 & e),
                31 == this.tagNumber)
              ) {
                var i = new v();
                do {
                  (e = t.get()), i.mulAdd(128, 127 & e);
                } while (128 & e);
                this.tagNumber = i.simplify();
              }
            }
            return (
              (t.prototype.isUniversal = function () {
                return 0 === this.tagClass;
              }),
              (t.prototype.isEOC = function () {
                return 0 === this.tagClass && 0 === this.tagNumber;
              }),
              t
            );
          })(),
          D = [
            2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61,
            67, 71, 73, 79, 83, 89, 97, 101, 103, 107, 109, 113, 127, 131, 137,
            139, 149, 151, 157, 163, 167, 173, 179, 181, 191, 193, 197, 199,
            211, 223, 227, 229, 233, 239, 241, 251, 257, 263, 269, 271, 277,
            281, 283, 293, 307, 311, 313, 317, 331, 337, 347, 349, 353, 359,
            367, 373, 379, 383, 389, 397, 401, 409, 419, 421, 431, 433, 439,
            443, 449, 457, 461, 463, 467, 479, 487, 491, 499, 503, 509, 521,
            523, 541, 547, 557, 563, 569, 571, 577, 587, 593, 599, 601, 607,
            613, 617, 619, 631, 641, 643, 647, 653, 659, 661, 673, 677, 683,
            691, 701, 709, 719, 727, 733, 739, 743, 751, 757, 761, 769, 773,
            787, 797, 809, 811, 821, 823, 827, 829, 839, 853, 857, 859, 863,
            877, 881, 883, 887, 907, 911, 919, 929, 937, 941, 947, 953, 967,
            971, 977, 983, 991, 997,
          ],
          x = (1 << 26) / D[D.length - 1],
          R = (function () {
            function i(t, e, i) {
              null != t &&
                ("number" == typeof t
                  ? this.fromNumber(t, e, i)
                  : null == e && "string" != typeof t
                  ? this.fromString(t, 256)
                  : this.fromString(t, e));
            }
            return (
              (i.prototype.toString = function (e) {
                if (this.s < 0) return "-" + this.negate().toString(e);
                var i;
                if (16 == e) i = 4;
                else if (8 == e) i = 3;
                else if (2 == e) i = 1;
                else if (32 == e) i = 5;
                else {
                  if (4 != e) return this.toRadix(e);
                  i = 2;
                }
                var r,
                  n = (1 << i) - 1,
                  s = !1,
                  o = "",
                  h = this.t,
                  a = this.DB - ((h * this.DB) % i);
                if (h-- > 0)
                  for (
                    a < this.DB &&
                    (r = this[h] >> a) > 0 &&
                    ((s = !0), (o = t(r)));
                    h >= 0;

                  )
                    a < i
                      ? ((r = (this[h] & ((1 << a) - 1)) << (i - a)),
                        (r |= this[--h] >> (a += this.DB - i)))
                      : ((r = (this[h] >> (a -= i)) & n),
                        a <= 0 && ((a += this.DB), --h)),
                      r > 0 && (s = !0),
                      s && (o += t(r));
                return s ? o : "0";
              }),
              (i.prototype.negate = function () {
                var t = I();
                return i.ZERO.subTo(this, t), t;
              }),
              (i.prototype.abs = function () {
                return this.s < 0 ? this.negate() : this;
              }),
              (i.prototype.compareTo = function (t) {
                var e = this.s - t.s;
                if (0 != e) return e;
                var i = this.t;
                if (0 != (e = i - t.t)) return this.s < 0 ? -e : e;
                for (; --i >= 0; ) if (0 != (e = this[i] - t[i])) return e;
                return 0;
              }),
              (i.prototype.bitLength = function () {
                return this.t <= 0
                  ? 0
                  : this.DB * (this.t - 1) +
                      C(this[this.t - 1] ^ (this.s & this.DM));
              }),
              (i.prototype.mod = function (t) {
                var e = I();
                return (
                  this.abs().divRemTo(t, null, e),
                  this.s < 0 && e.compareTo(i.ZERO) > 0 && t.subTo(e, e),
                  e
                );
              }),
              (i.prototype.modPowInt = function (t, e) {
                var i;
                return (
                  (i = t < 256 || e.isEven() ? new O(e) : new A(e)),
                  this.exp(t, i)
                );
              }),
              (i.prototype.clone = function () {
                var t = I();
                return this.copyTo(t), t;
              }),
              (i.prototype.intValue = function () {
                if (this.s < 0) {
                  if (1 == this.t) return this[0] - this.DV;
                  if (0 == this.t) return -1;
                } else {
                  if (1 == this.t) return this[0];
                  if (0 == this.t) return 0;
                }
                return (
                  ((this[1] & ((1 << (32 - this.DB)) - 1)) << this.DB) | this[0]
                );
              }),
              (i.prototype.byteValue = function () {
                return 0 == this.t ? this.s : (this[0] << 24) >> 24;
              }),
              (i.prototype.shortValue = function () {
                return 0 == this.t ? this.s : (this[0] << 16) >> 16;
              }),
              (i.prototype.signum = function () {
                return this.s < 0
                  ? -1
                  : this.t <= 0 || (1 == this.t && this[0] <= 0)
                  ? 0
                  : 1;
              }),
              (i.prototype.toByteArray = function () {
                var t = this.t,
                  e = [];
                e[0] = this.s;
                var i,
                  r = this.DB - ((t * this.DB) % 8),
                  n = 0;
                if (t-- > 0)
                  for (
                    r < this.DB &&
                    (i = this[t] >> r) != (this.s & this.DM) >> r &&
                    (e[n++] = i | (this.s << (this.DB - r)));
                    t >= 0;

                  )
                    r < 8
                      ? ((i = (this[t] & ((1 << r) - 1)) << (8 - r)),
                        (i |= this[--t] >> (r += this.DB - 8)))
                      : ((i = (this[t] >> (r -= 8)) & 255),
                        r <= 0 && ((r += this.DB), --t)),
                      0 != (128 & i) && (i |= -256),
                      0 == n && (128 & this.s) != (128 & i) && ++n,
                      (n > 0 || i != this.s) && (e[n++] = i);
                return e;
              }),
              (i.prototype.equals = function (t) {
                return 0 == this.compareTo(t);
              }),
              (i.prototype.min = function (t) {
                return this.compareTo(t) < 0 ? this : t;
              }),
              (i.prototype.max = function (t) {
                return this.compareTo(t) > 0 ? this : t;
              }),
              (i.prototype.and = function (t) {
                var i = I();
                return this.bitwiseTo(t, e, i), i;
              }),
              (i.prototype.or = function (t) {
                var e = I();
                return this.bitwiseTo(t, n, e), e;
              }),
              (i.prototype.xor = function (t) {
                var e = I();
                return this.bitwiseTo(t, s, e), e;
              }),
              (i.prototype.andNot = function (t) {
                var e = I();
                return this.bitwiseTo(t, o, e), e;
              }),
              (i.prototype.not = function () {
                for (var t = I(), e = 0; e < this.t; ++e)
                  t[e] = this.DM & ~this[e];
                return (t.t = this.t), (t.s = ~this.s), t;
              }),
              (i.prototype.shiftLeft = function (t) {
                var e = I();
                return t < 0 ? this.rShiftTo(-t, e) : this.lShiftTo(t, e), e;
              }),
              (i.prototype.shiftRight = function (t) {
                var e = I();
                return t < 0 ? this.lShiftTo(-t, e) : this.rShiftTo(t, e), e;
              }),
              (i.prototype.getLowestSetBit = function () {
                for (var t = 0; t < this.t; ++t)
                  if (0 != this[t]) return t * this.DB + h(this[t]);
                return this.s < 0 ? this.t * this.DB : -1;
              }),
              (i.prototype.bitCount = function () {
                for (var t = 0, e = this.s & this.DM, i = 0; i < this.t; ++i)
                  t += a(this[i] ^ e);
                return t;
              }),
              (i.prototype.testBit = function (t) {
                var e = Math.floor(t / this.DB);
                return e >= this.t
                  ? 0 != this.s
                  : 0 != (this[e] & (1 << t % this.DB));
              }),
              (i.prototype.setBit = function (t) {
                return this.changeBit(t, n);
              }),
              (i.prototype.clearBit = function (t) {
                return this.changeBit(t, o);
              }),
              (i.prototype.flipBit = function (t) {
                return this.changeBit(t, s);
              }),
              (i.prototype.add = function (t) {
                var e = I();
                return this.addTo(t, e), e;
              }),
              (i.prototype.subtract = function (t) {
                var e = I();
                return this.subTo(t, e), e;
              }),
              (i.prototype.multiply = function (t) {
                var e = I();
                return this.multiplyTo(t, e), e;
              }),
              (i.prototype.divide = function (t) {
                var e = I();
                return this.divRemTo(t, e, null), e;
              }),
              (i.prototype.remainder = function (t) {
                var e = I();
                return this.divRemTo(t, null, e), e;
              }),
              (i.prototype.divideAndRemainder = function (t) {
                var e = I(),
                  i = I();
                return this.divRemTo(t, e, i), [e, i];
              }),
              (i.prototype.modPow = function (t, e) {
                var i,
                  r,
                  n = t.bitLength(),
                  s = H(1);
                if (n <= 0) return s;
                (i = n < 18 ? 1 : n < 48 ? 3 : n < 144 ? 4 : n < 768 ? 5 : 6),
                  (r = n < 8 ? new O(e) : e.isEven() ? new V(e) : new A(e));
                var o = [],
                  h = 3,
                  a = i - 1,
                  u = (1 << i) - 1;
                if (((o[1] = r.convert(this)), i > 1)) {
                  var c = I();
                  for (r.sqrTo(o[1], c); h <= u; )
                    (o[h] = I()), r.mulTo(c, o[h - 2], o[h]), (h += 2);
                }
                var f,
                  l,
                  p = t.t - 1,
                  g = !0,
                  d = I();
                for (n = C(t[p]) - 1; p >= 0; ) {
                  for (
                    n >= a
                      ? (f = (t[p] >> (n - a)) & u)
                      : ((f = (t[p] & ((1 << (n + 1)) - 1)) << (a - n)),
                        p > 0 && (f |= t[p - 1] >> (this.DB + n - a))),
                      h = i;
                    0 == (1 & f);

                  )
                    (f >>= 1), --h;
                  if (((n -= h) < 0 && ((n += this.DB), --p), g))
                    o[f].copyTo(s), (g = !1);
                  else {
                    for (; h > 1; ) r.sqrTo(s, d), r.sqrTo(d, s), (h -= 2);
                    h > 0 ? r.sqrTo(s, d) : ((l = s), (s = d), (d = l)),
                      r.mulTo(d, o[f], s);
                  }
                  for (; p >= 0 && 0 == (t[p] & (1 << n)); )
                    r.sqrTo(s, d),
                      (l = s),
                      (s = d),
                      (d = l),
                      --n < 0 && ((n = this.DB - 1), --p);
                }
                return r.revert(s);
              }),
              (i.prototype.modInverse = function (t) {
                var e = t.isEven();
                if ((this.isEven() && e) || 0 == t.signum()) return i.ZERO;
                for (
                  var r = t.clone(),
                    n = this.clone(),
                    s = H(1),
                    o = H(0),
                    h = H(0),
                    a = H(1);
                  0 != r.signum();

                ) {
                  for (; r.isEven(); )
                    r.rShiftTo(1, r),
                      e
                        ? ((s.isEven() && o.isEven()) ||
                            (s.addTo(this, s), o.subTo(t, o)),
                          s.rShiftTo(1, s))
                        : o.isEven() || o.subTo(t, o),
                      o.rShiftTo(1, o);
                  for (; n.isEven(); )
                    n.rShiftTo(1, n),
                      e
                        ? ((h.isEven() && a.isEven()) ||
                            (h.addTo(this, h), a.subTo(t, a)),
                          h.rShiftTo(1, h))
                        : a.isEven() || a.subTo(t, a),
                      a.rShiftTo(1, a);
                  r.compareTo(n) >= 0
                    ? (r.subTo(n, r), e && s.subTo(h, s), o.subTo(a, o))
                    : (n.subTo(r, n), e && h.subTo(s, h), a.subTo(o, a));
                }
                return 0 != n.compareTo(i.ONE)
                  ? i.ZERO
                  : a.compareTo(t) >= 0
                  ? a.subtract(t)
                  : a.signum() < 0
                  ? (a.addTo(t, a), a.signum() < 0 ? a.add(t) : a)
                  : a;
              }),
              (i.prototype.pow = function (t) {
                return this.exp(t, new B());
              }),
              (i.prototype.gcd = function (t) {
                var e = this.s < 0 ? this.negate() : this.clone(),
                  i = t.s < 0 ? t.negate() : t.clone();
                if (e.compareTo(i) < 0) {
                  var r = e;
                  (e = i), (i = r);
                }
                var n = e.getLowestSetBit(),
                  s = i.getLowestSetBit();
                if (s < 0) return e;
                for (
                  n < s && (s = n),
                    s > 0 && (e.rShiftTo(s, e), i.rShiftTo(s, i));
                  e.signum() > 0;

                )
                  (n = e.getLowestSetBit()) > 0 && e.rShiftTo(n, e),
                    (n = i.getLowestSetBit()) > 0 && i.rShiftTo(n, i),
                    e.compareTo(i) >= 0
                      ? (e.subTo(i, e), e.rShiftTo(1, e))
                      : (i.subTo(e, i), i.rShiftTo(1, i));
                return s > 0 && i.lShiftTo(s, i), i;
              }),
              (i.prototype.isProbablePrime = function (t) {
                var e,
                  i = this.abs();
                if (1 == i.t && i[0] <= D[D.length - 1]) {
                  for (e = 0; e < D.length; ++e) if (i[0] == D[e]) return !0;
                  return !1;
                }
                if (i.isEven()) return !1;
                for (e = 1; e < D.length; ) {
                  for (var r = D[e], n = e + 1; n < D.length && r < x; )
                    r *= D[n++];
                  for (r = i.modInt(r); e < n; ) if (r % D[e++] == 0) return !1;
                }
                return i.millerRabin(t);
              }),
              (i.prototype.copyTo = function (t) {
                for (var e = this.t - 1; e >= 0; --e) t[e] = this[e];
                (t.t = this.t), (t.s = this.s);
              }),
              (i.prototype.fromInt = function (t) {
                (this.t = 1),
                  (this.s = t < 0 ? -1 : 0),
                  t > 0
                    ? (this[0] = t)
                    : t < -1
                    ? (this[0] = t + this.DV)
                    : (this.t = 0);
              }),
              (i.prototype.fromString = function (t, e) {
                var r;
                if (16 == e) r = 4;
                else if (8 == e) r = 3;
                else if (256 == e) r = 8;
                else if (2 == e) r = 1;
                else if (32 == e) r = 5;
                else {
                  if (4 != e) return void this.fromRadix(t, e);
                  r = 2;
                }
                (this.t = 0), (this.s = 0);
                for (var n = t.length, s = !1, o = 0; --n >= 0; ) {
                  var h = 8 == r ? 255 & +t[n] : q(t, n);
                  h < 0
                    ? "-" == t.charAt(n) && (s = !0)
                    : ((s = !1),
                      0 == o
                        ? (this[this.t++] = h)
                        : o + r > this.DB
                        ? ((this[this.t - 1] |=
                            (h & ((1 << (this.DB - o)) - 1)) << o),
                          (this[this.t++] = h >> (this.DB - o)))
                        : (this[this.t - 1] |= h << o),
                      (o += r) >= this.DB && (o -= this.DB));
                }
                8 == r &&
                  0 != (128 & +t[0]) &&
                  ((this.s = -1),
                  o > 0 &&
                    (this[this.t - 1] |= ((1 << (this.DB - o)) - 1) << o)),
                  this.clamp(),
                  s && i.ZERO.subTo(this, this);
              }),
              (i.prototype.clamp = function () {
                for (
                  var t = this.s & this.DM;
                  this.t > 0 && this[this.t - 1] == t;

                )
                  --this.t;
              }),
              (i.prototype.dlShiftTo = function (t, e) {
                var i;
                for (i = this.t - 1; i >= 0; --i) e[i + t] = this[i];
                for (i = t - 1; i >= 0; --i) e[i] = 0;
                (e.t = this.t + t), (e.s = this.s);
              }),
              (i.prototype.drShiftTo = function (t, e) {
                for (var i = t; i < this.t; ++i) e[i - t] = this[i];
                (e.t = Math.max(this.t - t, 0)), (e.s = this.s);
              }),
              (i.prototype.lShiftTo = function (t, e) {
                for (
                  var i = t % this.DB,
                    r = this.DB - i,
                    n = (1 << r) - 1,
                    s = Math.floor(t / this.DB),
                    o = (this.s << i) & this.DM,
                    h = this.t - 1;
                  h >= 0;
                  --h
                )
                  (e[h + s + 1] = (this[h] >> r) | o), (o = (this[h] & n) << i);
                for (h = s - 1; h >= 0; --h) e[h] = 0;
                (e[s] = o), (e.t = this.t + s + 1), (e.s = this.s), e.clamp();
              }),
              (i.prototype.rShiftTo = function (t, e) {
                e.s = this.s;
                var i = Math.floor(t / this.DB);
                if (i >= this.t) e.t = 0;
                else {
                  var r = t % this.DB,
                    n = this.DB - r,
                    s = (1 << r) - 1;
                  e[0] = this[i] >> r;
                  for (var o = i + 1; o < this.t; ++o)
                    (e[o - i - 1] |= (this[o] & s) << n),
                      (e[o - i] = this[o] >> r);
                  r > 0 && (e[this.t - i - 1] |= (this.s & s) << n),
                    (e.t = this.t - i),
                    e.clamp();
                }
              }),
              (i.prototype.subTo = function (t, e) {
                for (var i = 0, r = 0, n = Math.min(t.t, this.t); i < n; )
                  (r += this[i] - t[i]),
                    (e[i++] = r & this.DM),
                    (r >>= this.DB);
                if (t.t < this.t) {
                  for (r -= t.s; i < this.t; )
                    (r += this[i]), (e[i++] = r & this.DM), (r >>= this.DB);
                  r += this.s;
                } else {
                  for (r += this.s; i < t.t; )
                    (r -= t[i]), (e[i++] = r & this.DM), (r >>= this.DB);
                  r -= t.s;
                }
                (e.s = r < 0 ? -1 : 0),
                  r < -1 ? (e[i++] = this.DV + r) : r > 0 && (e[i++] = r),
                  (e.t = i),
                  e.clamp();
              }),
              (i.prototype.multiplyTo = function (t, e) {
                var r = this.abs(),
                  n = t.abs(),
                  s = r.t;
                for (e.t = s + n.t; --s >= 0; ) e[s] = 0;
                for (s = 0; s < n.t; ++s)
                  e[s + r.t] = r.am(0, n[s], e, s, 0, r.t);
                (e.s = 0), e.clamp(), this.s != t.s && i.ZERO.subTo(e, e);
              }),
              (i.prototype.squareTo = function (t) {
                for (var e = this.abs(), i = (t.t = 2 * e.t); --i >= 0; )
                  t[i] = 0;
                for (i = 0; i < e.t - 1; ++i) {
                  var r = e.am(i, e[i], t, 2 * i, 0, 1);
                  (t[i + e.t] += e.am(
                    i + 1,
                    2 * e[i],
                    t,
                    2 * i + 1,
                    r,
                    e.t - i - 1
                  )) >= e.DV && ((t[i + e.t] -= e.DV), (t[i + e.t + 1] = 1));
                }
                t.t > 0 && (t[t.t - 1] += e.am(i, e[i], t, 2 * i, 0, 1)),
                  (t.s = 0),
                  t.clamp();
              }),
              (i.prototype.divRemTo = function (t, e, r) {
                var n = t.abs();
                if (!(n.t <= 0)) {
                  var s = this.abs();
                  if (s.t < n.t)
                    return (
                      null != e && e.fromInt(0),
                      void (null != r && this.copyTo(r))
                    );
                  null == r && (r = I());
                  var o = I(),
                    h = this.s,
                    a = t.s,
                    u = this.DB - C(n[n.t - 1]);
                  u > 0
                    ? (n.lShiftTo(u, o), s.lShiftTo(u, r))
                    : (n.copyTo(o), s.copyTo(r));
                  var c = o.t,
                    f = o[c - 1];
                  if (0 != f) {
                    var l =
                        f * (1 << this.F1) + (c > 1 ? o[c - 2] >> this.F2 : 0),
                      p = this.FV / l,
                      g = (1 << this.F1) / l,
                      d = 1 << this.F2,
                      v = r.t,
                      m = v - c,
                      y = null == e ? I() : e;
                    for (
                      o.dlShiftTo(m, y),
                        r.compareTo(y) >= 0 && ((r[r.t++] = 1), r.subTo(y, r)),
                        i.ONE.dlShiftTo(c, y),
                        y.subTo(o, o);
                      o.t < c;

                    )
                      o[o.t++] = 0;
                    for (; --m >= 0; ) {
                      var b =
                        r[--v] == f
                          ? this.DM
                          : Math.floor(r[v] * p + (r[v - 1] + d) * g);
                      if ((r[v] += o.am(0, b, r, m, 0, c)) < b)
                        for (o.dlShiftTo(m, y), r.subTo(y, r); r[v] < --b; )
                          r.subTo(y, r);
                    }
                    null != e &&
                      (r.drShiftTo(c, e), h != a && i.ZERO.subTo(e, e)),
                      (r.t = c),
                      r.clamp(),
                      u > 0 && r.rShiftTo(u, r),
                      h < 0 && i.ZERO.subTo(r, r);
                  }
                }
              }),
              (i.prototype.invDigit = function () {
                if (this.t < 1) return 0;
                var t = this[0];
                if (0 == (1 & t)) return 0;
                var e = 3 & t;
                return (e =
                  ((e =
                    ((e =
                      ((e = (e * (2 - (15 & t) * e)) & 15) *
                        (2 - (255 & t) * e)) &
                      255) *
                      (2 - (((65535 & t) * e) & 65535))) &
                    65535) *
                    (2 - ((t * e) % this.DV))) %
                  this.DV) > 0
                  ? this.DV - e
                  : -e;
              }),
              (i.prototype.isEven = function () {
                return 0 == (this.t > 0 ? 1 & this[0] : this.s);
              }),
              (i.prototype.exp = function (t, e) {
                if (t > 4294967295 || t < 1) return i.ONE;
                var r = I(),
                  n = I(),
                  s = e.convert(this),
                  o = C(t) - 1;
                for (s.copyTo(r); --o >= 0; )
                  if ((e.sqrTo(r, n), (t & (1 << o)) > 0)) e.mulTo(n, s, r);
                  else {
                    var h = r;
                    (r = n), (n = h);
                  }
                return e.revert(r);
              }),
              (i.prototype.chunkSize = function (t) {
                return Math.floor((Math.LN2 * this.DB) / Math.log(t));
              }),
              (i.prototype.toRadix = function (t) {
                if (
                  (null == t && (t = 10), 0 == this.signum() || t < 2 || t > 36)
                )
                  return "0";
                var e = this.chunkSize(t),
                  i = Math.pow(t, e),
                  r = H(i),
                  n = I(),
                  s = I(),
                  o = "";
                for (this.divRemTo(r, n, s); n.signum() > 0; )
                  (o = (i + s.intValue()).toString(t).substr(1) + o),
                    n.divRemTo(r, n, s);
                return s.intValue().toString(t) + o;
              }),
              (i.prototype.fromRadix = function (t, e) {
                this.fromInt(0), null == e && (e = 10);
                for (
                  var r = this.chunkSize(e),
                    n = Math.pow(e, r),
                    s = !1,
                    o = 0,
                    h = 0,
                    a = 0;
                  a < t.length;
                  ++a
                ) {
                  var u = q(t, a);
                  u < 0
                    ? "-" == t.charAt(a) && 0 == this.signum() && (s = !0)
                    : ((h = e * h + u),
                      ++o >= r &&
                        (this.dMultiply(n),
                        this.dAddOffset(h, 0),
                        (o = 0),
                        (h = 0)));
                }
                o > 0 &&
                  (this.dMultiply(Math.pow(e, o)), this.dAddOffset(h, 0)),
                  s && i.ZERO.subTo(this, this);
              }),
              (i.prototype.fromNumber = function (t, e, r) {
                if ("number" == typeof e)
                  if (t < 2) this.fromInt(1);
                  else
                    for (
                      this.fromNumber(t, r),
                        this.testBit(t - 1) ||
                          this.bitwiseTo(i.ONE.shiftLeft(t - 1), n, this),
                        this.isEven() && this.dAddOffset(1, 0);
                      !this.isProbablePrime(e);

                    )
                      this.dAddOffset(2, 0),
                        this.bitLength() > t &&
                          this.subTo(i.ONE.shiftLeft(t - 1), this);
                else {
                  var s = [],
                    o = 7 & t;
                  (s.length = 1 + (t >> 3)),
                    e.nextBytes(s),
                    o > 0 ? (s[0] &= (1 << o) - 1) : (s[0] = 0),
                    this.fromString(s, 256);
                }
              }),
              (i.prototype.bitwiseTo = function (t, e, i) {
                var r,
                  n,
                  s = Math.min(t.t, this.t);
                for (r = 0; r < s; ++r) i[r] = e(this[r], t[r]);
                if (t.t < this.t) {
                  for (n = t.s & this.DM, r = s; r < this.t; ++r)
                    i[r] = e(this[r], n);
                  i.t = this.t;
                } else {
                  for (n = this.s & this.DM, r = s; r < t.t; ++r)
                    i[r] = e(n, t[r]);
                  i.t = t.t;
                }
                (i.s = e(this.s, t.s)), i.clamp();
              }),
              (i.prototype.changeBit = function (t, e) {
                var r = i.ONE.shiftLeft(t);
                return this.bitwiseTo(r, e, r), r;
              }),
              (i.prototype.addTo = function (t, e) {
                for (var i = 0, r = 0, n = Math.min(t.t, this.t); i < n; )
                  (r += this[i] + t[i]),
                    (e[i++] = r & this.DM),
                    (r >>= this.DB);
                if (t.t < this.t) {
                  for (r += t.s; i < this.t; )
                    (r += this[i]), (e[i++] = r & this.DM), (r >>= this.DB);
                  r += this.s;
                } else {
                  for (r += this.s; i < t.t; )
                    (r += t[i]), (e[i++] = r & this.DM), (r >>= this.DB);
                  r += t.s;
                }
                (e.s = r < 0 ? -1 : 0),
                  r > 0 ? (e[i++] = r) : r < -1 && (e[i++] = this.DV + r),
                  (e.t = i),
                  e.clamp();
              }),
              (i.prototype.dMultiply = function (t) {
                (this[this.t] = this.am(0, t - 1, this, 0, 0, this.t)),
                  ++this.t,
                  this.clamp();
              }),
              (i.prototype.dAddOffset = function (t, e) {
                if (0 != t) {
                  for (; this.t <= e; ) this[this.t++] = 0;
                  for (this[e] += t; this[e] >= this.DV; )
                    (this[e] -= this.DV),
                      ++e >= this.t && (this[this.t++] = 0),
                      ++this[e];
                }
              }),
              (i.prototype.multiplyLowerTo = function (t, e, i) {
                var r = Math.min(this.t + t.t, e);
                for (i.s = 0, i.t = r; r > 0; ) i[--r] = 0;
                for (var n = i.t - this.t; r < n; ++r)
                  i[r + this.t] = this.am(0, t[r], i, r, 0, this.t);
                for (n = Math.min(t.t, e); r < n; ++r)
                  this.am(0, t[r], i, r, 0, e - r);
                i.clamp();
              }),
              (i.prototype.multiplyUpperTo = function (t, e, i) {
                --e;
                var r = (i.t = this.t + t.t - e);
                for (i.s = 0; --r >= 0; ) i[r] = 0;
                for (r = Math.max(e - this.t, 0); r < t.t; ++r)
                  i[this.t + r - e] = this.am(
                    e - r,
                    t[r],
                    i,
                    0,
                    0,
                    this.t + r - e
                  );
                i.clamp(), i.drShiftTo(1, i);
              }),
              (i.prototype.modInt = function (t) {
                if (t <= 0) return 0;
                var e = this.DV % t,
                  i = this.s < 0 ? t - 1 : 0;
                if (this.t > 0)
                  if (0 == e) i = this[0] % t;
                  else
                    for (var r = this.t - 1; r >= 0; --r)
                      i = (e * i + this[r]) % t;
                return i;
              }),
              (i.prototype.millerRabin = function (t) {
                var e = this.subtract(i.ONE),
                  r = e.getLowestSetBit();
                if (r <= 0) return !1;
                var n = e.shiftRight(r);
                (t = (t + 1) >> 1) > D.length && (t = D.length);
                for (var s = I(), o = 0; o < t; ++o) {
                  s.fromInt(D[Math.floor(Math.random() * D.length)]);
                  var h = s.modPow(n, this);
                  if (0 != h.compareTo(i.ONE) && 0 != h.compareTo(e)) {
                    for (var a = 1; a++ < r && 0 != h.compareTo(e); )
                      if (0 == (h = h.modPowInt(2, this)).compareTo(i.ONE))
                        return !1;
                    if (0 != h.compareTo(e)) return !1;
                  }
                }
                return !0;
              }),
              (i.prototype.square = function () {
                var t = I();
                return this.squareTo(t), t;
              }),
              (i.prototype.gcda = function (t, e) {
                var i = this.s < 0 ? this.negate() : this.clone(),
                  r = t.s < 0 ? t.negate() : t.clone();
                if (i.compareTo(r) < 0) {
                  var n = i;
                  (i = r), (r = n);
                }
                var s = i.getLowestSetBit(),
                  o = r.getLowestSetBit();
                if (o < 0) e(i);
                else {
                  s < o && (o = s),
                    o > 0 && (i.rShiftTo(o, i), r.rShiftTo(o, r));
                  var h = function () {
                    (s = i.getLowestSetBit()) > 0 && i.rShiftTo(s, i),
                      (s = r.getLowestSetBit()) > 0 && r.rShiftTo(s, r),
                      i.compareTo(r) >= 0
                        ? (i.subTo(r, i), i.rShiftTo(1, i))
                        : (r.subTo(i, r), r.rShiftTo(1, r)),
                      i.signum() > 0
                        ? setTimeout(h, 0)
                        : (o > 0 && r.lShiftTo(o, r),
                          setTimeout(function () {
                            e(r);
                          }, 0));
                  };
                  setTimeout(h, 10);
                }
              }),
              (i.prototype.fromNumberAsync = function (t, e, r, s) {
                if ("number" == typeof e)
                  if (t < 2) this.fromInt(1);
                  else {
                    this.fromNumber(t, r),
                      this.testBit(t - 1) ||
                        this.bitwiseTo(i.ONE.shiftLeft(t - 1), n, this),
                      this.isEven() && this.dAddOffset(1, 0);
                    var o = this,
                      h = function () {
                        o.dAddOffset(2, 0),
                          o.bitLength() > t &&
                            o.subTo(i.ONE.shiftLeft(t - 1), o),
                          o.isProbablePrime(e)
                            ? setTimeout(function () {
                                s();
                              }, 0)
                            : setTimeout(h, 0);
                      };
                    setTimeout(h, 0);
                  }
                else {
                  var a = [],
                    u = 7 & t;
                  (a.length = 1 + (t >> 3)),
                    e.nextBytes(a),
                    u > 0 ? (a[0] &= (1 << u) - 1) : (a[0] = 0),
                    this.fromString(a, 256);
                }
              }),
              i
            );
          })(),
          B = (function () {
            function t() {}
            return (
              (t.prototype.convert = function (t) {
                return t;
              }),
              (t.prototype.revert = function (t) {
                return t;
              }),
              (t.prototype.mulTo = function (t, e, i) {
                t.multiplyTo(e, i);
              }),
              (t.prototype.sqrTo = function (t, e) {
                t.squareTo(e);
              }),
              t
            );
          })(),
          O = (function () {
            function t(t) {
              this.m = t;
            }
            return (
              (t.prototype.convert = function (t) {
                return t.s < 0 || t.compareTo(this.m) >= 0 ? t.mod(this.m) : t;
              }),
              (t.prototype.revert = function (t) {
                return t;
              }),
              (t.prototype.reduce = function (t) {
                t.divRemTo(this.m, null, t);
              }),
              (t.prototype.mulTo = function (t, e, i) {
                t.multiplyTo(e, i), this.reduce(i);
              }),
              (t.prototype.sqrTo = function (t, e) {
                t.squareTo(e), this.reduce(e);
              }),
              t
            );
          })(),
          A = (function () {
            function t(t) {
              (this.m = t),
                (this.mp = t.invDigit()),
                (this.mpl = 32767 & this.mp),
                (this.mph = this.mp >> 15),
                (this.um = (1 << (t.DB - 15)) - 1),
                (this.mt2 = 2 * t.t);
            }
            return (
              (t.prototype.convert = function (t) {
                var e = I();
                return (
                  t.abs().dlShiftTo(this.m.t, e),
                  e.divRemTo(this.m, null, e),
                  t.s < 0 && e.compareTo(R.ZERO) > 0 && this.m.subTo(e, e),
                  e
                );
              }),
              (t.prototype.revert = function (t) {
                var e = I();
                return t.copyTo(e), this.reduce(e), e;
              }),
              (t.prototype.reduce = function (t) {
                for (; t.t <= this.mt2; ) t[t.t++] = 0;
                for (var e = 0; e < this.m.t; ++e) {
                  var i = 32767 & t[e],
                    r =
                      (i * this.mpl +
                        (((i * this.mph + (t[e] >> 15) * this.mpl) & this.um) <<
                          15)) &
                      t.DM;
                  for (
                    t[(i = e + this.m.t)] += this.m.am(0, r, t, e, 0, this.m.t);
                    t[i] >= t.DV;

                  )
                    (t[i] -= t.DV), t[++i]++;
                }
                t.clamp(),
                  t.drShiftTo(this.m.t, t),
                  t.compareTo(this.m) >= 0 && t.subTo(this.m, t);
              }),
              (t.prototype.mulTo = function (t, e, i) {
                t.multiplyTo(e, i), this.reduce(i);
              }),
              (t.prototype.sqrTo = function (t, e) {
                t.squareTo(e), this.reduce(e);
              }),
              t
            );
          })(),
          V = (function () {
            function t(t) {
              (this.m = t),
                (this.r2 = I()),
                (this.q3 = I()),
                R.ONE.dlShiftTo(2 * t.t, this.r2),
                (this.mu = this.r2.divide(t));
            }
            return (
              (t.prototype.convert = function (t) {
                if (t.s < 0 || t.t > 2 * this.m.t) return t.mod(this.m);
                if (t.compareTo(this.m) < 0) return t;
                var e = I();
                return t.copyTo(e), this.reduce(e), e;
              }),
              (t.prototype.revert = function (t) {
                return t;
              }),
              (t.prototype.reduce = function (t) {
                for (
                  t.drShiftTo(this.m.t - 1, this.r2),
                    t.t > this.m.t + 1 && ((t.t = this.m.t + 1), t.clamp()),
                    this.mu.multiplyUpperTo(this.r2, this.m.t + 1, this.q3),
                    this.m.multiplyLowerTo(this.q3, this.m.t + 1, this.r2);
                  t.compareTo(this.r2) < 0;

                )
                  t.dAddOffset(1, this.m.t + 1);
                for (t.subTo(this.r2, t); t.compareTo(this.m) >= 0; )
                  t.subTo(this.m, t);
              }),
              (t.prototype.mulTo = function (t, e, i) {
                t.multiplyTo(e, i), this.reduce(i);
              }),
              (t.prototype.sqrTo = function (t, e) {
                t.squareTo(e), this.reduce(e);
              }),
              t
            );
          })();
        function I() {
          return new R(null);
        }
        function N(t, e) {
          return new R(t, e);
        }
        var P = "undefined" != typeof navigator;
        P && "Microsoft Internet Explorer" == navigator.appName
          ? ((R.prototype.am = function (t, e, i, r, n, s) {
              for (var o = 32767 & e, h = e >> 15; --s >= 0; ) {
                var a = 32767 & this[t],
                  u = this[t++] >> 15,
                  c = h * a + u * o;
                (n =
                  ((a =
                    o * a + ((32767 & c) << 15) + i[r] + (1073741823 & n)) >>>
                    30) +
                  (c >>> 15) +
                  h * u +
                  (n >>> 30)),
                  (i[r++] = 1073741823 & a);
              }
              return n;
            }),
            (T = 30))
          : P && "Netscape" != navigator.appName
          ? ((R.prototype.am = function (t, e, i, r, n, s) {
              for (; --s >= 0; ) {
                var o = e * this[t++] + i[r] + n;
                (n = Math.floor(o / 67108864)), (i[r++] = 67108863 & o);
              }
              return n;
            }),
            (T = 26))
          : ((R.prototype.am = function (t, e, i, r, n, s) {
              for (var o = 16383 & e, h = e >> 14; --s >= 0; ) {
                var a = 16383 & this[t],
                  u = this[t++] >> 14,
                  c = h * a + u * o;
                (n =
                  ((a = o * a + ((16383 & c) << 14) + i[r] + n) >> 28) +
                  (c >> 14) +
                  h * u),
                  (i[r++] = 268435455 & a);
              }
              return n;
            }),
            (T = 28)),
          (R.prototype.DB = T),
          (R.prototype.DM = (1 << T) - 1),
          (R.prototype.DV = 1 << T),
          (R.prototype.FV = Math.pow(2, 52)),
          (R.prototype.F1 = 52 - T),
          (R.prototype.F2 = 2 * T - 52);
        var M,
          L,
          j = [];
        for (M = "0".charCodeAt(0), L = 0; L <= 9; ++L) j[M++] = L;
        for (M = "a".charCodeAt(0), L = 10; L < 36; ++L) j[M++] = L;
        for (M = "A".charCodeAt(0), L = 10; L < 36; ++L) j[M++] = L;
        function q(t, e) {
          var i = j[t.charCodeAt(e)];
          return null == i ? -1 : i;
        }
        function H(t) {
          var e = I();
          return e.fromInt(t), e;
        }
        function C(t) {
          var e,
            i = 1;
          return (
            0 != (e = t >>> 16) && ((t = e), (i += 16)),
            0 != (e = t >> 8) && ((t = e), (i += 8)),
            0 != (e = t >> 4) && ((t = e), (i += 4)),
            0 != (e = t >> 2) && ((t = e), (i += 2)),
            0 != (e = t >> 1) && ((t = e), (i += 1)),
            i
          );
        }
        (R.ZERO = H(0)), (R.ONE = H(1));
        var F,
          U,
          K = (function () {
            function t() {
              (this.i = 0), (this.j = 0), (this.S = []);
            }
            return (
              (t.prototype.init = function (t) {
                var e, i, r;
                for (e = 0; e < 256; ++e) this.S[e] = e;
                for (i = 0, e = 0; e < 256; ++e)
                  (i = (i + this.S[e] + t[e % t.length]) & 255),
                    (r = this.S[e]),
                    (this.S[e] = this.S[i]),
                    (this.S[i] = r);
                (this.i = 0), (this.j = 0);
              }),
              (t.prototype.next = function () {
                var t;
                return (
                  (this.i = (this.i + 1) & 255),
                  (this.j = (this.j + this.S[this.i]) & 255),
                  (t = this.S[this.i]),
                  (this.S[this.i] = this.S[this.j]),
                  (this.S[this.j] = t),
                  this.S[(t + this.S[this.i]) & 255]
                );
              }),
              t
            );
          })(),
          k = null;
        if (null == k) {
          (k = []), (U = 0);
          var _ = void 0;
          if (
            "undefined" != typeof window &&
            window.crypto &&
            window.crypto.getRandomValues
          ) {
            var z = new Uint32Array(256);
            for (window.crypto.getRandomValues(z), _ = 0; _ < z.length; ++_)
              k[U++] = 255 & z[_];
          }
          var Z = 0,
            G = function (t) {
              if ((Z = Z || 0) >= 256 || U >= 256)
                window.removeEventListener
                  ? window.removeEventListener("mousemove", G, !1)
                  : window.detachEvent && window.detachEvent("onmousemove", G);
              else
                try {
                  var e = t.x + t.y;
                  (k[U++] = 255 & e), (Z += 1);
                } catch (t) {}
            };
          "undefined" != typeof window &&
            (window.addEventListener
              ? window.addEventListener("mousemove", G, !1)
              : window.attachEvent && window.attachEvent("onmousemove", G));
        }
        function $() {
          if (null == F) {
            for (F = new K(); U < 256; ) {
              var t = Math.floor(65536 * Math.random());
              k[U++] = 255 & t;
            }
            for (F.init(k), U = 0; U < k.length; ++U) k[U] = 0;
            U = 0;
          }
          return F.next();
        }
        var Y = (function () {
            function t() {}
            return (
              (t.prototype.nextBytes = function (t) {
                for (var e = 0; e < t.length; ++e) t[e] = $();
              }),
              t
            );
          })(),
          J = (function () {
            function t() {
              (this.n = null),
                (this.e = 0),
                (this.d = null),
                (this.p = null),
                (this.q = null),
                (this.dmp1 = null),
                (this.dmq1 = null),
                (this.coeff = null);
            }
            return (
              (t.prototype.doPublic = function (t) {
                return t.modPowInt(this.e, this.n);
              }),
              (t.prototype.doPrivate = function (t) {
                if (null == this.p || null == this.q)
                  return t.modPow(this.d, this.n);
                for (
                  var e = t.mod(this.p).modPow(this.dmp1, this.p),
                    i = t.mod(this.q).modPow(this.dmq1, this.q);
                  e.compareTo(i) < 0;

                )
                  e = e.add(this.p);
                return e
                  .subtract(i)
                  .multiply(this.coeff)
                  .mod(this.p)
                  .multiply(this.q)
                  .add(i);
              }),
              (t.prototype.setPublic = function (t, e) {
                null != t && null != e && t.length > 0 && e.length > 0
                  ? ((this.n = N(t, 16)), (this.e = parseInt(e, 16)))
                  : console.error("Invalid RSA public key");
              }),
              (t.prototype.encrypt = function (t) {
                var e = (this.n.bitLength() + 7) >> 3,
                  i = (function (t, e) {
                    if (e < t.length + 11)
                      return console.error("Message too long for RSA"), null;
                    for (var i = [], r = t.length - 1; r >= 0 && e > 0; ) {
                      var n = t.charCodeAt(r--);
                      n < 128
                        ? (i[--e] = n)
                        : n > 127 && n < 2048
                        ? ((i[--e] = (63 & n) | 128), (i[--e] = (n >> 6) | 192))
                        : ((i[--e] = (63 & n) | 128),
                          (i[--e] = ((n >> 6) & 63) | 128),
                          (i[--e] = (n >> 12) | 224));
                    }
                    i[--e] = 0;
                    for (var s = new Y(), o = []; e > 2; ) {
                      for (o[0] = 0; 0 == o[0]; ) s.nextBytes(o);
                      i[--e] = o[0];
                    }
                    return (i[--e] = 2), (i[--e] = 0), new R(i);
                  })(t, e);
                if (null == i) return null;
                var r = this.doPublic(i);
                if (null == r) return null;
                for (
                  var n = r.toString(16), s = n.length, o = 0;
                  o < 2 * e - s;
                  o++
                )
                  n = "0" + n;
                return n;
              }),
              (t.prototype.setPrivate = function (t, e, i) {
                null != t && null != e && t.length > 0 && e.length > 0
                  ? ((this.n = N(t, 16)),
                    (this.e = parseInt(e, 16)),
                    (this.d = N(i, 16)))
                  : console.error("Invalid RSA private key");
              }),
              (t.prototype.setPrivateEx = function (t, e, i, r, n, s, o, h) {
                null != t && null != e && t.length > 0 && e.length > 0
                  ? ((this.n = N(t, 16)),
                    (this.e = parseInt(e, 16)),
                    (this.d = N(i, 16)),
                    (this.p = N(r, 16)),
                    (this.q = N(n, 16)),
                    (this.dmp1 = N(s, 16)),
                    (this.dmq1 = N(o, 16)),
                    (this.coeff = N(h, 16)))
                  : console.error("Invalid RSA private key");
              }),
              (t.prototype.generate = function (t, e) {
                var i = new Y(),
                  r = t >> 1;
                this.e = parseInt(e, 16);
                for (var n = new R(e, 16); ; ) {
                  for (
                    ;
                    (this.p = new R(t - r, 1, i)),
                      0 != this.p.subtract(R.ONE).gcd(n).compareTo(R.ONE) ||
                        !this.p.isProbablePrime(10);

                  );
                  for (
                    ;
                    (this.q = new R(r, 1, i)),
                      0 != this.q.subtract(R.ONE).gcd(n).compareTo(R.ONE) ||
                        !this.q.isProbablePrime(10);

                  );
                  if (this.p.compareTo(this.q) <= 0) {
                    var s = this.p;
                    (this.p = this.q), (this.q = s);
                  }
                  var o = this.p.subtract(R.ONE),
                    h = this.q.subtract(R.ONE),
                    a = o.multiply(h);
                  if (0 == a.gcd(n).compareTo(R.ONE)) {
                    (this.n = this.p.multiply(this.q)),
                      (this.d = n.modInverse(a)),
                      (this.dmp1 = this.d.mod(o)),
                      (this.dmq1 = this.d.mod(h)),
                      (this.coeff = this.q.modInverse(this.p));
                    break;
                  }
                }
              }),
              (t.prototype.decrypt = function (t) {
                var e = N(t, 16),
                  i = this.doPrivate(e);
                return null == i
                  ? null
                  : (function (t, e) {
                      for (
                        var i = t.toByteArray(), r = 0;
                        r < i.length && 0 == i[r];

                      )
                        ++r;
                      if (i.length - r != e - 1 || 2 != i[r]) return null;
                      for (++r; 0 != i[r]; ) if (++r >= i.length) return null;
                      for (var n = ""; ++r < i.length; ) {
                        var s = 255 & i[r];
                        s < 128
                          ? (n += String.fromCharCode(s))
                          : s > 191 && s < 224
                          ? ((n += String.fromCharCode(
                              ((31 & s) << 6) | (63 & i[r + 1])
                            )),
                            ++r)
                          : ((n += String.fromCharCode(
                              ((15 & s) << 12) |
                                ((63 & i[r + 1]) << 6) |
                                (63 & i[r + 2])
                            )),
                            (r += 2));
                      }
                      return n;
                    })(i, (this.n.bitLength() + 7) >> 3);
              }),
              (t.prototype.generateAsync = function (t, e, i) {
                var r = new Y(),
                  n = t >> 1;
                this.e = parseInt(e, 16);
                var s = new R(e, 16),
                  o = this,
                  h = function () {
                    var e = function () {
                        if (o.p.compareTo(o.q) <= 0) {
                          var t = o.p;
                          (o.p = o.q), (o.q = t);
                        }
                        var e = o.p.subtract(R.ONE),
                          r = o.q.subtract(R.ONE),
                          n = e.multiply(r);
                        0 == n.gcd(s).compareTo(R.ONE)
                          ? ((o.n = o.p.multiply(o.q)),
                            (o.d = s.modInverse(n)),
                            (o.dmp1 = o.d.mod(e)),
                            (o.dmq1 = o.d.mod(r)),
                            (o.coeff = o.q.modInverse(o.p)),
                            setTimeout(function () {
                              i();
                            }, 0))
                          : setTimeout(h, 0);
                      },
                      a = function () {
                        (o.q = I()),
                          o.q.fromNumberAsync(n, 1, r, function () {
                            o.q.subtract(R.ONE).gcda(s, function (t) {
                              0 == t.compareTo(R.ONE) && o.q.isProbablePrime(10)
                                ? setTimeout(e, 0)
                                : setTimeout(a, 0);
                            });
                          });
                      },
                      u = function () {
                        (o.p = I()),
                          o.p.fromNumberAsync(t - n, 1, r, function () {
                            o.p.subtract(R.ONE).gcda(s, function (t) {
                              0 == t.compareTo(R.ONE) && o.p.isProbablePrime(10)
                                ? setTimeout(a, 0)
                                : setTimeout(u, 0);
                            });
                          });
                      };
                    setTimeout(u, 0);
                  };
                setTimeout(h, 0);
              }),
              (t.prototype.sign = function (t, e, i) {
                var r = (function (t, e) {
                  if (e < t.length + 22)
                    return console.error("Message too long for RSA"), null;
                  for (var i = e - t.length - 6, r = "", n = 0; n < i; n += 2)
                    r += "ff";
                  return N("0001" + r + "00" + t, 16);
                })((X[i] || "") + e(t).toString(), this.n.bitLength() / 4);
                if (null == r) return null;
                var n = this.doPrivate(r);
                if (null == n) return null;
                var s = n.toString(16);
                return 0 == (1 & s.length) ? s : "0" + s;
              }),
              (t.prototype.verify = function (t, e, i) {
                var r = N(e, 16),
                  n = this.doPublic(r);
                return null == n
                  ? null
                  : (function (t) {
                      for (var e in X)
                        if (X.hasOwnProperty(e)) {
                          var i = X[e],
                            r = i.length;
                          if (t.substr(0, r) == i) return t.substr(r);
                        }
                      return t;
                    })(n.toString(16).replace(/^1f+00/, "")) == i(t).toString();
              }),
              t
            );
          })(),
          X = {
            md2: "3020300c06082a864886f70d020205000410",
            md5: "3020300c06082a864886f70d020505000410",
            sha1: "3021300906052b0e03021a05000414",
            sha224: "302d300d06096086480165030402040500041c",
            sha256: "3031300d060960864801650304020105000420",
            sha384: "3041300d060960864801650304020205000430",
            sha512: "3051300d060960864801650304020305000440",
            ripemd160: "3021300906052b2403020105000414",
          },
          Q = {};
        Q.lang = {
          extend: function (t, e, i) {
            if (!e || !t)
              throw new Error(
                "YAHOO.lang.extend failed, please check that all dependencies are included."
              );
            var r = function () {};
            if (
              ((r.prototype = e.prototype),
              (t.prototype = new r()),
              (t.prototype.constructor = t),
              (t.superclass = e.prototype),
              e.prototype.constructor == Object.prototype.constructor &&
                (e.prototype.constructor = e),
              i)
            ) {
              var n;
              for (n in i) t.prototype[n] = i[n];
              var s = function () {},
                o = ["toString", "valueOf"];
              try {
                /MSIE/.test(navigator.userAgent) &&
                  (s = function (t, e) {
                    for (n = 0; n < o.length; n += 1) {
                      var i = o[n],
                        r = e[i];
                      "function" == typeof r &&
                        r != Object.prototype[i] &&
                        (t[i] = r);
                    }
                  });
              } catch (t) {}
              s(t.prototype, i);
            }
          },
        };
        var W = {};
        (void 0 !== W.asn1 && W.asn1) || (W.asn1 = {}),
          (W.asn1.ASN1Util = new (function () {
            (this.integerToByteHex = function (t) {
              var e = t.toString(16);
              return e.length % 2 == 1 && (e = "0" + e), e;
            }),
              (this.bigIntToMinTwosComplementsHex = function (t) {
                var e = t.toString(16);
                if ("-" != e.substr(0, 1))
                  e.length % 2 == 1
                    ? (e = "0" + e)
                    : e.match(/^[0-7]/) || (e = "00" + e);
                else {
                  var i = e.substr(1).length;
                  i % 2 == 1 ? (i += 1) : e.match(/^[0-7]/) || (i += 2);
                  for (var r = "", n = 0; n < i; n++) r += "f";
                  e = new R(r, 16)
                    .xor(t)
                    .add(R.ONE)
                    .toString(16)
                    .replace(/^-/, "");
                }
                return e;
              }),
              (this.getPEMStringFromHex = function (t, e) {
                return hextopem(t, e);
              }),
              (this.newObject = function (t) {
                var e = W.asn1,
                  i = e.DERBoolean,
                  r = e.DERInteger,
                  n = e.DERBitString,
                  s = e.DEROctetString,
                  o = e.DERNull,
                  h = e.DERObjectIdentifier,
                  a = e.DEREnumerated,
                  u = e.DERUTF8String,
                  c = e.DERNumericString,
                  f = e.DERPrintableString,
                  l = e.DERTeletexString,
                  p = e.DERIA5String,
                  g = e.DERUTCTime,
                  d = e.DERGeneralizedTime,
                  v = e.DERSequence,
                  m = e.DERSet,
                  y = e.DERTaggedObject,
                  b = e.ASN1Util.newObject,
                  T = Object.keys(t);
                if (1 != T.length) throw "key of param shall be only one.";
                var S = T[0];
                if (
                  -1 ==
                  ":bool:int:bitstr:octstr:null:oid:enum:utf8str:numstr:prnstr:telstr:ia5str:utctime:gentime:seq:set:tag:".indexOf(
                    ":" + S + ":"
                  )
                )
                  throw "undefined key: " + S;
                if ("bool" == S) return new i(t[S]);
                if ("int" == S) return new r(t[S]);
                if ("bitstr" == S) return new n(t[S]);
                if ("octstr" == S) return new s(t[S]);
                if ("null" == S) return new o(t[S]);
                if ("oid" == S) return new h(t[S]);
                if ("enum" == S) return new a(t[S]);
                if ("utf8str" == S) return new u(t[S]);
                if ("numstr" == S) return new c(t[S]);
                if ("prnstr" == S) return new f(t[S]);
                if ("telstr" == S) return new l(t[S]);
                if ("ia5str" == S) return new p(t[S]);
                if ("utctime" == S) return new g(t[S]);
                if ("gentime" == S) return new d(t[S]);
                if ("seq" == S) {
                  for (var E = t[S], w = [], D = 0; D < E.length; D++) {
                    var x = b(E[D]);
                    w.push(x);
                  }
                  return new v({ array: w });
                }
                if ("set" == S) {
                  for (E = t[S], w = [], D = 0; D < E.length; D++)
                    (x = b(E[D])), w.push(x);
                  return new m({ array: w });
                }
                if ("tag" == S) {
                  var R = t[S];
                  if (
                    "[object Array]" === Object.prototype.toString.call(R) &&
                    3 == R.length
                  ) {
                    var B = b(R[2]);
                    return new y({ tag: R[0], explicit: R[1], obj: B });
                  }
                  var O = {};
                  if (
                    (void 0 !== R.explicit && (O.explicit = R.explicit),
                    void 0 !== R.tag && (O.tag = R.tag),
                    void 0 === R.obj)
                  )
                    throw "obj shall be specified for 'tag'.";
                  return (O.obj = b(R.obj)), new y(O);
                }
              }),
              (this.jsonToASN1HEX = function (t) {
                return this.newObject(t).getEncodedHex();
              });
          })()),
          (W.asn1.ASN1Util.oidHexToInt = function (t) {
            for (
              var e = "",
                i = parseInt(t.substr(0, 2), 16),
                r = ((e = Math.floor(i / 40) + "." + (i % 40)), ""),
                n = 2;
              n < t.length;
              n += 2
            ) {
              var s = (
                "00000000" + parseInt(t.substr(n, 2), 16).toString(2)
              ).slice(-8);
              (r += s.substr(1, 7)),
                "0" == s.substr(0, 1) &&
                  ((e = e + "." + new R(r, 2).toString(10)), (r = ""));
            }
            return e;
          }),
          (W.asn1.ASN1Util.oidIntToHex = function (t) {
            var e = function (t) {
                var e = t.toString(16);
                return 1 == e.length && (e = "0" + e), e;
              },
              i = function (t) {
                var i = "",
                  r = new R(t, 10).toString(2),
                  n = 7 - (r.length % 7);
                7 == n && (n = 0);
                for (var s = "", o = 0; o < n; o++) s += "0";
                for (r = s + r, o = 0; o < r.length - 1; o += 7) {
                  var h = r.substr(o, 7);
                  o != r.length - 7 && (h = "1" + h), (i += e(parseInt(h, 2)));
                }
                return i;
              };
            if (!t.match(/^[0-9.]+$/)) throw "malformed oid string: " + t;
            var r = "",
              n = t.split("."),
              s = 40 * parseInt(n[0]) + parseInt(n[1]);
            (r += e(s)), n.splice(0, 2);
            for (var o = 0; o < n.length; o++) r += i(n[o]);
            return r;
          }),
          (W.asn1.ASN1Object = function () {
            (this.getLengthHexFromValue = function () {
              if (void 0 === this.hV || null == this.hV)
                throw "this.hV is null or undefined.";
              if (this.hV.length % 2 == 1)
                throw (
                  "value hex must be even length: n=" +
                  "".length +
                  ",v=" +
                  this.hV
                );
              var t = this.hV.length / 2,
                e = t.toString(16);
              if ((e.length % 2 == 1 && (e = "0" + e), t < 128)) return e;
              var i = e.length / 2;
              if (i > 15)
                throw (
                  "ASN.1 length too long to represent by 8x: n = " +
                  t.toString(16)
                );
              return (128 + i).toString(16) + e;
            }),
              (this.getEncodedHex = function () {
                return (
                  (null == this.hTLV || this.isModified) &&
                    ((this.hV = this.getFreshValueHex()),
                    (this.hL = this.getLengthHexFromValue()),
                    (this.hTLV = this.hT + this.hL + this.hV),
                    (this.isModified = !1)),
                  this.hTLV
                );
              }),
              (this.getValueHex = function () {
                return this.getEncodedHex(), this.hV;
              }),
              (this.getFreshValueHex = function () {
                return "";
              });
          }),
          (W.asn1.DERAbstractString = function (t) {
            W.asn1.DERAbstractString.superclass.constructor.call(this),
              (this.getString = function () {
                return this.s;
              }),
              (this.setString = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.s = t),
                  (this.hV = stohex(this.s));
              }),
              (this.setStringHex = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.s = null),
                  (this.hV = t);
              }),
              (this.getFreshValueHex = function () {
                return this.hV;
              }),
              void 0 !== t &&
                ("string" == typeof t
                  ? this.setString(t)
                  : void 0 !== t.str
                  ? this.setString(t.str)
                  : void 0 !== t.hex && this.setStringHex(t.hex));
          }),
          Q.lang.extend(W.asn1.DERAbstractString, W.asn1.ASN1Object),
          (W.asn1.DERAbstractTime = function (t) {
            W.asn1.DERAbstractTime.superclass.constructor.call(this),
              (this.localDateToUTC = function (t) {
                return (
                  (utc = t.getTime() + 6e4 * t.getTimezoneOffset()),
                  new Date(utc)
                );
              }),
              (this.formatDate = function (t, e, i) {
                var r = this.zeroPadding,
                  n = this.localDateToUTC(t),
                  s = String(n.getFullYear());
                "utc" == e && (s = s.substr(2, 2));
                var o =
                  s +
                  r(String(n.getMonth() + 1), 2) +
                  r(String(n.getDate()), 2) +
                  r(String(n.getHours()), 2) +
                  r(String(n.getMinutes()), 2) +
                  r(String(n.getSeconds()), 2);
                if (!0 === i) {
                  var h = n.getMilliseconds();
                  if (0 != h) {
                    var a = r(String(h), 3);
                    o = o + "." + (a = a.replace(/[0]+$/, ""));
                  }
                }
                return o + "Z";
              }),
              (this.zeroPadding = function (t, e) {
                return t.length >= e
                  ? t
                  : new Array(e - t.length + 1).join("0") + t;
              }),
              (this.getString = function () {
                return this.s;
              }),
              (this.setString = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.s = t),
                  (this.hV = stohex(t));
              }),
              (this.setByDateValue = function (t, e, i, r, n, s) {
                var o = new Date(Date.UTC(t, e - 1, i, r, n, s, 0));
                this.setByDate(o);
              }),
              (this.getFreshValueHex = function () {
                return this.hV;
              });
          }),
          Q.lang.extend(W.asn1.DERAbstractTime, W.asn1.ASN1Object),
          (W.asn1.DERAbstractStructured = function (t) {
            W.asn1.DERAbstractString.superclass.constructor.call(this),
              (this.setByASN1ObjectArray = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.asn1Array = t);
              }),
              (this.appendASN1Object = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  this.asn1Array.push(t);
              }),
              (this.asn1Array = new Array()),
              void 0 !== t && void 0 !== t.array && (this.asn1Array = t.array);
          }),
          Q.lang.extend(W.asn1.DERAbstractStructured, W.asn1.ASN1Object),
          (W.asn1.DERBoolean = function () {
            W.asn1.DERBoolean.superclass.constructor.call(this),
              (this.hT = "01"),
              (this.hTLV = "0101ff");
          }),
          Q.lang.extend(W.asn1.DERBoolean, W.asn1.ASN1Object),
          (W.asn1.DERInteger = function (t) {
            W.asn1.DERInteger.superclass.constructor.call(this),
              (this.hT = "02"),
              (this.setByBigInteger = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.hV = W.asn1.ASN1Util.bigIntToMinTwosComplementsHex(t));
              }),
              (this.setByInteger = function (t) {
                var e = new R(String(t), 10);
                this.setByBigInteger(e);
              }),
              (this.setValueHex = function (t) {
                this.hV = t;
              }),
              (this.getFreshValueHex = function () {
                return this.hV;
              }),
              void 0 !== t &&
                (void 0 !== t.bigint
                  ? this.setByBigInteger(t.bigint)
                  : void 0 !== t.int
                  ? this.setByInteger(t.int)
                  : "number" == typeof t
                  ? this.setByInteger(t)
                  : void 0 !== t.hex && this.setValueHex(t.hex));
          }),
          Q.lang.extend(W.asn1.DERInteger, W.asn1.ASN1Object),
          (W.asn1.DERBitString = function (t) {
            if (void 0 !== t && void 0 !== t.obj) {
              var e = W.asn1.ASN1Util.newObject(t.obj);
              t.hex = "00" + e.getEncodedHex();
            }
            W.asn1.DERBitString.superclass.constructor.call(this),
              (this.hT = "03"),
              (this.setHexValueIncludingUnusedBits = function (t) {
                (this.hTLV = null), (this.isModified = !0), (this.hV = t);
              }),
              (this.setUnusedBitsAndHexValue = function (t, e) {
                if (t < 0 || 7 < t)
                  throw "unused bits shall be from 0 to 7: u = " + t;
                var i = "0" + t;
                (this.hTLV = null), (this.isModified = !0), (this.hV = i + e);
              }),
              (this.setByBinaryString = function (t) {
                var e = 8 - ((t = t.replace(/0+$/, "")).length % 8);
                8 == e && (e = 0);
                for (var i = 0; i <= e; i++) t += "0";
                var r = "";
                for (i = 0; i < t.length - 1; i += 8) {
                  var n = t.substr(i, 8),
                    s = parseInt(n, 2).toString(16);
                  1 == s.length && (s = "0" + s), (r += s);
                }
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.hV = "0" + e + r);
              }),
              (this.setByBooleanArray = function (t) {
                for (var e = "", i = 0; i < t.length; i++)
                  1 == t[i] ? (e += "1") : (e += "0");
                this.setByBinaryString(e);
              }),
              (this.newFalseArray = function (t) {
                for (var e = new Array(t), i = 0; i < t; i++) e[i] = !1;
                return e;
              }),
              (this.getFreshValueHex = function () {
                return this.hV;
              }),
              void 0 !== t &&
                ("string" == typeof t && t.toLowerCase().match(/^[0-9a-f]+$/)
                  ? this.setHexValueIncludingUnusedBits(t)
                  : void 0 !== t.hex
                  ? this.setHexValueIncludingUnusedBits(t.hex)
                  : void 0 !== t.bin
                  ? this.setByBinaryString(t.bin)
                  : void 0 !== t.array && this.setByBooleanArray(t.array));
          }),
          Q.lang.extend(W.asn1.DERBitString, W.asn1.ASN1Object),
          (W.asn1.DEROctetString = function (t) {
            if (void 0 !== t && void 0 !== t.obj) {
              var e = W.asn1.ASN1Util.newObject(t.obj);
              t.hex = e.getEncodedHex();
            }
            W.asn1.DEROctetString.superclass.constructor.call(this, t),
              (this.hT = "04");
          }),
          Q.lang.extend(W.asn1.DEROctetString, W.asn1.DERAbstractString),
          (W.asn1.DERNull = function () {
            W.asn1.DERNull.superclass.constructor.call(this),
              (this.hT = "05"),
              (this.hTLV = "0500");
          }),
          Q.lang.extend(W.asn1.DERNull, W.asn1.ASN1Object),
          (W.asn1.DERObjectIdentifier = function (t) {
            var e = function (t) {
                var e = t.toString(16);
                return 1 == e.length && (e = "0" + e), e;
              },
              i = function (t) {
                var i = "",
                  r = new R(t, 10).toString(2),
                  n = 7 - (r.length % 7);
                7 == n && (n = 0);
                for (var s = "", o = 0; o < n; o++) s += "0";
                for (r = s + r, o = 0; o < r.length - 1; o += 7) {
                  var h = r.substr(o, 7);
                  o != r.length - 7 && (h = "1" + h), (i += e(parseInt(h, 2)));
                }
                return i;
              };
            W.asn1.DERObjectIdentifier.superclass.constructor.call(this),
              (this.hT = "06"),
              (this.setValueHex = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.s = null),
                  (this.hV = t);
              }),
              (this.setValueOidString = function (t) {
                if (!t.match(/^[0-9.]+$/)) throw "malformed oid string: " + t;
                var r = "",
                  n = t.split("."),
                  s = 40 * parseInt(n[0]) + parseInt(n[1]);
                (r += e(s)), n.splice(0, 2);
                for (var o = 0; o < n.length; o++) r += i(n[o]);
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.s = null),
                  (this.hV = r);
              }),
              (this.setValueName = function (t) {
                var e = W.asn1.x509.OID.name2oid(t);
                if ("" === e)
                  throw "DERObjectIdentifier oidName undefined: " + t;
                this.setValueOidString(e);
              }),
              (this.getFreshValueHex = function () {
                return this.hV;
              }),
              void 0 !== t &&
                ("string" == typeof t
                  ? t.match(/^[0-2].[0-9.]+$/)
                    ? this.setValueOidString(t)
                    : this.setValueName(t)
                  : void 0 !== t.oid
                  ? this.setValueOidString(t.oid)
                  : void 0 !== t.hex
                  ? this.setValueHex(t.hex)
                  : void 0 !== t.name && this.setValueName(t.name));
          }),
          Q.lang.extend(W.asn1.DERObjectIdentifier, W.asn1.ASN1Object),
          (W.asn1.DEREnumerated = function (t) {
            W.asn1.DEREnumerated.superclass.constructor.call(this),
              (this.hT = "0a"),
              (this.setByBigInteger = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.hV = W.asn1.ASN1Util.bigIntToMinTwosComplementsHex(t));
              }),
              (this.setByInteger = function (t) {
                var e = new R(String(t), 10);
                this.setByBigInteger(e);
              }),
              (this.setValueHex = function (t) {
                this.hV = t;
              }),
              (this.getFreshValueHex = function () {
                return this.hV;
              }),
              void 0 !== t &&
                (void 0 !== t.int
                  ? this.setByInteger(t.int)
                  : "number" == typeof t
                  ? this.setByInteger(t)
                  : void 0 !== t.hex && this.setValueHex(t.hex));
          }),
          Q.lang.extend(W.asn1.DEREnumerated, W.asn1.ASN1Object),
          (W.asn1.DERUTF8String = function (t) {
            W.asn1.DERUTF8String.superclass.constructor.call(this, t),
              (this.hT = "0c");
          }),
          Q.lang.extend(W.asn1.DERUTF8String, W.asn1.DERAbstractString),
          (W.asn1.DERNumericString = function (t) {
            W.asn1.DERNumericString.superclass.constructor.call(this, t),
              (this.hT = "12");
          }),
          Q.lang.extend(W.asn1.DERNumericString, W.asn1.DERAbstractString),
          (W.asn1.DERPrintableString = function (t) {
            W.asn1.DERPrintableString.superclass.constructor.call(this, t),
              (this.hT = "13");
          }),
          Q.lang.extend(W.asn1.DERPrintableString, W.asn1.DERAbstractString),
          (W.asn1.DERTeletexString = function (t) {
            W.asn1.DERTeletexString.superclass.constructor.call(this, t),
              (this.hT = "14");
          }),
          Q.lang.extend(W.asn1.DERTeletexString, W.asn1.DERAbstractString),
          (W.asn1.DERIA5String = function (t) {
            W.asn1.DERIA5String.superclass.constructor.call(this, t),
              (this.hT = "16");
          }),
          Q.lang.extend(W.asn1.DERIA5String, W.asn1.DERAbstractString),
          (W.asn1.DERUTCTime = function (t) {
            W.asn1.DERUTCTime.superclass.constructor.call(this, t),
              (this.hT = "17"),
              (this.setByDate = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.date = t),
                  (this.s = this.formatDate(this.date, "utc")),
                  (this.hV = stohex(this.s));
              }),
              (this.getFreshValueHex = function () {
                return (
                  void 0 === this.date &&
                    void 0 === this.s &&
                    ((this.date = new Date()),
                    (this.s = this.formatDate(this.date, "utc")),
                    (this.hV = stohex(this.s))),
                  this.hV
                );
              }),
              void 0 !== t &&
                (void 0 !== t.str
                  ? this.setString(t.str)
                  : "string" == typeof t && t.match(/^[0-9]{12}Z$/)
                  ? this.setString(t)
                  : void 0 !== t.hex
                  ? this.setStringHex(t.hex)
                  : void 0 !== t.date && this.setByDate(t.date));
          }),
          Q.lang.extend(W.asn1.DERUTCTime, W.asn1.DERAbstractTime),
          (W.asn1.DERGeneralizedTime = function (t) {
            W.asn1.DERGeneralizedTime.superclass.constructor.call(this, t),
              (this.hT = "18"),
              (this.withMillis = !1),
              (this.setByDate = function (t) {
                (this.hTLV = null),
                  (this.isModified = !0),
                  (this.date = t),
                  (this.s = this.formatDate(this.date, "gen", this.withMillis)),
                  (this.hV = stohex(this.s));
              }),
              (this.getFreshValueHex = function () {
                return (
                  void 0 === this.date &&
                    void 0 === this.s &&
                    ((this.date = new Date()),
                    (this.s = this.formatDate(
                      this.date,
                      "gen",
                      this.withMillis
                    )),
                    (this.hV = stohex(this.s))),
                  this.hV
                );
              }),
              void 0 !== t &&
                (void 0 !== t.str
                  ? this.setString(t.str)
                  : "string" == typeof t && t.match(/^[0-9]{14}Z$/)
                  ? this.setString(t)
                  : void 0 !== t.hex
                  ? this.setStringHex(t.hex)
                  : void 0 !== t.date && this.setByDate(t.date),
                !0 === t.millis && (this.withMillis = !0));
          }),
          Q.lang.extend(W.asn1.DERGeneralizedTime, W.asn1.DERAbstractTime),
          (W.asn1.DERSequence = function (t) {
            W.asn1.DERSequence.superclass.constructor.call(this, t),
              (this.hT = "30"),
              (this.getFreshValueHex = function () {
                for (var t = "", e = 0; e < this.asn1Array.length; e++)
                  t += this.asn1Array[e].getEncodedHex();
                return (this.hV = t), this.hV;
              });
          }),
          Q.lang.extend(W.asn1.DERSequence, W.asn1.DERAbstractStructured),
          (W.asn1.DERSet = function (t) {
            W.asn1.DERSet.superclass.constructor.call(this, t),
              (this.hT = "31"),
              (this.sortFlag = !0),
              (this.getFreshValueHex = function () {
                for (
                  var t = new Array(), e = 0;
                  e < this.asn1Array.length;
                  e++
                ) {
                  var i = this.asn1Array[e];
                  t.push(i.getEncodedHex());
                }
                return (
                  1 == this.sortFlag && t.sort(),
                  (this.hV = t.join("")),
                  this.hV
                );
              }),
              void 0 !== t &&
                void 0 !== t.sortflag &&
                0 == t.sortflag &&
                (this.sortFlag = !1);
          }),
          Q.lang.extend(W.asn1.DERSet, W.asn1.DERAbstractStructured),
          (W.asn1.DERTaggedObject = function (t) {
            W.asn1.DERTaggedObject.superclass.constructor.call(this),
              (this.hT = "a0"),
              (this.hV = ""),
              (this.isExplicit = !0),
              (this.asn1Object = null),
              (this.setASN1Object = function (t, e, i) {
                (this.hT = e),
                  (this.isExplicit = t),
                  (this.asn1Object = i),
                  this.isExplicit
                    ? ((this.hV = this.asn1Object.getEncodedHex()),
                      (this.hTLV = null),
                      (this.isModified = !0))
                    : ((this.hV = null),
                      (this.hTLV = i.getEncodedHex()),
                      (this.hTLV = this.hTLV.replace(/^../, e)),
                      (this.isModified = !1));
              }),
              (this.getFreshValueHex = function () {
                return this.hV;
              }),
              void 0 !== t &&
                (void 0 !== t.tag && (this.hT = t.tag),
                void 0 !== t.explicit && (this.isExplicit = t.explicit),
                void 0 !== t.obj &&
                  ((this.asn1Object = t.obj),
                  this.setASN1Object(
                    this.isExplicit,
                    this.hT,
                    this.asn1Object
                  )));
          }),
          Q.lang.extend(W.asn1.DERTaggedObject, W.asn1.ASN1Object);
        var tt,
          et,
          it =
            ((tt = function (t, e) {
              return (
                (tt =
                  Object.setPrototypeOf ||
                  ({ __proto__: [] } instanceof Array &&
                    function (t, e) {
                      t.__proto__ = e;
                    }) ||
                  function (t, e) {
                    for (var i in e)
                      Object.prototype.hasOwnProperty.call(e, i) &&
                        (t[i] = e[i]);
                  }),
                tt(t, e)
              );
            }),
            function (t, e) {
              if ("function" != typeof e && null !== e)
                throw new TypeError(
                  "Class extends value " +
                    String(e) +
                    " is not a constructor or null"
                );
              function i() {
                this.constructor = t;
              }
              tt(t, e),
                (t.prototype =
                  null === e
                    ? Object.create(e)
                    : ((i.prototype = e.prototype), new i()));
            }),
          rt = (function (t) {
            function e(i) {
              var r = t.call(this) || this;
              return (
                i &&
                  ("string" == typeof i
                    ? r.parseKey(i)
                    : (e.hasPrivateKeyProperty(i) ||
                        e.hasPublicKeyProperty(i)) &&
                      r.parsePropertiesFrom(i)),
                r
              );
            }
            return (
              it(e, t),
              (e.prototype.parseKey = function (t) {
                try {
                  var e = 0,
                    i = 0,
                    r = /^\s*(?:[0-9A-Fa-f][0-9A-Fa-f]\s*)+$/.test(t)
                      ? (function (t) {
                          var e;
                          if (void 0 === u) {
                            var i = "0123456789ABCDEF",
                              r = " \f\n\r\t \u2028\u2029";
                            for (u = {}, e = 0; e < 16; ++e) u[i.charAt(e)] = e;
                            for (i = i.toLowerCase(), e = 10; e < 16; ++e)
                              u[i.charAt(e)] = e;
                            for (e = 0; e < r.length; ++e) u[r.charAt(e)] = -1;
                          }
                          var n = [],
                            s = 0,
                            o = 0;
                          for (e = 0; e < t.length; ++e) {
                            var h = t.charAt(e);
                            if ("=" == h) break;
                            if (-1 != (h = u[h])) {
                              if (void 0 === h)
                                throw new Error(
                                  "Illegal character at offset " + e
                                );
                              (s |= h),
                                ++o >= 2
                                  ? ((n[n.length] = s), (s = 0), (o = 0))
                                  : (s <<= 4);
                            }
                          }
                          if (o)
                            throw new Error(
                              "Hex encoding incomplete: 4 bits missing"
                            );
                          return n;
                        })(t)
                      : g.unarmor(t),
                    n = E.decode(r);
                  if (
                    (3 === n.sub.length && (n = n.sub[2].sub[0]),
                    9 === n.sub.length)
                  ) {
                    (e = n.sub[1].getHexStringValue()),
                      (this.n = N(e, 16)),
                      (i = n.sub[2].getHexStringValue()),
                      (this.e = parseInt(i, 16));
                    var s = n.sub[3].getHexStringValue();
                    this.d = N(s, 16);
                    var o = n.sub[4].getHexStringValue();
                    this.p = N(o, 16);
                    var h = n.sub[5].getHexStringValue();
                    this.q = N(h, 16);
                    var a = n.sub[6].getHexStringValue();
                    this.dmp1 = N(a, 16);
                    var c = n.sub[7].getHexStringValue();
                    this.dmq1 = N(c, 16);
                    var f = n.sub[8].getHexStringValue();
                    this.coeff = N(f, 16);
                  } else {
                    if (2 !== n.sub.length) return !1;
                    if (n.sub[0].sub) {
                      var l = n.sub[1].sub[0];
                      (e = l.sub[0].getHexStringValue()),
                        (this.n = N(e, 16)),
                        (i = l.sub[1].getHexStringValue()),
                        (this.e = parseInt(i, 16));
                    } else
                      (e = n.sub[0].getHexStringValue()),
                        (this.n = N(e, 16)),
                        (i = n.sub[1].getHexStringValue()),
                        (this.e = parseInt(i, 16));
                  }
                  return !0;
                } catch (t) {
                  return !1;
                }
              }),
              (e.prototype.getPrivateBaseKey = function () {
                var t = {
                  array: [
                    new W.asn1.DERInteger({ int: 0 }),
                    new W.asn1.DERInteger({ bigint: this.n }),
                    new W.asn1.DERInteger({ int: this.e }),
                    new W.asn1.DERInteger({ bigint: this.d }),
                    new W.asn1.DERInteger({ bigint: this.p }),
                    new W.asn1.DERInteger({ bigint: this.q }),
                    new W.asn1.DERInteger({ bigint: this.dmp1 }),
                    new W.asn1.DERInteger({ bigint: this.dmq1 }),
                    new W.asn1.DERInteger({ bigint: this.coeff }),
                  ],
                };
                return new W.asn1.DERSequence(t).getEncodedHex();
              }),
              (e.prototype.getPrivateBaseKeyB64 = function () {
                return f(this.getPrivateBaseKey());
              }),
              (e.prototype.getPublicBaseKey = function () {
                var t = new W.asn1.DERSequence({
                    array: [
                      new W.asn1.DERObjectIdentifier({
                        oid: "1.2.840.113549.1.1.1",
                      }),
                      new W.asn1.DERNull(),
                    ],
                  }),
                  e = new W.asn1.DERSequence({
                    array: [
                      new W.asn1.DERInteger({ bigint: this.n }),
                      new W.asn1.DERInteger({ int: this.e }),
                    ],
                  }),
                  i = new W.asn1.DERBitString({
                    hex: "00" + e.getEncodedHex(),
                  });
                return new W.asn1.DERSequence({
                  array: [t, i],
                }).getEncodedHex();
              }),
              (e.prototype.getPublicBaseKeyB64 = function () {
                return f(this.getPublicBaseKey());
              }),
              (e.wordwrap = function (t, e) {
                if (!t) return t;
                var i =
                  "(.{1," + (e = e || 64) + "})( +|$\n?)|(.{1," + e + "})";
                return t.match(RegExp(i, "g")).join("\n");
              }),
              (e.prototype.getPrivateKey = function () {
                var t = "-----BEGIN RSA PRIVATE KEY-----\n";
                return (
                  (t += e.wordwrap(this.getPrivateBaseKeyB64()) + "\n") +
                  "-----END RSA PRIVATE KEY-----"
                );
              }),
              (e.prototype.getPublicKey = function () {
                var t = "-----BEGIN PUBLIC KEY-----\n";
                return (
                  (t += e.wordwrap(this.getPublicBaseKeyB64()) + "\n") +
                  "-----END PUBLIC KEY-----"
                );
              }),
              (e.hasPublicKeyProperty = function (t) {
                return (
                  (t = t || {}).hasOwnProperty("n") && t.hasOwnProperty("e")
                );
              }),
              (e.hasPrivateKeyProperty = function (t) {
                return (
                  (t = t || {}).hasOwnProperty("n") &&
                  t.hasOwnProperty("e") &&
                  t.hasOwnProperty("d") &&
                  t.hasOwnProperty("p") &&
                  t.hasOwnProperty("q") &&
                  t.hasOwnProperty("dmp1") &&
                  t.hasOwnProperty("dmq1") &&
                  t.hasOwnProperty("coeff")
                );
              }),
              (e.prototype.parsePropertiesFrom = function (t) {
                (this.n = t.n),
                  (this.e = t.e),
                  t.hasOwnProperty("d") &&
                    ((this.d = t.d),
                    (this.p = t.p),
                    (this.q = t.q),
                    (this.dmp1 = t.dmp1),
                    (this.dmq1 = t.dmq1),
                    (this.coeff = t.coeff));
              }),
              e
            );
          })(J),
          nt = i(155),
          st =
            void 0 !== nt
              ? null === (et = nt.env) || void 0 === et
                ? void 0
                : "3.3.1"
              : void 0;
        const ot = (function () {
          function t(t) {
            void 0 === t && (t = {}),
              (t = t || {}),
              (this.default_key_size = t.default_key_size
                ? parseInt(t.default_key_size, 10)
                : 1024),
              (this.default_public_exponent =
                t.default_public_exponent || "010001"),
              (this.log = t.log || !1),
              (this.key = null);
          }
          return (
            (t.prototype.setKey = function (t) {
              this.log &&
                this.key &&
                console.warn("A key was already set, overriding existing."),
                (this.key = new rt(t));
            }),
            (t.prototype.setPrivateKey = function (t) {
              this.setKey(t);
            }),
            (t.prototype.setPublicKey = function (t) {
              this.setKey(t);
            }),
            (t.prototype.decrypt = function (t) {
              try {
                return this.getKey().decrypt(l(t));
              } catch (t) {
                return !1;
              }
            }),
            (t.prototype.encrypt = function (t) {
              try {
                return f(this.getKey().encrypt(t));
              } catch (t) {
                return !1;
              }
            }),
            (t.prototype.sign = function (t, e, i) {
              try {
                return f(this.getKey().sign(t, e, i));
              } catch (t) {
                return !1;
              }
            }),
            (t.prototype.verify = function (t, e, i) {
              try {
                return this.getKey().verify(t, l(e), i);
              } catch (t) {
                return !1;
              }
            }),
            (t.prototype.getKey = function (t) {
              if (!this.key) {
                if (
                  ((this.key = new rt()),
                  t && "[object Function]" === {}.toString.call(t))
                )
                  return void this.key.generateAsync(
                    this.default_key_size,
                    this.default_public_exponent,
                    t
                  );
                this.key.generate(
                  this.default_key_size,
                  this.default_public_exponent
                );
              }
              return this.key;
            }),
            (t.prototype.getPrivateKey = function () {
              return this.getKey().getPrivateKey();
            }),
            (t.prototype.getPrivateKeyB64 = function () {
              return this.getKey().getPrivateBaseKeyB64();
            }),
            (t.prototype.getPublicKey = function () {
              return this.getKey().getPublicKey();
            }),
            (t.prototype.getPublicKeyB64 = function () {
              return this.getKey().getPublicBaseKeyB64();
            }),
            (t.version = st),
            t
          );
        })();
      })(),
      r.default
    );
  })()
);
