import { Alert, Button, ButtonBorder, Div, ImageView, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React, { useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { apiMain } from "services/apiMain";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { PostMSG, parseMultilanguage } from "utils/utils";

function ComReSubmit() {
  const insets = useSafeAreaInsets();
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const draftQuestion = useAppSelector((state) => state.authen.draftQuestion);
  const [loading, setLoading] = useState<boolean>(false);
  const onReSubmit = async () => {
    await setLoading(true);
    try {
      const res = await apiMain.submitQuestion(draftQuestion);
      await setTimeout(() => {}, 300);
      if (res.status == 200) {
        PostMSG("RiskQuestionSuccess", {});
        PostMSG("CreateEsignSuccess", {});
        navigate("ResultModal");
        Alert.show({
          content: `alert.capnhatkhaosatruirothanhcong`,
          type: 2,
          titleClose: `alert.dong`,
        });
        return;
      }
      Alert.showError({
        content: res.message,
        type: 2,
        multilanguage: false,
        onConfirm: () => {
          navigate("ResultModal");
        },
        title: `alert.thatbai`,
      });
      return;
    } catch (error: any) {
      Alert.showError({
        content: error.message,
        type: 2,
        multilanguage: false,
        onClose: () => {},
        title: `alert.thatbai`,
        onConfirm: () => {
          navigate("ResultModal");
        },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Div
      paddingBottom={insets.bottom + 10}
      width={"100%"}
      paddingHorizontal={19}
      alignItems="center"
    >
      <Div
        flexDirection="row"
        paddingBottom={20}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Button
          widthHeight={25}
          onPress={() => {
            setIsAccept((a) => !a);
          }}
          marginRight={13}
          borderWidth={1}
          alignItems={"center"}
          justifyContent={"center"}
          borderColor={isAccept ? Ecolors.mainColor : Ecolors.spaceColor}
          borderRadius={25}
        >
          <ImageView
            source={isAccept ? Icons.check : Icons.uncheck}
            widthHeight={20}
            tintColor={isAccept ? Ecolors.mainColor : Ecolors.grayColor}
          />
        </Button>
        <Label multilanguage={false} size={14}>
          {parseMultilanguage(`riskquestionscreen.voiviecchononnay`)}
          <Label
            onPress={() => {
              navigate("RiskContentModal");
            }}
            color={Ecolors.linkColor}
            size={14}
          >{`riskquestionscreen.toixacnhan`}</Label>
        </Label>
      </Div>
      <ButtonBorder
        isDisable={!isAccept}
        type={1}
        loading={loading}
        onPress={() => {
          onReSubmit();
        }}
        title={`riskquestionscreen.hoantat`}
        height={48}
      />
    </Div>
  );
}

export default React.memo(ComReSubmit);
