import { Div, HeaderBack } from "components";
import { Ecolors } from "constants/themes";
import React, { useState } from "react";
import { ScrollView } from "react-native";
import { useAppSelector } from "store/hooks";
import CardSignature from "./CardSignature";
import RowButtonAction from "./RowButtonAction";

function DigitalSignatureScreen() {
  const currentUser = useAppSelector((state) => state.authen.currentUser);
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const { investmentProfile } = currentUser;

  return (
    <Div backgroundColor={Ecolors.whiteColor} height={"100%"}>
      <HeaderBack type={2} title={`digitalsignature.hopdongdientu`} />
      <ScrollView>
        <Div alignItems={"center"} flex={1}>
          <CardSignature isAccept={isAccept} setIsAccept={setIsAccept} />
        </Div>
      </ScrollView>
      {isAccept && <RowButtonAction />}
    </Div>
  );
}

export default React.memo(DigitalSignatureScreen);
