export function Log(t?: string | object, r?: any) {
  if (__DEV__) {
    if (typeof t == "string") {
      return console.log(t, r || "");
    } else if (typeof t == "object") {
      return console.log(t);
    } else {
      return console.log(t || "", r || "");
    }
  }
  return;
}
