import { Alert, ButtonBorder, Div, HeaderBack, Label } from "components";
import React, { useEffect, useState } from "react";
import { Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import { loadAsset, loadProduct } from "reducer/asset";
import { draftQuestion, getInfo, getQuestion } from "reducer/authen";
import { apiMain } from "services/apiMain";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { setStoreData } from "utils";
import { detectLinkData } from "utils/utils";
import ComQuestion from "./ComQuestion";

const ObjContent2 = {
  content: `Bắt đầu thực hiện trả lời khảo sát`,
  id: "selectDoSomeThing",
};

function RiskQuestionScreen() {
  const appetites = useAppSelector(
    (state) => state.authen.question?.appetites || []
  );
  const insets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const [selectAppetites, setSelectAppetites] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const LinkCredit = React.useRef<string>("");

  useEffect(() => {
    // getData();
    // dispatch(getQuestion({}));
    bindDataUrl();
    return () => {};
  }, []);

  const bindDataUrl = async () => {
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        LinkCredit.current = r;
        setStoreData("idNo", obj?.idNo || "");
        setStoreData("phone", obj?.phone || "");
        setStoreData("refCode", obj?.refCode || "");
        setStoreData("bankId", obj?.bankId || "");
        setStoreData("bankNumber", obj?.bankNumber || "");
        setStoreData("productId", obj?.productId || "");
        setStoreData("mToken", obj?.token || "");
        setStoreData("idCode", obj?.idCode || "");
      })
      .catch((err: any) => {})
      .finally(() => {
        if (
          LinkCredit.current.includes("/Register") ||
          LinkCredit.current.includes("/Login")
        ) {
          return;
        }
        dispatch(getInfo({}));
        dispatch(getQuestion({}));
        dispatch(loadProduct({}));
        dispatch(loadAsset({}));
        getData();
      });
  };

  const getData = async () => {
    // dispatch(getQuestion({}));
    const result = await apiMain.getResult();
    if (result.status == 200 && result.data && !!result.data.appetites) {
      navigate("ResultModal");
    }
    return;
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      const obj = {
        appetiteId: selectAppetites?.id,
        questionResults: [],
        isSave: false,
      };
      const t: any = await apiMain.submitQuestion(obj);
      if (t.status == 200) {
        dispatch(draftQuestion(obj));
        navigate("ResultModal", {
          flow: "Submit",
          data: t.data,
        });
        return;
      }
      Alert.showError({
        content: t.message,
        type: 2,
        multilanguage: false,
        onConfirm: () => {
          navigate("ResultModal");
        },
        title: `alert.thatbai`,
      });
      return;
    } catch (error: any) {
      Alert.showError({
        content: error.message,
        type: 2,
        multilanguage: false,
        onClose: () => {},
        title: `alert.thatbai`,
        onConfirm: () => {
          navigate("ResultModal");
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div screen={true}>
      <HeaderBack
        isShowStep={true}
        title={`riskquestionscreen.bangkhaosatkhauviruiro`}
      />
      <ScrollView showsVerticalScrollIndicator={false}>
        <Div padding={16}>
          <Label>{`riskquestionscreen.content1`}</Label>
          {!!appetites &&
            appetites?.map((item: any, index: number) => {
              return (
                <Div paddingTop={13} key={index}>
                  <ComQuestion
                    data={item}
                    onPress={(t) => {
                      setSelectAppetites(item);
                    }}
                    isSelect={selectAppetites?.id == item.id}
                    type={1}
                  />
                </Div>
              );
            })}
          <Div height={13} />
          <Label>{`riskquestionscreen.content2`}</Label>
          <Div height={13} />
          <ComQuestion
            data={ObjContent2}
            onPress={(t) => {
              setSelectAppetites(ObjContent2);
            }}
            isSelect={selectAppetites?.id == ObjContent2.id}
            type={2}
          />
          <Div height={200} />
        </Div>
      </ScrollView>
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        marginTop={10}
        marginBottom={insets.bottom + 20}
        paddingHorizontal={29}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <ButtonBorder
          loading={loading}
          isDisable={!selectAppetites}
          onPress={() => {
            if (selectAppetites?.id == `selectDoSomeThing`) {
              navigate("EditRiskQuestionModal");
              return;
            }
            submitForm();
            return;
          }}
          height={48}
          title={`riskquestionscreen.xacnhan`}
          type={1}
        />
      </Div>
    </Div>
  );
}

export default React.memo(RiskQuestionScreen);
