import {
  BottomSheetDialog,
  Button,
  Div,
  ImageView,
  Label,
  LoadingIndicator,
  Toast,
} from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React, { useEffect, useRef, useState } from "react";
import { doUploadFileSignature } from "screens/DigitalSignature/func";
import { uploadFile } from "services/uploadFile";
import { useAppSelector } from "store/hooks";
import { getImageCamera, getImageLibrary } from "utils/utils";
import ComActionUpload from "./ComActionUpload";
import SignatureDraw from "./SignatureDraw";

const Btn = (p: {
  type: 1 | 2;
  title: string;
  icon: any;
  onPress: () => void;
}) => {
  return (
    <Button
      onPress={() => {
        p.onPress();
      }}
      width={166}
      height={48}
      flexDirection={"row"}
      alignItems={"center"}
      backgroundColor={p.type == 1 ? Ecolors.grayColor : Ecolors.mainColor}
      justifyContent={"center"}
      borderRadius={8}
    >
      <ImageView widthHeight={20} resizeMode={"contain"} source={p.icon} />
      <Label
        marginLeft={7}
        fontWeight={"500"}
        color={p.type == 1 ? Ecolors.textColor : Ecolors.whiteColor}
      >
        {p.title}
      </Label>
    </Button>
  );
};

function RowButtonAction() {
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const bottomSheetUpload = useRef<any>(null);
  const bottomSheetDraw = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [stateimage, setStateImage] = useState<any>(null);

  const hide = (cb?: () => void) => {
    if (bottomSheetUpload.current) {
      bottomSheetUpload.current.hide().then(() => {
        cb && cb();
      });
    }
  };

  useEffect(() => {
    // getAccountToken();
    return () => {};
  }, []);

  // const getAccountToken = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await apiAuth.login({
  //       username: "341240111",
  //       password: "Tokimhong1",
  //     });
  //     if (res.status == 200) {
  //       await setStoreToken(res.data.access_token);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      {loading && (
        <Div
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          zIndex={99999}
          elevation={99999}
          backgroundColor={Ecolors.transparentLoading}
          position={"absolute"}
        >
          <LoadingIndicator color={Ecolors.mainColor} />
        </Div>
      )}
      {/* <ImageView
        widthHeight={100}
        source={{
          uri: stateimage?.uri || '',
        }}
      /> */}

      <BottomSheetDialog
        style={{
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        ref={bottomSheetDraw}
      >
        <SignatureDraw
          onHide={() => {
            bottomSheetDraw.current.hide();
          }}
        />
      </BottomSheetDialog>

      <BottomSheetDialog
        style={{
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        ref={bottomSheetUpload}
      >
        <ComActionUpload
          onCancel={() => {
            hide();
          }}
          onCamera={async () => {
            try {
              hide(async () => {
                await getImageCamera().then(async (image: any) => {
                  if (image[0]) {
                    const r: any = await uploadFile({
                      fileBase64: image[0].base64,
                    });
                    doUploadFileSignature({
                      link: r,
                      I18nState: I18nState,
                      setLoading: setLoading,
                    });
                  }
                });
              });
            } catch (error) {
              console.log("image", error);
              if (!!error) {
                Toast.show({
                  content: "alert.daxayraloi",
                  multilanguage: true,
                });
              }
            } finally {
              // hide(async () => {});
            }
          }}
          onGallery={async () => {
            try {
              hide(async () => {
                await getImageLibrary().then(async (image: any) => {
                  if (image[0]) {
                    const r: any = await uploadFile({
                      fileBase64: image[0].base64,
                    });
                    doUploadFileSignature({
                      link: image[0].uri,
                      I18nState: I18nState,
                      setLoading: setLoading,
                    });
                  }
                });
              });
            } catch (error) {
              if (!!error) {
                Toast.show({
                  content: "alert.daxayraloi",
                  multilanguage: true,
                });
                return;
              }
            } finally {
              // hide(async () => {});
            }
          }}
        />
      </BottomSheetDialog>
      <Div
        flexDirection={"row"}
        paddingHorizontal={16}
        width={"100%"}
        alignItems={"center"}
        // justifyContent={"center"}
        justifyContent={"space-between"}
        marginTop={18}
        marginBottom={80}
      >
        <Btn
          onPress={() => {
            //   call action get image from library
            bottomSheetUpload.current.show();
          }}
          type={1}
          title={`digitalsignature.taichuky`}
          icon={Icons.camera}
        />
        <Btn
          onPress={() => {
            // navigate("SignatureDraw");
            bottomSheetDraw.current.show();
          }}
          type={2}
          title={`digitalsignature.kydientu`}
          icon={Icons.signature}
        />
      </Div>
    </>
  );
}

export default React.memo(RowButtonAction);
