import * as React from "react";
import { StackName } from "utils";

export const navigationRef: any = React.createRef();
const timeoutref: any = React.createRef();

export function navigate(
  name: StackName,
  params?: object,
  cancelDebounce?: boolean
) {
  //   if (name == "AlertModal") {
  //     navigationRef.current?.navigate(name, params);
  //     return;
  //   }
  if (timeoutref.current && !cancelDebounce) {
    return;
  }
  if (__DEV__) {
    console.log("SCEEN:", name);
  }
  navigationRef.current?.navigate(name, params);
  timeoutref.current = setTimeout(() => {
    clearTimeout(timeoutref.current);
    timeoutref.current = null;
  }, 0);
}
export function goBack() {
  return new Promise((rs, rj) => {
    if (navigationRef.current) {
      navigationRef.current?.goBack();
    }
    rs("");
    return "";
  });
}
