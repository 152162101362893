import { Alert, Button, Div, InputItem, Label } from "components";
import ButtonBorder from "components/ButtonBorder";
import ImageView from "components/ImageView";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React, { useRef, useState } from "react";
import { ScrollView } from "react-native";
import { useDispatch } from "react-redux";
import { getInfo } from "reducer/authen";
import { apiAuth } from "services/api";
import { navigate } from "services/navigation";
import { Log, setStoreToken, widthScale, widthScreen } from "utils";
import { parseMultilanguage } from "utils/utils";

function LoginScreen() {
  //
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isSaveName, setIsSaveName] = useState<boolean>(false);
  //
  const [biometryType, setBioMetryType] = useState<string>("");
  const [isSupported, setIsSupported] = useState<boolean>(false);
  const [isHaveAccount, setIsHaveAccount] = useState<boolean>(false);
  const [isBio, setIsBio] = useState<boolean>(false);
  const dispatch = useDispatch();
  //
  const passwordRef = useRef<any>(null);

  const doLogin = async () => {
    try {
      const res = await apiAuth.login({
        username,
        password,
      });
      if (res.status == 200) {
        await setStoreToken(res.data.access_token);
        const r = await apiAuth.getInfo();
        navigate("ControlEKYCScreen", {
          data: {
            phone: username,
            password,
            email: r?.data?.email || "",
          },
        });
      } else {
        Alert.showError({
          content: "Tên đăng nhập hoặc mật khẩu của Quý khách không đúng",
          multilanguage: false,
        });
      }
    } catch (error: any) {
      Alert.showError({
        content: error.message,
        multilanguage: false,
        error: true,
      });
    } finally {
    }
  };

  const gotoRegister = () => {
    navigate("Register");
  };

  return (
    <ScrollView keyboardShouldPersistTaps={"handled"}>
      <ImageView
        source={Icons.fincorpbanner}
        style={{
          width: widthScreen,
          height: widthScale(250),
        }}
        resizeMode={"cover"}
      />
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        paddingHorizontal={29}
        paddingTop={26}
        justifyContent={"space-between"}
      >
        <Label size={20} fontWeight="700" color={Ecolors.mainColorSpace}>
          {`loginscreen.login`}
        </Label>
        {/* <DropdownMultiLanguage /> */}
      </Div>
      {/* login form  */}
      <Div marginTop={33} paddingHorizontal={29} flexDirection={"column"}>
        <Label size={16}>{`loginscreen.tendangnhap`}</Label>
        <InputItem
          marginTop={6}
          value={username}
          marginHorizontal={0}
          onSubmitEditing={() => {
            if (passwordRef.current) {
              passwordRef.current.focus();
            }
          }}
          onChangeText={setUserName}
          placeholder={parseMultilanguage(`loginscreen.accountplacehoder`)}
          keyboardType={"number-pad"}
        />
        <Label marginTop={13} size={16}>
          {`loginscreen.matkhau`}
        </Label>
        <InputItem
          inputRef={passwordRef}
          isShowAndHide={true}
          value={password}
          onChangeText={setPassword}
          marginTop={6}
          keyboardType={"ascii-capable"}
          marginHorizontal={0}
          placeholder={parseMultilanguage(`loginscreen.passwordplacehoder`)}
          // onSubmitEditing={() =>
          //   onLogin({
          //     name: username,
          //     pass: password,
          //   })
          // }
        />
        <Div
          paddingTop={15}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* <Button
            flexDirection={"row"}
            alignItems={"center"}
            onPress={() => setIsSaveName((a) => !a)}
          >
            {isSaveName ? (
              <ImageView
                source={Icons.check}
                tintColor={Ecolors.linkColor}
                widthHeight={20}
              />
            ) : (
              <Div
                widthHeight={20}
                borderColor={Ecolors.textColor}
                borderRadius={100}
                borderWidth={0.5}
              />
            )}
            <Label
              marginLeft={10}
              size={15}
              color={isSaveName ? Ecolors.linkColor : Ecolors.textColor}
            >
              {`Lưu tài khoản`}
            </Label>
          </Button> */}
          <Div />
          <Button
            onPress={() => {
              navigate("ForgotPasswordScreen");
            }}
          >
            <Label size={15} color={Ecolors.linkColor}>
              {`loginscreen.forgotpass`}
            </Label>
          </Button>
        </Div>
      </Div>
      <Div
        paddingTop={23}
        paddingHorizontal={29}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ButtonBorder
          width={isSupported && isHaveAccount ? 252 : 317}
          title={`loginscreen.login`}
          onPress={async () => {
            doLogin();
          }}
        />
        {!!isBio && isSupported && isHaveAccount && Icons.finger && (
          <Button
            heightWidth={48}
            borderWidth={0.5}
            borderColor={Ecolors.grayColor}
            borderRadius={5}
            alignItems={"center"}
            justifyContent={"center"}
            onPress={() => {
              // checkBiometryType()
            }}
          >
            <ImageView
              source={biometryType == "FaceID" ? Icons.faceid : Icons.finger}
              widthHeight={27}
              resizeMode={"contain"}
            />
          </Button>
        )}
      </Div>
      {/* goto register screens */}
      <Div
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingTop={50}
        paddingBottom={50}
      >
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          marginTop={20}
        >
          <Label>{`loginscreen.signuptitle`}</Label>
          <Button
            onPress={() => {
              gotoRegister();
            }}
          >
            <Label fontWeight={"700"} color={Ecolors.linkColor}>
              {`loginscreen.signup`}
            </Label>
          </Button>
        </Div>
      </Div>
    </ScrollView>
  );
}

export default React.memo(LoginScreen);
