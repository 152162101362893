import { Alert, Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useRef, useState } from "react";
import { AppState } from "react-native";
import { useAppSelector } from "store/hooks";
import { convertTimestamp, timeoutFromNow } from "utils/utils";

interface Props {
  currentSession: any;
  toTime: any;
  onSetCurrentSession: () => void;
}

function TimeFromNow({ currentSession, toTime, onSetCurrentSession }: Props) {
  const [timeToOut, setTimeToOut] = useState<any>(null);
  const curInteval = useRef<any>(null);
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  const timeLast = useRef<any>(null);

  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        const currentTime: any = new Date();
        const timeBack = currentTime - timeLast.current;
        setTimeToOut((t) => t - Math.round(timeBack / 1000));
      }
      appState.current = nextAppState;
      timeLast.current = new Date();
    });
    return () => {
      if (subscription) {
        subscription.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (toTime) {
      const date = new Date(toTime).getTime();
      const cur = new Date().getTime();
      const timeOut = Math.round(Math.abs(date - cur) / 1000);
      setTimeToOut(timeOut);
      controlInteval();
    }
    return () => {
      stopInteval();
    };
  }, [toTime]);

  useEffect(() => {
    if (timeToOut && timeToOut <= 0) {
      // const toTime =currentSession?.closedOrderBookTime ||''
      Alert.show({
        type: 2,
        titleClose: "alert.dong",
        content:
          I18nState == "vi"
            ? `Lệnh đặt của Quý nhà đầu tư cho phiên ${
                convertTimestamp(toTime) || ""
              } đã kết thúc. Quý khách vui lòng đặt lại lệnh cho phiên tiếp theo`
            : `System cannot submit your subscribe transaction because the trading session ${
                convertTimestamp(toTime) || ""
              } already closed. Please try to create a new subscribe for next trading session`,
        multilanguage: false,
        onConfirm: () => {
          onSetCurrentSession();
        },
        onClose: () => {
          onSetCurrentSession();
        },
      });

      stopInteval();
    }
    return () => {};
  }, [timeToOut]);

  const controlInteval = () => {
    if (curInteval.current) {
      clearInterval(curInteval.current);
    }
    curInteval.current = setInterval(() => {
      setTimeToOut((a: any) => a - 1);
    }, 1000);
  };

  const stopInteval = () => {
    if (curInteval.current) {
      clearInterval(curInteval.current);
      curInteval.current = null;
    }
  };

  return (
    <Div
      width={"100%"}
      maxWidth={170}
      height={35}
      marginTop={6}
      borderRadius={5}
      backgroundColor={Ecolors.yellowColor}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Label size={12} multilanguage={false}>
        {timeoutFromNow(timeToOut, I18nState)}
      </Label>
    </Div>
  );
}

export default React.memo(TimeFromNow);
