import { createAsyncThunk, AsyncThunkAction } from "@reduxjs/toolkit";
import { Alert } from "components";
import { changeStatusScreen } from "reducer/authen";
import { apiAsset } from "services/apiAsset";
import { apiInvestment } from "services/apiInvestment";
export const loadAsset = createAsyncThunk(
  `asset@/load`,
  async (params: {}, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiAsset.loadDashboard(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const loadBanner = createAsyncThunk(
  `banner@/load`,
  async (params: {}, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiAsset.getBanner(params);
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
export const loadProduct = createAsyncThunk(
  `product@/load`,
  async (params: {}, { rejectWithValue, dispatch }) => {
    try {
      const res = await apiInvestment.loadProduct({});
      if (res.status == 200) {
        return res.data;
      }
      throw rejectWithValue(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
