import { useRoute } from "@react-navigation/core";
import {
  ButtonBorder,
  Div,
  Dropdown,
  HeaderBack,
  InputItem,
  Label,
} from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { apiAuth } from "services/api";

function Lbl(p: { content: string; marginTop?: number }) {
  return (
    <Label marginTop={p.marginTop || 0} multilanguage={false}>
      <Label>{p.content}</Label>
      {` (`}
      <Label multilanguage={false} color={Ecolors.red}>
        *
      </Label>
      {`)`}
    </Label>
  );
}

function EditBankInfoModal() {
  const route = useRoute<any>();
  const [name, setName] = useState("");
  const [bank, setBank] = useState<any>(null);
  const [branch, setBranch] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [number, setNumber] = useState("");

  //   useEffect(() => {
  //     bindData(bankAccount);
  //     return () => {};
  //   }, [bankAccount]);

  const bindData = (e: {
    name?: string;
    number?: string;
    branchName?: string;
    branchName_en?: string;
    bankName?: string;
    bankName_en?: string;
    branchId?: string | number;
    bankId?: string | number;
  }) => {
    Promise.all([
      setName(e?.name || ""),
      setNumber(e?.number || ""),
      setBranch(
        e?.branchId
          ? {
              id: e.branchId,
              name: e.branchName || "Hội sở",
              nameen: e.branchName_en || "Hội sở",
            }
          : null
      ),
      setBank(
        e?.bankId
          ? {
              id: e.bankId || "1",
              name: e.bankName,
              nameen: e.bankName_en,
            }
          : null
      ),
    ]);
  };

  const onConfirm = async () => {
    try {
      if (route.params?.onConfirm) {
        route.params?.onConfirm({
          userBankAccount: {
            bankId: `${bank?.id}` || "0",
            branchId: `${branch?.id || bank?.id}` || "0",
            name: name,
            number: number,
          },
        });
        return;
      }
      setLoading(true);
      const res = await apiAuth.updateInvestmentBank({
        bankId: `${bank?.id}` || "0",
        branchId: `${branch?.id || bank?.id}` || "0",
        name: name,
        number: number,
      });
      // console.log('resss', res);
      if (res.status == 200) {
        // Alert.show({
        //   content: res.message,
        //   multilanguage: false,
        //   onConfirm: () => {
        //     navigate('AccountVerifyScreen');
        //   },
        // });
        // dispatch(getInfo({}));
        return;
      }
    } catch (error: any) {
      // console.log('error', error);
      //   Alert.show({
      //     content: error.message,
      //     multilanguage: false,
      //     onConfirm: () => {
      //       navigate('AccountVerifyScreen');
      //     },
      //   });
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div backgroundColor={Ecolors.white} screen={true}>
      <HeaderBack
        type={2}
        loading={loading}
        titleRight={`Lưu`}
        onRightPress={() => {
          onConfirm();
        }}
        title={`Thông tin ngân hàng`}
      />
      <ScrollView>
        <Div paddingHorizontal={29}>
          <Lbl marginTop={16} content={`Tên chủ tài khoản`} />
          <InputItem
            marginTop={6}
            keyboardType={"name-phone-pad"}
            onChangeText={(e) => setName(e)}
            value={name}
            marginHorizontal={0}
          />
          <Lbl marginTop={13} content={`Số tài khoản`} />
          <InputItem
            keyboardType={"number-pad"}
            value={number}
            onChangeText={(a) => setNumber(a)}
            marginHorizontal={0}
            marginTop={6}
          />
          <Lbl marginTop={13} content={`Tên ngân hàng`} />
          <Dropdown
            multilanguage={true}
            url={"bank/list"}
            isActive={true}
            value={bank}
            marginTop={6}
            paddingHorizontal={0}
            content={`Vui lòng chọn ngân hàng`}
            onChange={(a) => {
              setBank(a);
              setBranch(null);
            }}
          />
          <Lbl marginTop={13} content={`Chi nhánh`} />
          <Dropdown
            multilanguage={true}
            isActive={!!bank}
            content={`Vui lòng chọn chi nhánh`}
            url={`bank/branch/list?bankId=${bank?.id || 0}`}
            marginTop={6}
            value={branch}
            paddingHorizontal={0}
            onChange={(a) => setBranch(a)}
          />
        </Div>
      </ScrollView>
    </Div>
  );
}

export default React.memo(EditBankInfoModal);
