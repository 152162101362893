import { Div, HeaderBack, Label, LoadingIndicator } from "components";
import { Ecolors, Efonts } from "constants/themes";
import React, { useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { apiMain } from "services/apiMain";
import { Log, widthScale, widthScreen } from "utils";
import { convertTimestamp } from "utils/utils";
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryTheme,
} from "victory";
import { AnimatePropTypeInterface } from "victory-core";
import RowTime from "./RowTime";

const animate: AnimatePropTypeInterface = {
  duration: 500,
};

function Chart() {
  const [dataNav, setDataNav] = useState([]);
  const [dataInception, setDataInception] = useState([]);
  const [dataTime, setDataTime] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = async (t: any) => {
    try {
      setLoading(true);
      const chartInfo = await apiMain.getChartInfo(t);
      if (chartInfo.status == 200) {
        let dnav = [];
        let dInce = [];
        let dTime = [];
        const space = Math.round(chartInfo.data.length / 7);
        chartInfo.data.map((item: any, index) => {
          dnav.push({
            y: item.nav,
            x: item.navDate,
          });
          dInce.push({
            y: item.startDateInception,
            x: item.navDate,
          });
          if (index % space == 0) {
            dTime.push(item);
          }
        });

        Promise.all([
          setDataNav(dnav),
          setDataInception(dInce),
          setDataTime(dTime),
        ]);
      }
    } catch (error) {
      Log("erorr", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div screen={true}>
      <HeaderBack isShowStep={true} title={`performance.bieudo`} />
      <ScrollView showsVerticalScrollIndicator={false}>
        <Div height={20} />
        <RowTime onChange={onChange} />
        {!!dataNav.length ? (
          <VictoryChart
            width={widthScreen}
            theme={VictoryTheme.grayscale}
            padding={{
              right: widthScale(10),
              top: widthScale(40),
              left: widthScale(50),
              bottom: widthScale(20),
            }}
            domainPadding={{ y: widthScale(20) }}
          >
            <VictoryChart>
              <VictoryAxis
                dependentAxis
                offsetY={widthScale(50)}
                standalone={true}
                style={{
                  axis: { stroke: Ecolors.textColor },
                  grid: {
                    stroke: ({ tick }) => {
                      return Ecolors.grayColor;
                    },
                  },
                  tickLabels: {
                    fontSize: widthScale(10),
                    color: Ecolors.textColor,
                    fontWeight: "400",
                    fontFamily: Efonts.medium,
                  },
                }}
              />

              <VictoryAxis
                style={{
                  axis: { stroke: Ecolors.grayColor },
                  axisLabel: {
                    fontSize: widthScale(8),
                    color: Ecolors.transparent,
                    fontWeight: "400",
                    fontFamily: Efonts.medium,
                  },
                  tickLabels: {
                    fontSize: widthScale(0.1),
                    color: Ecolors.transparent,
                    fontWeight: "400",
                    fontFamily: Efonts.medium,
                  },
                  grid: {
                    stroke: ({ tick }) => {
                      const sp = Math.round(dataInception.length / 7);
                      return tick % sp == 0
                        ? Ecolors.grayColor
                        : Ecolors.transparent;
                    },
                  },
                }}
                offsetY={widthScale(20)}
              />
            </VictoryChart>
            {/* build line */}

            <VictoryGroup>
              <VictoryLine
                animate={animate}
                interpolation={"natural"}
                style={{
                  data: { stroke: Ecolors.textColor },
                  parent: { border: "1px solid #ccc" },
                }}
                data={dataInception}
              />
              <VictoryLine
                animate={animate}
                interpolation={"natural"}
                style={{
                  data: { stroke: Ecolors.mainColor },
                  parent: { border: "1px solid #ccc" },
                }}
                data={dataNav}
              />
            </VictoryGroup>
          </VictoryChart>
        ) : (
          <>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Div
                marginTop={30}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Label>{`investmentscreen.khongconoidunghienthi`}</Label>
              </Div>
            )}
          </>
        )}
        <Div
          flexDirection="row"
          alignItems="center"
          paddingLeft={35}
          paddingRight={5}
          justifyContent="space-between"
        >
          {dataTime.map((item: any, index: number) => {
            return (
              <Label
                key={index}
                multilanguage={false}
                size={8}
                style={{
                  transform: [
                    {
                      rotate: "-70deg",
                    },
                  ],
                }}
              >
                {convertTimestamp(item.navDate, "DD/MM/YY")}
              </Label>
            );
          })}
        </Div>
      </ScrollView>
    </Div>
  );
}

export default React.memo(Chart);
