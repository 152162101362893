import { Div, HeaderBack, Label } from "components";
import { Ecolors } from "constants/themes";
import React from "react";
import { FlatList } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useAppSelector } from "store/hooks";

const Item = (p: { data: any; index }) => {
  const { data, index } = p;
  const fillingContent = data.options.filter((t: any) => t.wasChose);
  return (
    <Div paddingHorizontal={20}>
      <Label
        fontWeight="400"
        size={14}
        multilanguage={false}
        color={Ecolors.grayText}
      >{`${index + 1}. ${data.content}`}</Label>
      {fillingContent?.map((item: any, index: number) => {
        return (
          <Div marginTop={5} key={index}>
            <Label multilanguage={false} size={14}>
              {item.content}
            </Label>
          </Div>
        );
      })}
    </Div>
  );
};

function HistoryRiskQuestionModal() {
  const questions = useAppSelector((state) => state.authen.result.questions);
  const insets = useSafeAreaInsets();

  const renderItem = (p: { item: any; index: number }) => {
    return <Item data={p.item} index={p.index} />;
  };
  const keyExtractor = (item: any, index: number) => `key${index}`;

  const ItemSeparatorComponent = () => {
    return (
      <Div height={1} backgroundColor={Ecolors.grayText} marginVertical={10} />
    );
  };
  const ListFooterComponent = () => {
    return <Div height={insets.bottom + 200} />;
  };
  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack
        isShowStep={false}
        type={4}
        title={`riskquestionscreen.bangkhaosatkhauviruiro`}
      />
      <FlatList
        data={questions}
        extraData={questions}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={ItemSeparatorComponent}
        ListFooterComponent={ListFooterComponent}
        showsVerticalScrollIndicator={false}
      />
    </Div>
  );
}

export default React.memo(HistoryRiskQuestionModal);
