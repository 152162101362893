import { Div, Label } from "components";
import React, { useEffect, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { widthScreen } from "utils";
import ComQuestion from "./ComQuestion";

function StepComQuestion(p: { data: any; onAnswer: (t: any) => void }) {
  const [itemSelect, setItemSelect] = useState<Object>({});
  const { data, onAnswer } = p;

  const onSelect = (t) => {
    if (!data.isOneChoice) {
      setItemSelect({
        ...itemSelect,
        [t.index]: !itemSelect?.[t.index] ? t : null,
      });
      return;
    }
    setItemSelect({
      [t.index]: t,
    });
  };

  useEffect(() => {
    setItemSelect({});
    return;
  }, [p.data.index]);

  useEffect(() => {
    if (!!itemSelect) {
      onAnswer({
        id: data.id,
        questionNo: data.index + 1,
        options: Object.keys(itemSelect || {})?.map(
          (t: any) => itemSelect[t]?.name
        ),
      });
    }
    return () => {};
  }, [itemSelect]);

  return (
    <Div
      padding={19}
      style={{
        width: widthScreen,
        height: "100%",
        flex: 1,
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <Label multilanguage={false}>{data.content}</Label>
        {data?.options?.map((item: any, index: number) => {
          return (
            <Div paddingTop={13} key={index}>
              <ComQuestion
                data={item}
                onPress={(t) => {
                  onSelect({ ...item, index });
                }}
                isSelect={!!itemSelect?.[index]}
                type={2}
              />
            </Div>
          );
        })}
      </ScrollView>
    </Div>
  );
}

export default React.memo(StepComQuestion);
